import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const AddMyVideo = ({ Axios }) => {
  const [name, setName] = useState("");
  const [video, setVideo] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!name || !video) {
      console.log("Name and video are required");
      return;
    }
    const formData = new FormData();
    formData.append("name", name);
    formData.append("video", video);
    Axios.post("myvideos", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((response) => {
      console.log("Video uploaded successfully", response.data);
      navigate("/admin/lista-videos");
    });
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <h5>Adauga video :</h5>

      <form onSubmit={handleSubmit}>
        <label htmlFor="name" style={{ color: "red" }}>
          Nume video
        </label>
        <input
          value={name}
          type="text"
          //multiple
          className="form-control mb-2"
          placeholder="name"
          onChange={(e) => setName(e.target.value)}
        />

        <input
          type="file"
          accept="video/*"
          className="form-control mb-2"
          onChange={(event) => {
            setVideo(event.target.files[0]);
          }}
        />
        <button type="submit" className="btn btn-primary">
          Add Video
        </button>
      </form>
    </div>
  );
};

export default AddMyVideo;
