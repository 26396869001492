import React from "react";
import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";

const spin = keyframes`
 0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }`;
const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  background-color: #f7f7f7; /* Background color during loading */
`;
const Spinner = styled.div`
  border: 8px solid rgba(255, 255, 255, 0.3);
  border-top: 8px solid #3498db; /* Spinner color */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1.2s linear infinite;
`;

const LoadingSpinner = () => {
  return (
    <SpinnerContainer className="loading-spinner-container">
      <Spinner className="spinner"></Spinner>
    </SpinnerContainer>
  );
};

export default LoadingSpinner;
