import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import LoginPage from "../Pages/Admin/LoginPage/LoginPage";
import CategoryList from "../Pages/Admin/Categories/CategoryList/CategoryList";
import AddCategory from "../Pages/Admin/Categories/AddCategory/AddCategory";
import EditCategory from "../Pages/Admin/Categories/EditCategory/EditCategory";
import CategoryDescriptionsList from "../Pages/Admin/CategoryDescriptions/CategoryDescriptionsList";
import AddCategoryDescriptions from "../Pages/Admin/CategoryDescriptions/AddCategoryDescriptions";
import EditCategoryDescriptions from "../Pages/Admin/CategoryDescriptions/EditCategoryDescriptions";
import IngredientsList from "../Pages/Admin/Ingredients/IngredientsList/IngredientsList";
import AddIngredient from "../Pages/Admin/Ingredients/AddIngredient/AddIngredient";
import EditIngredient from "../Pages/Admin/Ingredients/EditIngredient/EditIngredient";
import ProductsList from "../Pages/Admin/Products/ProductsList/ProductsList";
import AddProduct from "../Pages/Admin/Products/AddProduct/AddProduct";
import EditProduct from "../Pages/Admin/Products/EditProduct/EditProduct";
import MenuItemsList from "../Pages/Admin/MenuItems/MenuItemsList";
import AddMenuItem from "../Pages/Admin/MenuItems/AddMenuItem";
import EditMenuItem from "../Pages/Admin/MenuItems/EditMenuItem";
import ListOfThemes from "../Pages/Admin/Themes/ListOfThemes";
import AddTheme from "../Pages/Admin/Themes/AddTheme";
import EditTheme from "../Pages/Admin/Themes/EditTheme";
import ListOfBackgroundSvgsOrImages from "../Pages/Admin/BackgroundSvgsOrImages/ListOfBackgroundSvgsOrImages";
import AddBackgroundSvgsOrImages from "../Pages/Admin/BackgroundSvgsOrImages/AddBackgroundSvgsOrImages";
import ListOfSubcategories from "../Pages/Admin/Subcategories/ListOfSubcategories";
import AddSubcategory from "../Pages/Admin/Subcategories/AddSubcategory";
import EditSubcategory from "../Pages/Admin/Subcategories/EditSubcategory";
import ListOfMyVideos from "../Pages/Admin/MyVideos/ListOfMyVideos";
import AddMyVideo from "../Pages/Admin/MyVideos/AddMyVideo";
import ListOfMyModals from "../Pages/Admin/MyModals/ListOfMyModals";
import AddMyModal from "../Pages/Admin/MyModals/AddMyModal";
import EditMyModal from "../Pages/Admin/MyModals/EditMyModal";

const AdminViews = ({
  sideNav,
  showSideNav,
  currentTheme,
  priceOffPercentage,
  priceOffObjs,
  setPriceOffPercentage,
  setModifiedData,
  modifiedData,
  logged,
  setLogged,
  menuItems,
  categoryData,
  subcategoriesData,
  categoryDescriptionsData,
  imagesData,
  productCategoryData,
  descriptionsData,
  pricesByQntData,
  ingredientsData,
  productIngredientData,
  specificationsData,
  videoData,
  themesData,
  backgroundSvgsOrImagesData,
  modalsData,
  Axios,
}) => {
  return (
    <Routes>
      {/* **********************************************************ADMIN*********************************************************
       ************************************************************************************************************************* */}
      <Route
        path="/admin"
        element={
          <LoginPage
            logged={logged}
            setLogged={setLogged}
            modifiedData={modifiedData}
            setModifiedData={setModifiedData}
            Axios={Axios}
            priceOffPercentage={priceOffPercentage}
            setPriceOffPercentage={setPriceOffPercentage}
            priceOffObjs={priceOffObjs}
            menuItems={menuItems}
            categoryData={categoryData}
            currentTheme={currentTheme}
          />
        }
      >
        {/* ---------------------------------MENU ITEMS----------------------------------- */}
        <Route
          path="/admin/lista-menu-items"
          element={
            <MenuItemsList
              menuItems={menuItems}
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              Axios={Axios}
            />
          }
        />
        <Route
          path="/admin/adauga-menu-item"
          element={
            <AddMenuItem
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              Axios={Axios}
            />
          }
        />
        <Route
          path="/admin/editeaza-menu-item/:id"
          element={
            <EditMenuItem
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              Axios={Axios}
            />
          }
        />

        {/* --------------------------------CATEGORII----------------------------------- */}
        <Route
          path="/admin/lista-categorii"
          element={
            <CategoryList
              categoryData={categoryData}
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              menuItems={menuItems}
              Axios={Axios}
            />
          }
        />
        <Route
          path="/admin/adauga-categorie"
          element={
            <AddCategory
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              menuItems={menuItems}
              Axios={Axios}
            />
          }
        />
        <Route
          path="/admin/editeaza-categorie/:id"
          element={
            <EditCategory
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              Axios={Axios}
            />
          }
        />
        {/* --------------------------------SUBCATEGORII----------------------------------- */}
        <Route
          path="/admin/lista-subcategorii"
          element={
            <ListOfSubcategories
              subcategoriesData={subcategoriesData}
              categoryData={categoryData}
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              Axios={Axios}
            />
          }
        />
        <Route
          path="/admin/adauga-subcategorie"
          element={
            <AddSubcategory
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              categoryData={categoryData}
              Axios={Axios}
            />
          }
        />
        <Route
          path="/admin/editeaza-subcategorie/:id"
          element={
            <EditSubcategory
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              Axios={Axios}
            />
          }
        />
        {/* --------------------------------DESCRIERI CATEGORII----------------------------------- */}
        <Route
          path="/admin/lista-descrieri-categorii"
          element={
            <CategoryDescriptionsList
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              categoryDescriptionsData={categoryDescriptionsData}
              categoryData={categoryData}
              Axios={Axios}
            />
          }
        />
        <Route
          path="/admin/adauga-descriere-categorie"
          element={
            <AddCategoryDescriptions
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              Axios={Axios}
            />
          }
        />
        <Route
          path="/admin/editeaza-descriere-categorie/:id"
          element={
            <EditCategoryDescriptions
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              Axios={Axios}
            />
          }
        />
        {/* --------------------------------INGREDIENTE----------------------------------- */}
        <Route
          path="/admin/lista-ingrediente"
          element={
            <IngredientsList
              ingredientsData={ingredientsData}
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              Axios={Axios}
            />
          }
        />
        <Route
          path="/admin/adauga-ingredient"
          element={
            <AddIngredient
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              Axios={Axios}
            />
          }
        />
        <Route
          path="/admin/editeaza-ingredient/:id"
          element={
            <EditIngredient
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              Axios={Axios}
            />
          }
        />
        {/* --------------------------------PRODUSE----------------------------------- */}
        <Route
          path="/admin/lista-produse"
          element={
            <ProductsList
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              menuItems={menuItems}
              imagesData={imagesData}
              categoryData={categoryData}
              productCategoryData={productCategoryData}
              descriptionsData={descriptionsData}
              pricesByQntData={pricesByQntData}
              ingredientsData={ingredientsData}
              productIngredientData={productIngredientData}
              specificationsData={specificationsData}
              Axios={Axios}
            />
          }
        />
        <Route
          path="/admin/adauga-produs"
          element={
            <AddProduct
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              ingredientsData={ingredientsData}
              categoryData={categoryData}
              subcategoriesData={subcategoriesData}
              menuItems={menuItems}
              Axios={Axios}
            />
          }
        />
        <Route
          path="/admin/editeaza-produs/:id"
          element={
            <EditProduct
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              ingredientsData={ingredientsData}
              categoryData={categoryData}
              subcategoriesData={subcategoriesData}
              menuItems={menuItems}
              Axios={Axios}
            />
          }
        />

        {/* ----------------------------BACKGROUND SVGS OR IMAGES----------------------------------- */}
        <Route
          path="/admin/lista-background-svgs-or-images"
          element={
            <ListOfBackgroundSvgsOrImages
              backgroundSvgsOrImagesData={backgroundSvgsOrImagesData}
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              Axios={Axios}
            />
          }
        />
        <Route
          path="/admin/adauga-background-svg-or-image"
          element={
            <AddBackgroundSvgsOrImages
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              Axios={Axios}
            />
          }
        />

        {/* -------------------------------VIDEOS----------------------------------- */}
        <Route
          path="/admin/lista-videos"
          element={
            <ListOfMyVideos
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              videoData={videoData}
              Axios={Axios}
            />
          }
        />
        <Route
          path="/admin/adauga-video"
          element={
            <AddMyVideo
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              Axios={Axios}
            />
          }
        />

        {/* --------------------------------TEME SITE----------------------------------- */}
        <Route
          path="/admin/lista-teme-site"
          element={
            <ListOfThemes
              themesData={themesData}
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              videoData={videoData}
              Axios={Axios}
            />
          }
        />
        <Route
          path="/admin/adauga-tema"
          element={
            <AddTheme
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              backgroundSvgsOrImagesData={backgroundSvgsOrImagesData}
              videoData={videoData}
              Axios={Axios}
            />
          }
        />
        <Route
          path="/admin/editeaza-tema/:id"
          element={
            <EditTheme
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              backgroundSvgsOrImagesData={backgroundSvgsOrImagesData}
              videoData={videoData}
              Axios={Axios}
            />
          }
        />
        {/* --------------------------------MODALS----------------------------------- */}
        <Route
          path="/admin/lista-modals"
          element={
            <ListOfMyModals
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              modalsData={modalsData}
              Axios={Axios}
            />
          }
        />
        <Route
          path="/admin/adauga-modal"
          element={
            <AddMyModal
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              Axios={Axios}
            />
          }
        />
        <Route
          path="/admin/editeaza-modal/:id"
          element={
            <EditMyModal
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              modalsData={modalsData}
              Axios={Axios}
            />
          }
        />
      </Route>
    </Routes>
  );
};

export default AdminViews;
