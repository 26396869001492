import React, { useState, useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const EditMenuItem = ({ modifiedData, setModifiedData, Axios }) => {
  const navigate = useNavigate();
  const params = useParams();
  const currentMenuItemId = parseInt(params.id);
  const [currentMenuItem, setCurrentMenuItem] = useState();

  //Find current menu item after id
  useEffect(() => {
    Axios.get(`menuitems/${currentMenuItemId}`).then((response) => {
      setCurrentMenuItem(response.data);
    });
  }, [currentMenuItemId]);

  const initialValues = {
    name: currentMenuItem?.name,
    hasdropdown: currentMenuItem?.hasdropdown,
    path: currentMenuItem?.path,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Nume obligatoriu!"),
    hasdropdown: Yup.string().required("Hasdropdown obligatoriu!"),
    path: Yup.string().required("Path obligatoriu!"),
  });

  const onSubmit = (data) => {
    Axios.patch(`menuitems/${currentMenuItemId}`, data).then((response) => {
      console.log("Menu Item editat ", response.data);
      setModifiedData(!modifiedData);
      navigate("/admin/lista-menu-items");
    });
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center w-100">
      {currentMenuItem && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form
            style={{ width: "40%" }}
            className="d-flex flex-column align-items-center justify-content-center "
          >
            <ErrorMessage
              name="name"
              component="div"
              className="alert alert-danger"
            />
            <Field
              name="name"
              type="text"
              className="form-control"
              placeholder="nume"
            />
            <ErrorMessage
              name="hasdropdown"
              component="div"
              className="alert alert-danger"
            />
            <Field
              name="hasdropdown"
              type="text"
              className="form-control"
              placeholder="hasdropdown"
            />
            <ErrorMessage
              name="path"
              component="div"
              className="alert alert-danger"
            />
            <Field
              name="path"
              type="text"
              className="form-control"
              placeholder="path"
            />

            <button type="submit" className="btn btn-primary mt-2">
              Editeaza
            </button>
          </Form>
        </Formik>
      )}
    </div>
  );
};

export default EditMenuItem;
