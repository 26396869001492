import React from "react";

import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const AddMenuItem = ({ modifiedData, setModifiedData, Axios }) => {
  let navigate = useNavigate();

  const initialValues = {
    name: "",
    hasdropdown: "",
    path: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Nume obligatoriu!"),
    hasdropdown: Yup.string().required("Hasdropdown obligatoriu!"),
    path: Yup.string().required("Path obligatoriu!"),
  });

  const onSubmit = (data) => {
    Axios.post("menuitems", data).then((response) => {
      setModifiedData(!modifiedData);
      navigate("/admin/lista-menu-items");
    });
  };
  return (
    <div className="d-flex flex-column align-items-center justify-content-center w-100">
      <div
        className="d-flex flex-column align-items-center justify-content-center "
        style={{ width: "50%" }}
      >
        <h5>Adauga menu item</h5>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form style={{ width: "100%" }}>
            <ErrorMessage
              name="name"
              component="div"
              className="alert alert-danger"
            />
            <Field
              name="name"
              type="text"
              className="form-control"
              placeholder="nume"
            />
            <ErrorMessage
              name="hasdropdown"
              component="div"
              className="alert alert-danger"
            />
            <Field
              name="hasdropdown"
              type="text"
              className="form-control"
              placeholder="hasdropdown"
            />
            <ErrorMessage
              name="path"
              component="div"
              className="alert alert-danger"
            />
            <Field
              name="path"
              type="text"
              className="form-control"
              placeholder="path"
            />

            <button type="submit" className="btn btn-primary mt-2">
              Adauga
            </button>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default AddMenuItem;
