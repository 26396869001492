import React, { useState, useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import PreviewImage from "../../../Components/Admin/UploadImage.js/PreviewImage";

const AddMyModalWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const AddMyModal = ({ modifiedData, setModifiedData, Axios }) => {
  const fileRefMyModal = useRef(null);
  const [modalStatus, setModalStatus] = useState("Activ");
  const navigate = useNavigate();

  const handleModalStatusChange = () => {
    if (modalStatus === "Activ") {
      setModalStatus("Inactiv");
    } else {
      setModalStatus("Activ");
    }
  };

  const initialValues = {
    name: "",
    title: "",
    text: "",
    image: null,
    status: modalStatus,
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Nume obligatoriu!"),
    title: Yup.string().required("Titlu obligatoriu!"),
    text: Yup.string().required("Text obligatoriu!"),
    image: Yup.mixed().notRequired("Imagine obligatorie!"),
    status: Yup.string().required("Status obligatoriu!"),
  });
  const onSubmit = (data) => {
    //console.log(data);
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("title", data.title);
    formData.append("text", data.text);
    formData.append("image", data.image);
    formData.append("status", modalStatus);

    //Log the FormData entries
    // for (let pair of formData.entries()) {
    //   console.log(pair[0], pair[1]);
    // }

    Axios.post("mymodals", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((response) => {
      setModifiedData(!modifiedData);
      console.log(response.data);
    });

    navigate("/admin/lista-modals");
  };
  return (
    <AddMyModalWrap>
      <h5>Adauga modal</h5>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form>
              <ErrorMessage
                name="name"
                component="div"
                className="alert alert-danger"
              />
              <Field
                name="name"
                type="text"
                className="form-control"
                placeholder="name"
              />
              <ErrorMessage
                name="title"
                component="div"
                className="alert alert-danger"
              />
              <Field
                name="title"
                type="text"
                className="form-control"
                placeholder="title"
              />
              <ErrorMessage
                name="text"
                component="div"
                className="alert alert-danger"
              />
              <Field
                name="text"
                type="text"
                className="form-control"
                placeholder="text"
              />
              {/* --------------------IMAGE---------------------------*/}
              <div className="d-flex align-items-center mb-2">
                <div style={{ color: "red" }}>Incarca imagine:</div>
                <input
                  id="image"
                  name="image"
                  ref={fileRefMyModal}
                  hidden
                  type="file"
                  onChange={(e) => {
                    setFieldValue("image", e.target.files[0]);
                  }}
                />
                {values.image && <PreviewImage file={values.image} />}

                <button
                  type="button"
                  onClick={() => {
                    fileRefMyModal.current.click();
                  }}
                >
                  Upload
                </button>
              </div>
              {/* ************************************Daca este activ sau nu********************************* */}
              <div className="mb-3">
                <label
                  htmlFor="status"
                  style={{ color: "red" }}
                  className="form-label me-3 "
                >
                  Bifați dacă doriți ca modalul să fie inactiv
                </label>
                <input
                  name="status"
                  style={{ width: "20px", height: "20px" }}
                  type="checkbox"
                  checked={modalStatus === "Activ" ? false : true}
                  onChange={handleModalStatusChange}
                />
              </div>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </Form>
          );
        }}
      </Formik>
    </AddMyModalWrap>
  );
};

export default AddMyModal;
