import React, { useEffect, useState } from "react";

import "./ProductsList.css";
import { Link, useNavigate } from "react-router-dom";
import SearchBar from "../../../../Components/SearchBar";

const ProductsList = ({
  modifiedData,
  setModifiedData,
  logged,
  setLogged,
  Axios,
}) => {
  const navigate = useNavigate();
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [allCompletProducts, setAllCompletProducts] = useState([]);

  useEffect(() => {
    Axios.get("products/getAllCompletProducts").then((response) => {
      setAllCompletProducts(response.data);
    });
  }, [modifiedData]);

  useEffect(() => {
    if (allCompletProducts != null) {
      setFilteredProducts(allCompletProducts);
    }
  }, [allCompletProducts]);

  const deleteProduct = (id) => {
    Axios.delete(`products/${id}`).then((response) => {
      console.log(response.data);
      setModifiedData(!modifiedData);
    });
  };
  //------------------------SCHIMBAREA VISIBILITATII PRODUSULUI----------
  const changeVisibility = (visibility, productId) => {
    const formData = new FormData();
    formData.append("visibility", visibility);

    Axios.patch(`products/editVisibility/${productId}`, formData).then(
      (response) => {
        setModifiedData(!modifiedData);
      }
    );
  };

  return (
    <div className="w-100 d-flex flex-column  align-items-center ">
      <div
        className="d-flex flex-column  align-items-center "
        style={{ width: "80%" }}
      >
        <h4>Lista produse:</h4>
        <Link
          to="/admin/adauga-produs"
          style={{ fontSize: "17px", fontWeight: "bold" }}
        >
          ADAUGA PRODUS
        </Link>
        <SearchBar
          list={allCompletProducts}
          onResults={setFilteredProducts}
          style={{ width: "80%" }}
        />
        {filteredProducts &&
          filteredProducts.length > 0 &&
          filteredProducts.map((product) => {
            // console.log(product);
            // console.log("prices", product.pricesByQnt);
            return (
              <div key={product.id} className="w-100">
                <div className="product-container container">
                  <div className="btns-container">
                    <button
                      onClick={() => deleteProduct(product.id)}
                      className="btn me-2"
                      style={{
                        backgroundColor: "rgba(184,15,10,1)",
                        color: "white",
                      }}
                    >
                      Sterge
                    </button>
                    <button
                      className="btn me-2"
                      style={{ backgroundColor: " rgba(255,215,0,1)" }}
                    >
                      <Link
                        to={`/admin/editeaza-produs/${product.id}`}
                        style={{
                          textDecoration: "none",
                          color: "white",
                        }}
                      >
                        Editează
                      </Link>
                    </button>
                    <button
                      className="btn"
                      style={{
                        backgroundColor:
                          product.visibility == "Vizibil"
                            ? "rgba(0,128,0,1)"
                            : "grey",
                        color: "white",
                      }}
                      onClick={() =>
                        changeVisibility(product.visibility, product.id)
                      }
                    >
                      {product.visibility}
                    </button>
                  </div>
                  {/* -----------------------IMAGINE SI NUME------------------------------ */}
                  <table className="table mt-2">
                    <thead>
                      <tr>
                        <th scope="col">Imagine</th>
                        <th scope="col">Nume</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr key={product.id}>
                        <td>
                          <img
                            src={product.image ? product.image.imgSrc : ""}
                            style={{ width: "60px" }}
                            alt="product-image"
                          />
                        </td>

                        <td>{product.name}</td>
                      </tr>
                    </tbody>
                  </table>

                  {/* -----------------------CATEGORII SI PRETURI------------------------------ */}
                  <table className="mt-2 table">
                    <thead>
                      <tr>
                        <th scope="col">Menu items</th>
                        <th scope="col">Categorii</th>
                        <th scope="col">Subcategorii</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {product.menuItems.map((menuItem) => {
                            return <div key={menuItem.id}>{menuItem.name}</div>;
                          })}
                        </td>
                        <td>
                          {product.categories.map((category) => {
                            return <div key={category.id}>{category.name}</div>;
                          })}
                        </td>
                        <td>
                          {product.subcategories.map((subcategory) => {
                            return (
                              <div key={subcategory.id}>{subcategory.name}</div>
                            );
                          })}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  {/* -----------------------SPECIFICATII PRODUS------------------------------ */}
                  <table className="mt-2 table">
                    <thead>
                      <tr>
                        <th scope="col">Specificatii produs</th>
                      </tr>
                    </thead>
                    <tbody>
                      {product.specifications.map((specification) => {
                        return (
                          <tr key={specification.id}>
                            <td>
                              <div>
                                {specification.title} :{" "}
                                {specification.specification}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {/* -----------------------USE INSTRUCTIONS PRODUS------------------------------ */}
                  <table className="mt-2 table">
                    <thead>
                      <tr>
                        <th scope="col">Use instructions:</th>
                      </tr>
                    </thead>
                    <tbody>
                      {product.useInstructions.map((instruction) => {
                        return (
                          <tr key={instruction.id}>
                            <td>
                              <div>{instruction.instruction}</div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {/* -----------------------WARNINGS PRODUS------------------------------ */}
                  <table className="mt-2 table">
                    <thead>
                      <tr>
                        <th scope="col">Warnings:</th>
                      </tr>
                    </thead>
                    <tbody>
                      {product.warnings.map((warning) => {
                        return (
                          <tr key={warning.id}>
                            <td>
                              <div>{warning.warning}</div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  {/* -----------------------DESCRIERI PRODUS------------------------------ */}
                  <table className="mt-2 table">
                    <thead>
                      <tr>
                        <th scope="col">Descrieri produs</th>
                      </tr>
                    </thead>
                    <tbody>
                      {product.descriptions.map((description) => {
                        return (
                          <tr key={description.id}>
                            <td>
                              <div>{description.description}</div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  {/* -----------------------PRETURI , VALABILITATE, UM------------------------------ */}
                  <table className="mt-2 table">
                    <thead>
                      <tr>
                        <th scope="col">Reteta</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{product.recipe}</td>
                      </tr>
                    </tbody>
                  </table>

                  {/* -----------------------PRETURI , VALABILITATE, UM------------------------------ */}
                  <table className="mt-2 table">
                    <thead>
                      <tr>
                        <th scope="col">Preturi</th>
                        <th scope="col">Valabilitate</th>
                        <th scope="col">UM</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {product.pricesByQnt.map((elem) => {
                            return (
                              <div key={elem.id}>
                                {elem.quantity}
                                {product.unitOfMeasure}-{elem.price}ron
                              </div>
                            );
                          })}
                        </td>
                        <td>{product.valability}</td>
                        <td>{product.unitOfMeasure}</td>
                      </tr>
                    </tbody>
                  </table>

                  {/* -----------------------INGREDIENTE DESCRISE------------------------------ */}
                  <table className="mt-2 table">
                    <thead>
                      <tr>
                        <th scope="col">Ingrediente descrise:</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {product.ingredients.map((ingredient) => {
                            return (
                              <span key={ingredient.id}>
                                {ingredient.name},{" "}
                              </span>
                            );
                          })}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ProductsList;
