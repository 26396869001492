import { useEffect, useState } from "react";

const MyModal = ({ currentmodal, setShowModal }) => {
  return (
    <>
      {currentmodal && (
        <div
          onClick={() => setShowModal(false)}
          style={{
            position: "fixed",
            zIndex: "101",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              background: "white",

              width: 400,
              margin: "auto",
              padding: "2%",
              border: "2px solid #000",
              borderRadius: "10px",
              boxShadow: "2px solid black",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h4 style={{ textAlign: "center", color: "red" }}>
              {currentmodal.title}
            </h4>
            <p
              style={{ fontWeight: "600", color: "green", fontSize: "20px" }}
              className="text-center"
            >
              {currentmodal.text}
            </p>
            <img
              src={currentmodal.image.src}
              alt={currentmodal.title}
              style={{ width: "200px" }}
              className="mb-2"
            />
            <button
              className="btn btn-info"
              style={{
                float: "right",
                width: "80%",
                fontWeight: "600",
                fontSize: "20px",
              }}
              onClick={() => setShowModal(false)}
            >
              OK
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default MyModal;
