import React, { useEffect } from "react";
import "./AdminMenu.css";
import { Link } from "react-router-dom";

const AdminMenu = ({
  setPriceOffPercentage,
  priceOffPercentage,
  Axios,
  setModifiedData,
  modifiedData,
  priceOffObjs,
}) => {
  const calculateAndSetPricesOff = (priceOffPercentage) => {
    //Modific preturile produselor in DB :
    Axios.patch(`products/calculateAndSetPricesOff/${priceOffPercentage}`).then(
      (res) => {
        console.log("Vechile preturi sunt:", res.data);
        setPriceOffPercentage(0);
        setModifiedData(!modifiedData);
        const formData = new FormData();

        res.data.forEach((item) => {
          formData.append("ProductId", item.ProductId);
          formData.append("prices", item.price);
          formData.append("quantities", item.quantity);
        });

        //Introduc vechile preturi in DB
        // Log FormData
        // for (let pair of formData.entries()) {
        //   console.log(pair[0], pair[1]);
        // }
        Axios.post("oldpricesbyqnt", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then((res) => {
          console.log(res.data);
          setModifiedData(!modifiedData);
        });

        //Introduc reducerea in DB :
        Axios.post(`priceoff/${priceOffPercentage}`).then((res) => {
          console.log("reducere bagata in DB", res.data);
          setModifiedData(!modifiedData);
        });
      }
    );
  };

  const cancelDiscount = (priceOffObjs) => {
    const id = priceOffObjs[0].id;
    console.log("id", id);
    console.log("priceOffObjs", priceOffObjs);
    // //Sterg reducerea din DB
    Axios.delete(`priceoff/${id}`).then((res) => {
      console.log("reducere anulata", res.data);
      setModifiedData(!modifiedData);
    });

    //Refac preturile initiale pt produse :
    Axios.patch(`products/cancelDiscount`).then((res) => {
      console.log("Vechile preturi sunt:", res.data);
      setModifiedData(!modifiedData);
    });
  };
  return (
    <div className="admin-wrap">
      <h3 className="text-center">Admin Menu</h3>
      <div className="admin-nav">
        <div className="link-wrap">
          <Link className="nav-link" to="/admin/lista-menu-items">
            Listă menu items
          </Link>
        </div>

        <div className="link-wrap">
          <Link className="nav-link" to="/admin/lista-produse">
            Listă produse
          </Link>
        </div>

        <div className="link-wrap">
          <Link className="nav-link" to="/admin/lista-categorii">
            Listă categorii
          </Link>
        </div>
        <div className="link-wrap">
          <Link className="nav-link" to="/admin/lista-subcategorii">
            Listă subcategorii
          </Link>
        </div>

        <div className="link-wrap">
          <Link className="nav-link" to="/admin/lista-descrieri-categorii">
            Listă descrieri categorii
          </Link>
        </div>

        <div className="link-wrap">
          <Link className="nav-link" to="/admin/lista-ingrediente">
            Listă ingrediente
          </Link>
        </div>
        <div className="link-wrap">
          <Link
            className="nav-link"
            to="/admin/lista-background-svgs-or-images"
          >
            Listă background svgs or images
          </Link>
        </div>
        <div className="link-wrap">
          <Link className="nav-link" to="/admin/lista-videos">
            Listă videos
          </Link>
        </div>

        <div className="link-wrap">
          <Link className="nav-link" to="/admin/lista-teme-site">
            Listă teme site
          </Link>
        </div>
        <div className="link-wrap">
          <Link className="nav-link" to="/admin/lista-modals">
            Listă modals
          </Link>
        </div>
        <div className="link-wrap">
          <div className="  d-flex justify-content-evenly p-2">
            <button
              className="btn btn-info me-3"
              style={{ width: "200px" }}
              onClick={() => calculateAndSetPricesOff(priceOffPercentage)}
            >
              Seteaza reducere de{" "}
            </button>
            <input
              name="priceOffProcentage"
              type="number"
              value={priceOffPercentage}
              onChange={(e) => setPriceOffPercentage(e.target.value)}
            />
          </div>
          {priceOffObjs && priceOffObjs.length > 0 && (
            <div className="  d-flex justify-content-evenly p-2">
              <button
                style={{ width: "200px" }}
                className="btn btn-danger me-3"
                onClick={() => cancelDiscount(priceOffObjs)}
              >
                Anuleaza reducerea{" "}
              </button>
              <span className="text-danger p-2" style={{ fontWeight: "bold" }}>
                Reducere setata la {`${priceOffObjs[0].percentage}%`}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminMenu;
