import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Select from "react-select";

const StyledBootstrapCard = styled(Card)`
  width: 270px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px;

  &:hover {
    transform: scale(1.02);
    color: red;
  }
`;
const StyledBootstrapImage = styled(Card.Img)`
  width: 190px;
  margin-top: 15px;
`;
const StyledCardBody = styled(Card.Body)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const CardImage = styled(Link)``;
const StyledCardTitle = styled(Card.Title)`
  font-size: 16px;
`;
const Specifications = styled.ul`
  width: 100%;
  font-size: 16px;
  line-height: 20px;
`;
const SingleSpecification = styled.li`
  padding: 3px 0;
`;
const DetaliiButton = styled(Link)`
  width: 100%;
  height: 50px;
  //background-color: rgba(143, 30, 53, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`;

const Price = styled.div`
  display: flex;
  align-items: center;
`;
const CurrentPrice = styled.div`
  font-weight: 600;
  font-size: 16px;
`;
const OldPrice = styled.div`
  text-decoration: line-through;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
`;
const Quantity = styled.div`
  padding: 3px;
`;

const SingleCard = ({ currentTheme, currentProduct, oldProductPricesObj }) => {
  const [productOldPriceByQnt, setProductOldPriceByQnt] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [displayedPrice, setDisplayedPrice] = useState(null);
  const [displayedOldPrice, setDisplayedOldPrice] = useState(null);

  //Gasesc vechile preturi ale produsului , daca exista o reducere:
  useEffect(() => {
    if (
      currentProduct &&
      oldProductPricesObj &&
      oldProductPricesObj.length > 0
    ) {
      const productOldPriceByQnt = oldProductPricesObj
        .filter((item) => {
          return item.ProductId === currentProduct.id;
        })
        .sort((a, b) => a.quantity - b.quantity);
      setProductOldPriceByQnt(productOldPriceByQnt);
      setDisplayedOldPrice(productOldPriceByQnt[0].price);
    }
  }, [currentProduct]);

  const handleQuantityChange = (selectedOption) => {
    if (productOldPriceByQnt && productOldPriceByQnt.length > 0) {
      const oldPrice = productOldPriceByQnt.find(
        (item) => item.quantity === selectedOption.quantity
      ).price;
      setDisplayedOldPrice(oldPrice);
    }

    setSelectedOption(selectedOption);
    setDisplayedPrice(selectedOption.value);
  };

  //Select options and default value and price
  useEffect(() => {
    if (currentProduct) {
      const transformedData = currentProduct.pricesByQnt.map((item) => {
        return {
          value: item.price,
          label: item.quantity + currentProduct.unitOfMeasure,
          quantity: item.quantity,
        };
      });
      setSelectOptions(transformedData);
      if (transformedData.length > 0) {
        setSelectedOption(transformedData[0]);
        setDisplayedPrice(transformedData[0].value);
      }
    }
  }, [currentProduct]);

  return (
    <>
      {currentProduct && (
        <StyledBootstrapCard
          style={{
            backgroundColor: currentTheme
              ? currentTheme.cardBackgroundColor
              : "white",
          }}
        >
          <CardImage to={`/produse/${currentProduct.id}`}>
            <StyledBootstrapImage
              className=" hover-shadow"
              variant="top"
              src={currentProduct ? currentProduct.image.imgSrc : ""}
              alt="product-image-card"
            />
          </CardImage>
          <StyledCardBody>
            <StyledCardTitle
              style={{
                color: currentTheme
                  ? ` ${currentTheme.cardTitleColor}`
                  : "rgba(143, 30, 53, 255)",
                width: "90%",
                fontWeight: "600",
                //border: "2px solid red",
              }}
            >
              {currentProduct.name}
            </StyledCardTitle>
            <Specifications>
              {currentProduct.specifications.map((item) => {
                return (
                  <SingleSpecification key={item.id}>
                    <span
                      style={{
                        color: currentTheme
                          ? ` ${currentTheme.cardPriceColor}`
                          : "rgba(143, 30, 53, 255)",
                      }}
                    >
                      {item.title}:{" "}
                    </span>
                    <span
                      style={{
                        color: currentTheme
                          ? ` ${currentTheme.cardSpecificationsColor}`
                          : "rgba(143, 30, 53, 255)",
                      }}
                    >
                      {item.specification}
                    </span>
                  </SingleSpecification>
                );
              })}
            </Specifications>
            <Price>
              <CurrentPrice
                style={{
                  color: currentTheme
                    ? ` ${currentTheme.cardPriceColor}`
                    : "rgba(143, 30, 53, 255)",
                }}
              >
                {displayedPrice}
                <span
                  style={{
                    color: currentTheme
                      ? ` ${currentTheme.cardPriceColor}`
                      : "rgba(143, 30, 53, 255)",
                    marginLeft: "1px",
                  }}
                >
                  Ron
                </span>
              </CurrentPrice>
              {productOldPriceByQnt && productOldPriceByQnt.length > 0 && (
                <OldPrice>
                  <span style={{ marginRight: "1px", marginLeft: "12px" }}>
                    {displayedOldPrice}
                  </span>
                  Ron
                </OldPrice>
              )}
            </Price>
            {selectOptions && selectOptions.length > 0 && (
              <Quantity>
                <Select
                  value={selectedOption}
                  options={selectOptions}
                  onChange={(selectedOption) =>
                    handleQuantityChange(selectedOption)
                  }
                  menuPortalTarget={document.body}
                  style={{
                    borderRadius: "4px",
                    padding: "5px 8px",
                    marginLeft: "6px",
                    backgroundColor: currentTheme
                      ? ` ${currentTheme.cardBackgroundColor}`
                      : "white",
                  }}
                ></Select>
              </Quantity>
            )}
          </StyledCardBody>

          <DetaliiButton
            to={`/produse/${currentProduct.id}`}
            style={{
              backgroundColor: currentTheme
                ? ` ${currentTheme.cardBtnBackgroundColor}`
                : "rgba(143, 30, 53, 255)",
            }}
          >
            Detalii
          </DetaliiButton>
        </StyledBootstrapCard>
      )}
    </>
  );
};

export default SingleCard;
