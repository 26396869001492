import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";

const EditCategory = ({ modifiedData, setModifiedData, Axios }) => {
  const navigate = useNavigate();
  const params = useParams();
  const currentCategoryId = parseInt(params.id);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [menuItemsOptions, setMenuItemsOptions] = useState([]);

  useEffect(() => {
    Axios.get(`categories/${params.id}`)
      .then((response) => {
        setCurrentCategory(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [params.id]);

  useEffect(() => {
    //formes options de menu items pt select
    if (currentCategory) {
      Axios.get("menuItems").then((response) => {
        const options = response.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setMenuItemsOptions(options);
      });
    }
  }, [modifiedData, currentCategory]);
  console.log("currentCategory", currentCategory);

  const initialValues = {
    name: currentCategory?.name,
    selectedMenuItemId: currentCategory?.MenuItemId,
    path: currentCategory?.path,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Nume obligatoriu!"),
    path: Yup.string().required("path obligatoriu!"),
    selectedMenuItemId: Yup.string().required("Selecteaza o categorie!"),
  });

  const onSubmit = (data) => {
    Axios.patch(`categories/${currentCategoryId}`, data).then((response) => {
      setModifiedData(!modifiedData);
      navigate("/admin/lista-categorii");
    });
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center w-100">
      <h5>Editeaza categorie</h5>
      <>
        {currentCategory && (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ values, setFieldValue, setFieldTouched }) => {
              return (
                <Form
                  style={{ width: "400px" }}
                  className="d-flex flex-column align-items-center justify-content-center "
                >
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="alert alert-danger"
                  />
                  <label htmlFor="name" className="form-label text-danger ">
                    Nume
                  </label>
                  <Select
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "400px",
                      }),
                      menu: (base) => ({
                        ...base,
                        width: "400px",
                      }),
                    }}
                    value={menuItemsOptions.find(
                      (item) => item.value === values.selectedMenuItemId
                    )}
                    name="selectedMenuItemId"
                    options={menuItemsOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(selectedOption) => {
                      setFieldValue("selectedMenuItemId", selectedOption.value);
                    }}
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="alert alert-danger"
                  />
                  <label htmlFor="name" className="form-label text-danger ">
                    Nume
                  </label>
                  <Field
                    name="name"
                    type="text"
                    className="form-control"
                    placeholder="name"
                  />

                  <ErrorMessage
                    name="path"
                    component="div"
                    className="alert alert-danger"
                  />
                  <label htmlFor="path" className="form-label text-danger mt-2">
                    Path(fara /)
                  </label>
                  <Field
                    name="path"
                    type="text"
                    className="form-control"
                    placeholder="path"
                  />

                  <button type="submit" className="btn btn-primary">
                    Editeaza Categorie
                  </button>
                </Form>
              );
            }}
          </Formik>
        )}
      </>
    </div>
  );
};

export default EditCategory;
