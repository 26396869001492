import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import PreviewImage from "../../../Components/Admin/UploadImage.js/PreviewImage";

const ContentEditMyModalWrap = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
`;
const EditMyModal = ({ modalsData, modifiedData, setModifiedData, Axios }) => {
  const [currentModal, setCurrentModal] = useState();
  const fileRefModal = useRef(null);
  const navigate = useNavigate();
  const params = useParams();
  const id = parseInt(params.id);

  //Currentmodal

  useEffect(() => {
    if (modalsData && modalsData.length > 0) {
      const currModal = modalsData.find((modal) => modal.id === id);

      setCurrentModal(currModal);
    }
  }, [modalsData, id]);

  // console.log("modalsData", modalsData);
  // console.log("currentModal", currentModal);

  const initialValues = {
    name: currentModal?.name,
    title: currentModal?.title,
    text: currentModal?.text,
    image: null,
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Nume obligatoriu!"),
    title: Yup.string().required("Titlu obligatoriu!"),
    text: Yup.string().required("Text obligatoriu!"),
    image: Yup.mixed().notRequired("Imagine obligatorie!"),
  });
  const onSubmit = (data) => {
    //console.log(data);
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("title", data.title);
    formData.append("text", data.text);
    if (data.image) {
      formData.append("image", data.image);
    }

    //Log the FormData entries
    // for (let pair of formData.entries()) {
    //   console.log(pair[0], pair[1]);
    // }

    Axios.patch(`http://localhost:3001/mymodals/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((response) => {
      setModifiedData(!modifiedData);
      console.log(response.data);
    });

    navigate("/admin/lista-modals");
  };

  return (
    <div
      style={{ width: "100%" }}
      className="d-flex align-items-center justify-content-center"
    >
      <ContentEditMyModalWrap>
        <h5>Editeaza modal</h5>
        {currentModal && (
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ values, setFieldValue }) => {
              return (
                <Form>
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="alert alert-danger"
                  />
                  <label htmlFor="name" className="form-label text-danger">
                    Name:
                  </label>
                  <Field
                    name="name"
                    type="text"
                    className="form-control"
                    placeholder="name"
                  />
                  <ErrorMessage
                    name="title"
                    component="div"
                    className="alert alert-danger"
                  />
                  <label htmlFor="name" className="form-label text-danger">
                    Title:
                  </label>
                  <Field
                    name="title"
                    type="text"
                    className="form-control"
                    placeholder="title"
                  />
                  <ErrorMessage
                    name="text"
                    component="div"
                    className="alert alert-danger"
                  />
                  <label htmlFor="name" className="form-label text-danger">
                    Text:
                  </label>
                  <Field
                    name="text"
                    type="text"
                    className="form-control"
                    placeholder="text"
                  />
                  {/* --------------------IMAGE---------------------------*/}
                  <div className="d-flex justify-content-between">
                    <div>
                      <div>Imagine actuala:</div>
                      <img
                        src={currentModal.image.src}
                        style={{ width: "100px" }}
                      />
                    </div>
                    <div className="d-flex align-items-center mb-2">
                      <div style={{ color: "red" }}>Incarca alta imagine:</div>
                      <input
                        id="image"
                        name="image"
                        ref={fileRefModal}
                        hidden
                        type="file"
                        onChange={(e) => {
                          setFieldValue("image", e.target.files[0]);
                        }}
                      />
                      {values.image && <PreviewImage file={values.image} />}

                      <button
                        type="button"
                        onClick={() => {
                          fileRefModal.current.click();
                        }}
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                  <button type="submit" className="btn btn-primary w-100 mt-3">
                    Submit
                  </button>
                </Form>
              );
            }}
          </Formik>
        )}
      </ContentEditMyModalWrap>
    </div>
  );
};

export default EditMyModal;
