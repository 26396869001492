import React, { useRef } from "react";

import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import PreviewImage from "../../../Components/Admin/UploadImage.js/PreviewImage";
import styled from "styled-components";

const AddWrap = styled.div`
  width: 90%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  padding: 20px;
  margin-bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const AddBackgroundSvgsOrImages = ({
  modifiedData,
  setModifiedData,
  Axios,
}) => {
  let navigate = useNavigate();
  const svgOrImageRef = useRef(null);

  const initialValues = {
    svgOrImages: [],
  };

  const validationSchema = Yup.object({
    svgOrImages: Yup.array()
      .min(1, "At least one file is required!")
      .required("Svg or Image is required!"),
  });

  const onSubmit = (data) => {
    const formData = new FormData();
    data.svgOrImages.forEach((file) => {
      formData.append("svgOrImages", file); // Append each file to FormData
    });
    Axios.post("backgroundsvgsorimages", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((response) => {
      console.log(response.data);
      setModifiedData(!modifiedData);
      navigate("/admin/lista-background-svgs-or-images");
    });
  };

  return (
    <div className="w-100 d-flex justify-content-center align-items-center">
      <AddWrap>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue }) => {
            return (
              <Form className="w-50">
                <ErrorMessage
                  name="svgOrImages"
                  component="div"
                  className="alert alert-danger"
                />
                <div className=" w-100 d-flex flex-column justify-content-center align-items-center">
                  <label
                    htmlFor="svgOrImages"
                    style={{ color: "red", marginRight: "20px" }}
                    className="mb-3"
                  >
                    Upload Svg sau imagine :
                  </label>
                  <input
                    hidden
                    multiple
                    ref={svgOrImageRef}
                    name="svgOrImages"
                    type="file"
                    onChange={(e) => {
                      setFieldValue("svgOrImages", Array.from(e.target.files));
                    }}
                  />
                  <div className=" d-flex flex-wrap">
                    {values.svgOrImages &&
                      values.svgOrImages.map((file, index) => (
                        <PreviewImage key={index} file={file} />
                      ))}
                  </div>
                  <div className="d-flex  justify-content-around w-25 mt-4">
                    <button
                      className="btn btn-info"
                      type="button"
                      onClick={() => {
                        svgOrImageRef.current.click();
                      }}
                    >
                      Upload
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </AddWrap>
    </div>
  );
};

export default AddBackgroundSvgsOrImages;
