import React, { useState, useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const EditIngredient = ({ modifiedData, setModifiedData, Axios }) => {
  const navigate = useNavigate();
  const params = useParams();
  const currentIngredientId = parseInt(params.id);
  const [currentIngredient, setCurrentIngredient] = useState();

  //Find current ingredient after ingredient id:
  useEffect(() => {
    Axios.get(`ingredients/${currentIngredientId}`)
      .then((response) => {
        setCurrentIngredient(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentIngredientId]);

  const initialValues = {
    name: currentIngredient?.name,
    description: currentIngredient?.description,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Nume obligatoriu!"),
    description: Yup.string().required("Descriere obligatorie!"),
  });

  const onSubmit = (data) => {
    Axios.patch(`ingredients/${currentIngredientId}`, data).then((response) => {
      setModifiedData(!modifiedData);
      navigate("/admin/lista-ingrediente");
    });
  };
  return (
    <div className="d-flex flex-column align-items-center justify-content-center w-100">
      <h5>Editeaza ingredient</h5>
      {currentIngredient && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form
            className="d-flex flex-column align-items-center justify-content-center "
            style={{ width: "80%" }}
          >
            <ErrorMessage
              name="name"
              component="div"
              className="alert alert-danger"
            />
            <Field
              name="name"
              type="text"
              className="form-control"
              placeholder="Nume ingredient"
              style={{
                width: "100%",
              }}
            />
            <ErrorMessage
              name="description"
              component="div"
              className="alert alert-danger"
            />
            <Field
              name="description"
              as="textarea"
              rows={6}
              className="form-control mt-2"
              placeholder="descriere"
              style={{
                width: "100%",
                height: "100px",
                resize: "vertical", // Enables resizing vertically
                overflowY: "scroll",
              }}
            />
            <button className="btn btn-primary mt-2" type="submit">
              Editeaza
            </button>
          </Form>
        </Formik>
      )}
    </div>
  );
};

export default EditIngredient;
