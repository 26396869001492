import React, { useEffect } from "react";
import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";

const appear = keyframes`
from{
  opacity:0;}
  to{
    opacity:1;}
  
  `;

const Wrap = styled.div`
  width: 100%;
  margin-top: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: ${appear} 2s ease forwards;
`;

const VideoDiv = styled.div`
  width: 100%;
  height: 600px;
  //overflow: hidden; /* Ensures the video is cropped */
  position: relative;

  @media screen and (max-width: 600px) {
    height: 400px;
  }
`;
const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 60%;
`;
const LucilleNaturalCareImg = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;

  @media screen and (max-width: 600px) {
    width: 250px;
  }
`;

const CineSuntEuSection = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.4);
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  width: 100%;
  background-color: ${(props) => props.$backgroundColor || "white"};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CineSuntEuContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  @media screen and (max-width: 600px) {
    width: 90%;
  }
  @media screen and (min-width: 600px) {
    width: 60%;
  }
`;

const TitleSvgDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;
const Title = styled.div`
  font-size: 25px;
  font-weight: 500;
  text-align: center;
`;
const MyDescriptionText = styled.div``;
const MyDescriptionFragment = styled.div`
  padding: 3px;
`;

const ImagesSection = styled.div`
  padding: 20px;
  background-color: ${(props) => props.$mybackgroundcolor || "white"};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const ImageSectionContent = styled.div`
  // box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
  //   rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
`;
const ImageSectionText = styled(Container)`
  padding: 20px;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
  @media screen and (min-width: 600px) {
    width: 60%;
  }
`;
const GallerySection = styled.div`
  // border: 2px solid red;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //padding: 20px;
`;
const GalleryWrap = styled.div`
  width: 70%;
  //border: 2px solid blue;
  display: flex;
  flex-wrap: wrap;
  // align-items: center;
  justify-content: center;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

const style = {
  lucilleStyle: {
    width: "100%",
    // "@media (max-width:540px)": {
    //   width: "95%",
    // },
  },
  LogoImg: {
    maxWidth: "1000px",
    "@media (max-width:540px)": {
      maxWidth: "95%",
    },
  },
  MyDescriptionSvg: {
    width: "200px",
  },
  Image: {
    width: "150px",
    padding: "6px",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
  },
};

const HomePage = ({ currentTheme }) => {
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);
  return (
    <Wrap>
      {/* ---------------------------------Logo Image and Lucille Natural Care------------------------------ */}
      <VideoDiv>
        {/* {currentTheme.homePageVideoSrc ?
          <Video
          src={currentTheme ? currentTheme.homePageVideoSrc : ""}
          autoPlay
          loop
          muted
        />:null 
        } */}
        <img
          src="/images/home-page-images-section/homepage-cream-img.jpeg"
          alt="home-page-img  "
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "20% 70%",
          }}
        />
        <LucilleNaturalCareImg
          src={currentTheme ? currentTheme.homePageLucilleNaturalCare : ""}
          alt="Lucille img"
        />
      </VideoDiv>
      {/* ---------------------------------Logo Image and Lucille Natural Care------------------------------*/}
      <CineSuntEuSection
        $backgroundColor={
          currentTheme ? currentTheme.homePageBackgroundColor : " white"
        }
      >
        <CineSuntEuContent>
          <TitleSvgDiv>
            <img
              src={currentTheme ? currentTheme.homePageSvgCineSuntEu : ""}
              alt="eyes"
              style={style.MyDescriptionSvg}
            />
            <Title>Cine sunt eu?</Title>
          </TitleSvgDiv>
          <MyDescriptionText>
            <MyDescriptionFragment>
              <span style={{ opacity: "0" }}>Ba</span>O femeie care iubește să
              învețe de toate ! Deși formarea mea este una reală ( facultatea de
              Matematică la Univ. București), am avut o atracție mare către
              partea de medicină , astfel că am urmat în mod particular
              cursurile acestei facultăți, pentru ca mai apoi să intru în
              domeniul produselor cât mai naturale, indiferent că vorbim de
              alimentație sau de cosmetice.
            </MyDescriptionFragment>
            <MyDescriptionFragment>
              Am experimentat diferite stiluri de alimentație (vegetarian,
              vegan, keto și chiar carnivor), pentru că eu dacă nu încerc, nu-mi
              dau cu părerea. După mai bine de 12 ani de studiu și experimentare
              în medicină,diete,chimie cosmetică și alte vrute și nevrute, m-am
              întors la originile mele reale și am descoperit o altă pasiune,
              programarea.
            </MyDescriptionFragment>
            <MyDescriptionFragment>
              Dar m-a lovit de numa'! Așa că am combinat programarea cu
              produsele cosmetice și a ieșit acest site, pe care l-am prezentat
              la lucrarea de dizertație când am terminat cursul postuniversitar
              de la Politehnică. Da, știu, mă dau mare, dar sunt foarte mândră
              de realizare, mi-am pus sufletul nu numai în produsele pe care le
              fac, dar și în acest site.
            </MyDescriptionFragment>
            <MyDescriptionFragment>
              Nu vă mai plictisesc cu pasiunile mele și lipsa de modestie și vă
              las cu o urare de bine și sănătate!
            </MyDescriptionFragment>
          </MyDescriptionText>
        </CineSuntEuContent>
      </CineSuntEuSection>
      {/* -------------------------------------------IMAGES SECTION---------------------------- */}
      <ImagesSection
        $mybackgroundcolor={
          currentTheme ? currentTheme.homePageSoapsBackgroundColor : "white"
        }
      >
        <ImageSectionContent>
          <Title>Câteva din săpunurile mele favorite</Title>
          <ImageSectionText>
            A fost o perioadă când eram fascinată de săpun, făceam unul la 2
            zile, experimentând cu rețete, forme și culori. A fost o joacă
            superbă și din acea joacă au ieșit aceste modele, colorate ori cu
            pudre naturale, ori cu pigment mineral. Am ajuns chiar să-mi fac
            propriile matrițe. Ce înseamnă să ai timp!
          </ImageSectionText>

          <GallerySection>
            <GalleryWrap>
              <img
                src="/images/home-page-images-section/1.jpg"
                style={style.Image}
              />
              <img
                src="/images/home-page-images-section/2.jpg"
                style={style.Image}
              />
              <img
                src="/images/home-page-images-section/3.jpg"
                style={style.Image}
              />
              <img
                src="/images/home-page-images-section/4.jpg"
                style={style.Image}
              />
              <img
                src="/images/home-page-images-section/5.jpg"
                style={style.Image}
              />
              <img
                src="/images/home-page-images-section/6.jpg"
                style={style.Image}
              />
              <img
                src="/images/home-page-images-section/7.jpg"
                style={style.Image}
              />
              <img
                src="/images/home-page-images-section/8.jpg"
                style={style.Image}
              />
            </GalleryWrap>
            <GalleryWrap>
              <img
                src="/images/home-page-images-section/a.jpg"
                style={style.Image}
              />
              <img
                src="/images/home-page-images-section/b.jpg"
                style={style.Image}
              />
              <img
                src="/images/home-page-images-section/c.jpg"
                style={style.Image}
              />
              <img
                src="/images/home-page-images-section/d.jpg"
                style={style.Image}
              />
              <img
                src="/images/home-page-images-section/e.jpg"
                style={style.Image}
              />
              <img
                src="/images/home-page-images-section/f.jpg"
                style={style.Image}
              />
              <img
                src="/images/home-page-images-section/g.jpg"
                style={style.Image}
              />
              <img
                src="/images/home-page-images-section/i.jpg"
                style={style.Image}
              />
            </GalleryWrap>
          </GallerySection>
        </ImageSectionContent>
      </ImagesSection>
    </Wrap>
  );
};

export default HomePage;
