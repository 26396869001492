import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import PreviewImage from "../../../../Components/Admin/UploadImage.js/PreviewImage";

const SelectListsContainer = styled.div``;
const FormWrap = styled.div`
  width: 80%;
`;
const SpecificationDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  border: 1px dotted blue;
  padding: 10px;
`;

const RemoveBtn = styled.button`
  width: 80px;
  height: 40px;
`;

const CustomList = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;
const ListDiv = styled.div`
  flex-grow: 1;
`;
const Label = styled.div`
  color: green;
`;
const AddProduct = ({
  modifiedData,
  setModifiedData,
  menuItems,
  ingredientsData,
  categoryData,
  subcategoriesData,
  Axios,
}) => {
  const navigate = useNavigate();
  const fileRef = useRef(null);
  const [menuItemsList, setMenuItemsList] = useState([]);
  const [visibility, setVisibility] = useState(true);
  const [ingredientsList, setIngredientsList] = useState([]);

  //--------------Options for menu items----------------
  useEffect(() => {
    if (menuItems) {
      const transformedData = menuItems.map((menuItem) => ({
        value: menuItem.id,
        label: menuItem.name,
      }));
      setMenuItemsList(transformedData);
    }
  }, [menuItems]);

  //--------------Options for ingredients----------------
  useEffect(() => {
    const transformedData = ingredientsData.map((ingredient) => ({
      value: ingredient.id,
      label: ingredient.name,
    }));
    setIngredientsList(transformedData);
  }, [ingredientsData]);

  const handleCheckBoxChange = (event) => {
    setVisibility(!visibility);
  };
  const onSubmit = (data) => {
    console.log("Data to be posted", data);
    const formData = new FormData();
    data.selectedMenuItemsCategoriesSubcategories?.forEach((item) => {
      formData.append("selectedMenuItemsIds", item.menuItemId);
      item.categories?.forEach((category) => {
        formData.append("selectedCategoriesIds", category.categoryId);
        category.subcategoriesIds?.forEach((subcategoryId) => {
          formData.append("selectedSubcategoriesIds", subcategoryId);
        });
      });
    });

    formData.append("name", data.name);
    formData.append("image", data.image);
    formData.append("recipe", data.recipe);
    formData.append("valability", data.valability);
    formData.append("unitOfMeasure", data.unitOfMeasure);
    formData.append("visibility", visibility ? "Vizibil" : "Invizibil");
    data.specifications.forEach((specification) => {
      formData.append("specificationsTitles", specification.title);
    });
    data.useInstructions.forEach((instruction) => {
      formData.append("useInstructions", instruction);
    });
    data.specifications.forEach((specification) => {
      formData.append(
        "specificationsDescriptions",
        specification.specification
      );
    });
    data.warnings.forEach((warning) => {
      formData.append("warnings", warning);
    });
    data.descriptions.forEach((description) => {
      formData.append("descriptions", description);
    });

    data.pricesByQnt.forEach((priceByQnt) => {
      formData.append(`prices`, priceByQnt.price);
      formData.append(`quantities`, priceByQnt.quantity);
    });
    data.selectedIngredientsIds.forEach((ingredientId) => {
      formData.append("selectedIngredients", ingredientId);
    });
    // Log the FormData entries
    for (let pair of formData.entries()) {
      console.log(pair[0], pair[1]);
    }
    // /-------------Adaugare produs----------------

    Axios.post("products", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((response) => {
      console.log("Return from Add Products:", response.data);
      setModifiedData(!modifiedData);
    });
    navigate("/admin/lista-produse");
  };
  const handleMenuItemsChange = (selected, setFieldValue, values) => {
    console.log("selectedMenuItems", selected);

    //Cand adaugam un element:
    if (
      selected.length > values.selectedMenuItemsCategoriesSubcategories.length
    ) {
      //creez un nou element in values.selectedMenuItemsCategoriesSubcategories:

      //Gasesc menuItemId adaugat:
      const menuItemId = selected[selected.length - 1].value;
      //gasesc obiectul menuItem cu id menuItemId:
      const menuItemObj = menuItems.find(
        (menuItem) => menuItem.id === menuItemId
      );

      const filteredCategoriesObj = categoryData
        .filter((category) => {
          return category.MenuItemId === menuItemId;
        })
        .map((category) => {
          return {
            value: category.id,
            label: category.name,
          };
        });
      const selectedCategoriesIds = [];
      const categories = [];

      const elem = {
        menuItemId: menuItemId,
        menuItemObj: menuItemObj,
        selectedCategoriesIds: selectedCategoriesIds,
        filteredCategoriesObj: filteredCategoriesObj,
        categories: categories,
      };
      setFieldValue("selectedMenuItemsCategoriesSubcategories", [
        ...values.selectedMenuItemsCategoriesSubcategories,
        elem,
      ]);
    } else {
      //Cand stergem un element:

      const selectedMenuItemsCategoriesSubcategoriesAfterDeleteElem =
        values.selectedMenuItemsCategoriesSubcategories.filter((item) => {
          return selected
            .map((selectedMenuItem) => {
              return selectedMenuItem.value;
            })
            .includes(item.menuItemId);
        });
      setFieldValue(
        "selectedMenuItemsCategoriesSubcategories",
        selectedMenuItemsCategoriesSubcategoriesAfterDeleteElem
      );
    }
  };
  const handleCategoryChange = (
    selected,
    setFieldValue,
    values,
    menuItemIndex
  ) => {
    console.log(
      `selectedCategories for menu item index ${menuItemIndex}`,
      selected
    );
    //Daca adaugam:
    if (
      values.selectedMenuItemsCategoriesSubcategories[menuItemIndex]
        .selectedCategoriesIds == [] ||
      selected.length >
        values.selectedMenuItemsCategoriesSubcategories[menuItemIndex]
          .selectedCategoriesIds.length
    ) {
      //Cream un nou element in values.selectedMenuItemsCategoriesSubcategories:
      const selectedCategoriesIds = selected.map((category) => category.value);
      setFieldValue(
        `selectedMenuItemsCategoriesSubcategories[${menuItemIndex}].selectedCategoriesIds`,
        selectedCategoriesIds
      );
      //Adaug noua categorie la categories:

      //gasesc noul categoryId adaugat:
      const categoryId = selected[selected.length - 1].value;
      //gasesc obiectul category cu id categoryId:
      const categoryObj = categoryData.find(
        (category) => category.id === categoryId
      );
      //gasesc subcategories pentru categoryId:
      const filteredSubcategoriesObj = subcategoriesData
        .filter((subcategory) => {
          return subcategory.CategoryId === categoryId;
        })
        .map((subcategory) => {
          return {
            value: subcategory.id,
            label: subcategory.name,
          };
        });
      const categories =
        values.selectedMenuItemsCategoriesSubcategories[menuItemIndex]
          .categories;
      const categoryElem = {
        categoryId: categoryId,
        categoryObj: categoryObj,
        filteredSubcategoriesObj: filteredSubcategoriesObj,
        subcategories: [],
        subcategoriesIds: [],
      };
      categories.push(categoryElem);
      setFieldValue(
        `selectedMenuItemsCategoriesSubcategories[${menuItemIndex}].categories`,
        categories
      );
    } else {
      //Daca stergem:
      const selectedMenuItemsCategoriesSubcategoriesAfterDeleteElem =
        values.selectedMenuItemsCategoriesSubcategories.map((item, index) => {
          if (index === menuItemIndex) {
            item.selectedCategoriesIds = selected.map(
              (category) => category.value
            );
            //sterg din categories elementul aferent idului sters
            item.categories = item.categories.filter((category) => {
              return selected
                .map((selectedCategory) => {
                  return selectedCategory.value;
                })
                .includes(category.categoryId);
            });
            return item;
          } else {
            return item;
          }
        });
      setFieldValue(
        "selectedMenuItemsCategoriesSubcategories",
        selectedMenuItemsCategoriesSubcategoriesAfterDeleteElem
      );
    }
  };
  const handleSubcategoryChange = (
    selected,
    setFieldValue,
    values,
    categoryIndex,
    menuItemIndex
  ) => {
    console.log(
      `selectedSubcategories for category index ${categoryIndex} and menu item index ${menuItemIndex}`,
      selected
    );
    //daca adaugam:
    if (
      selected.length >
      values.selectedMenuItemsCategoriesSubcategories[menuItemIndex].categories[
        categoryIndex
      ].subcategoriesIds.length
    ) {
      //Cream un nou element in values.selectedMenuItemsCategoriesSubcategories:
      const selectedSubcategoriesIds = selected.map((subcategory) => {
        return subcategory.value;
      });
      setFieldValue(
        `selectedMenuItemsCategoriesSubcategories[${menuItemIndex}].categories[${categoryIndex}].subcategoriesIds`,
        selectedSubcategoriesIds
      );
      //gasesc id-ul adaugat si adaug la subcategories:
      const subcategoryId = selected[selected.length - 1].value;
      const subcategoryObj = subcategoriesData.find(
        (subcategory) => subcategory.id === subcategoryId
      );
      const subcategories =
        values.selectedMenuItemsCategoriesSubcategories[menuItemIndex]
          .categories[categoryIndex].subcategories;

      subcategories.push(subcategoryObj);
      setFieldValue(
        `selectedMenuItemsCategoriesSubcategories[${menuItemIndex}].categories[${categoryIndex}].subcategories`,
        subcategories
      );
    } else {
      //daca stergem :
      const selectedMenuItemsCategoriesSubcategoriesAfterDeleteElem =
        values.selectedMenuItemsCategoriesSubcategories.map((item, index) => {
          if (index === menuItemIndex) {
            item.categories[categoryIndex].subcategoriesIds = selected.map(
              (subcategory) => subcategory.value
            );
            //sterg din subcategories elementul aferent idului sters
            item.categories[categoryIndex].subcategories = item.categories[
              categoryIndex
            ].subcategories.filter((subcategory) => {
              return selected
                .map((selectedSubcategory) => {
                  return selectedSubcategory.value;
                })
                .includes(subcategory.id);
            });
            return item;
          } else {
            return item;
          }
        });
      setFieldValue(
        "selectedMenuItemsCategoriesSubcategories",
        selectedMenuItemsCategoriesSubcategoriesAfterDeleteElem
      );
    }
  };
  //--------------Initial values----------------
  const initialValues = {
    selectedMenuItemsCategoriesSubcategories: [],
    name: "",
    image: null,
    recipe: "",
    valability: "",
    unitOfMeasure: "",
    visibility: visibility,
    useInstructions: [""],
    specifications: [{ title: "", specification: "" }],
    warnings: [],
    descriptions: [""],
    pricesByQnt: [{ price: "", quantity: "" }],
    selectedIngredientsIds: [],
  };

  return (
    <div className="d-flex  flex-column align-items-center w-100">
      <h5>Adauga produs :</h5>
      <FormWrap>
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ values, setFieldValue }) => {
            console.log("Values For Add Product", values);
            return (
              <Form>
                <SelectListsContainer>
                  {/*-------------------- SELECT LIST MENU ITEMS----------------- */}
                  <div>
                    <Label>Menu Items</Label>
                    <Select
                      isMulti
                      name="menuItems"
                      options={menuItemsList}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(selected) =>
                        handleMenuItemsChange(selected, setFieldValue, values)
                      }
                    />
                  </div>
                  {/*-------------------- SELECT LIST CATEGORIES & SUBCATEGORIES------------------ */}
                  {values.selectedMenuItemsCategoriesSubcategories &&
                    values.selectedMenuItemsCategoriesSubcategories.length >
                      0 &&
                    values.selectedMenuItemsCategoriesSubcategories.map(
                      (menuItem, menuItemIndex) => {
                        return (
                          <div key={menuItemIndex}>
                            {menuItem.filteredCategoriesObj &&
                              menuItem.filteredCategoriesObj.length > 0 && (
                                <div>
                                  {/*  ----------Categorii---------------------- */}
                                  <div>
                                    <Label>
                                      Categorii pentru{" "}
                                      {`${menuItem.menuItemObj.name}`}
                                    </Label>

                                    <Select
                                      isMulti
                                      name={`selectedMenuItemsCategoriesSubcategories[${menuItemIndex}].categories`}
                                      options={menuItem.filteredCategoriesObj}
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                      onChange={(selected) => {
                                        handleCategoryChange(
                                          selected,
                                          setFieldValue,
                                          values,
                                          menuItemIndex
                                        );
                                      }}
                                    />
                                  </div>
                                  {/* -------Subcategorii---------- */}
                                  {menuItem.categories &&
                                    menuItem.categories.length > 0 &&
                                    menuItem.categories.map(
                                      (category, categoryIndex) => {
                                        return (
                                          <div key={categoryIndex}>
                                            {category.filteredSubcategoriesObj &&
                                              category.filteredSubcategoriesObj
                                                .length > 0 && (
                                                <div>
                                                  <Label>
                                                    Subcategorii pentru{" "}
                                                    {`${category.categoryObj.name}`}
                                                  </Label>
                                                  <Select
                                                    isMulti
                                                    name={`selectedMenuItemsCategoriesSubcategories[${menuItemIndex}].categories[${categoryIndex}.subcategoriesIds]`}
                                                    options={
                                                      category.filteredSubcategoriesObj
                                                    }
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    onChange={(selected) => {
                                                      handleSubcategoryChange(
                                                        selected,
                                                        setFieldValue,
                                                        values,
                                                        categoryIndex,
                                                        menuItemIndex
                                                      );
                                                    }}
                                                  />
                                                </div>
                                              )}
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              )}
                          </div>
                        );
                      }
                    )}
                </SelectListsContainer>
                {/*-------------------- NAME----------------- */}
                <ErrorMessage
                  name="name"
                  component="div"
                  className="alert alert-danger"
                />
                <Label htmlFor="name">Nume produs</Label>
                <Field
                  name="name"
                  as="textarea"
                  className="form-control mb-2"
                  placeholder="name"
                />
                {/* --------------------IMAGE---------------------------*/}
                <div className="d-flex align-items-center mb-2">
                  <Label>Incarca imagine:</Label>
                  <input
                    id="image"
                    name="image"
                    ref={fileRef}
                    hidden
                    type="file"
                    onChange={(e) => {
                      setFieldValue("image", e.target.files[0]);
                    }}
                  />
                  {values.image && <PreviewImage file={values.image} />}

                  <button
                    type="button"
                    onClick={() => {
                      fileRef.current.click();
                    }}
                    className="btn btn-outline-info"
                  >
                    Upload
                  </button>
                </div>
                {/* --------------------RECIPE---------------------------*/}

                <ErrorMessage
                  name="recipe"
                  component="div"
                  className="alert alert-danger"
                />
                <Label htmlFor="recipe">Reteta</Label>
                <Field
                  name="recipe"
                  as="textarea"
                  rows={6}
                  className="form-control mb-2"
                  placeholder="recipe"
                />

                {/* --------------------VALABILITY---------------------------*/}
                <ErrorMessage
                  name="valability"
                  component="div"
                  className="alert alert-danger"
                />
                <Label htmlFor="valability">Valability</Label>
                <Field
                  name="valability"
                  as="textarea"
                  className="form-control mb-2"
                  placeholder="valability"
                />

                {/* --------------------UNIT OF MEASURE---------------------------*/}
                <ErrorMessage
                  name="unitOfMeasure"
                  component="div"
                  className="alert alert-danger"
                />
                <Label htmlFor="unitOfMeasure">Unitate de masura (ml,g)</Label>
                <Field
                  name="unitOfMeasure"
                  type="text"
                  className="form-control mb-2"
                  placeholder="unitOfMeasure"
                />
                {/* --------------------USE INSTRUCTIONS---------------------------*/}
                <Label htmlFor="useInstructions">Use Instructions:</Label>
                <FieldArray name="useInstructions">
                  {({ push, remove }) => (
                    <div className="mb-2">
                      {values.useInstructions.map((_, index) => (
                        <CustomList key={index}>
                          <ListDiv>
                            <Field
                              name={`useInstructions[${index}]`}
                              as="textarea"
                              placeholder="Use Instruction"
                              className="form-control"
                            />
                            <ErrorMessage
                              name={`useInstructions[${index}]`}
                              component="div"
                              className="alert alert-danger"
                            />
                          </ListDiv>

                          <RemoveBtn
                            type="button"
                            className="btn btn-danger"
                            onClick={() => remove(index)}
                          >
                            Remove
                          </RemoveBtn>
                        </CustomList>
                      ))}
                      <button
                        type="button"
                        className="btn btn-info"
                        onClick={() => push("")}
                      >
                        Add Use Instruction
                      </button>
                    </div>
                  )}
                </FieldArray>
                {/* --------------------SPECIFICATIONS---------------------------*/}
                <Label htmlFor="specifications">Specificatii</Label>
                <FieldArray name="specifications">
                  {({ push, remove }) => (
                    <div className="mb-2">
                      {values.specifications.map((_, index) => (
                        <SpecificationDiv key={index}>
                          <ListDiv>
                            {/* Textarea for the title value */}
                            <Field
                              name={`specifications[${index}].title`}
                              as="textarea"
                              placeholder="Titlu specificatie"
                              className="form-control"
                            />
                            <ErrorMessage
                              name={`specifications[${index}].title`}
                              component="div"
                              className="alert alert-danger"
                            />
                            {/* Textarea for the specification value */}
                            <Field
                              name={`specifications[${index}].specification`}
                              as="textarea"
                              placeholder="Titlu specificatie"
                              className="form-control"
                            />
                            <ErrorMessage
                              name={`specifications[${index}].specification`}
                              component="div"
                              className="alert alert-danger"
                            />
                          </ListDiv>

                          <RemoveBtn
                            type="button"
                            className="btn btn-danger"
                            onClick={() => remove(index)}
                          >
                            Remove
                          </RemoveBtn>
                        </SpecificationDiv>
                      ))}
                      <button
                        type="button"
                        className="btn btn-info"
                        onClick={() => push("")}
                      >
                        Add Specification
                      </button>
                    </div>
                  )}
                </FieldArray>

                {/* --------------------WARNINGS---------------------------*/}
                <Label htmlFor="warnings">Atentionari:</Label>
                <FieldArray name="warnings">
                  {({ push, remove }) => (
                    <div className="mb-2">
                      {values.warnings.map((_, index) => (
                        <CustomList key={index}>
                          <ListDiv>
                            <Field
                              name={`warnings[${index}]`}
                              as="textarea"
                              placeholder="Atentionare"
                              className="form-control"
                            />
                            <ErrorMessage
                              name={`warnings[${index}]`}
                              component="div"
                              className="alert alert-danger"
                            />
                          </ListDiv>

                          <RemoveBtn
                            type="button"
                            className="btn btn-danger"
                            onClick={() => remove(index)}
                          >
                            Remove
                          </RemoveBtn>
                        </CustomList>
                      ))}
                      <button
                        type="button"
                        className="btn btn-info"
                        onClick={() => push("")}
                      >
                        Add Warning
                      </button>
                    </div>
                  )}
                </FieldArray>

                {/* --------------------DESCRIPTIONS---------------------------*/}
                <Label htmlFor="descriptions">Descrieri</Label>
                <FieldArray name="descriptions">
                  {({ push, remove }) => (
                    <div className="mb-2">
                      {values.descriptions.map((_, index) => (
                        <div
                          key={index}
                          className="d-flex align-items-center mb-1"
                        >
                          <Field
                            name={`descriptions[${index}]`}
                            type="text"
                            placeholder="Descriere produs"
                            className="form-control"
                            style={{ width: "100%", height: "70px" }}
                          />
                          <ErrorMessage
                            name={`descriptions[${index}]`}
                            component="div"
                            className="alert alert-danger"
                          />
                          <RemoveBtn
                            type="button"
                            className="btn btn-danger"
                            onClick={() => remove(index)}
                          >
                            Remove
                          </RemoveBtn>
                        </div>
                      ))}
                      <button
                        type="button"
                        className="btn btn-info"
                        onClick={() => push("")}
                      >
                        Add Description
                      </button>
                    </div>
                  )}
                </FieldArray>
                {/* --------------------PRICES BY QNT---------------------------*/}
                {/* Dynamic Prices By Quantity */}
                <Label htmlFor="pricesByQnt">Preturi</Label>
                <FieldArray name="pricesByQnt">
                  {({ push, remove }) => (
                    <div className="mb-2">
                      {values.pricesByQnt.map((_, index) => (
                        <div key={index} className="d-flex mb-1">
                          <Field
                            name={`pricesByQnt[${index}].quantity`}
                            type="number"
                            placeholder="Quantity"
                            className="form-control"
                          />
                          <ErrorMessage
                            name={`pricesByQnt[${index}].quantity`}
                            component="div"
                            className="alert alert-danger"
                          />

                          <Field
                            name={`pricesByQnt[${index}].price`}
                            type="number"
                            placeholder="Price"
                            className="form-control"
                          />
                          <ErrorMessage
                            name={`pricesByQnt[${index}].price`}
                            component="div"
                            className="alert alert-danger"
                          />
                          <RemoveBtn
                            type="button"
                            className="btn btn-danger"
                            onClick={() => remove(index)}
                          >
                            Remove
                          </RemoveBtn>
                        </div>
                      ))}
                      <button
                        type="button"
                        className="btn btn-info"
                        onClick={() => push({ quantity: "", price: "" })}
                      >
                        Add Price
                      </button>
                    </div>
                  )}
                </FieldArray>
                {/* --------------------INGREDIENTS---------------------------*/}
                {/* Searchable Input for Ingredients */}
                <Label htmlFor="selectedIngredientsIds">
                  Ingrediente descrise
                </Label>
                <Select
                  isMulti
                  isSearchable={true}
                  name="selectedIngredientsIds"
                  options={ingredientsList}
                  className="basic-multi-select mb-2"
                  classNamePrefix="select"
                  onChange={(selectedOptions) => {
                    const values = selectedOptions.map(
                      (option) => option.value
                    );
                    setFieldValue("selectedIngredientsIds", values);
                  }}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      height: "150px",
                      minHeight: "60px",
                      fontSize: "16px",
                    }),
                  }}
                />
                <ErrorMessage
                  name="selectedIngredientsIds"
                  component="div"
                  className="alert alert-danger"
                />
                {/* ************************************Vizibilitatea produsului********************************* */}
                <div className="mb-3">
                  <Label
                    htmlFor="invisibilityCheckBox"
                    className="form-Label me-3 "
                  >
                    Bifați dacă doriți ca produsul să fie invizibil
                    utilizatorilor:
                  </Label>
                  <input
                    name="invisibilityCheckBox"
                    type="checkbox"
                    checked={visibility ? false : true}
                    onChange={handleCheckBoxChange}
                  />
                </div>

                <button type="submit" className="btn btn-primary mb-5">
                  Adauga Produs
                </button>
              </Form>
            );
          }}
        </Formik>
      </FormWrap>
    </div>
  );
};

export default AddProduct;
