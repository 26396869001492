import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./DesktopNavbar.css";
import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";

const appear = keyframes`
from{
  opacity:0;}
  to{
    opacity:1;}
  
  `;
const DesktopNav = styled.div`
  animation: ${appear} 1s ease forwards;
`;

const DesktopNavbar = ({ menuItems, categoryData, currentTheme }) => {
  const dropdownRef = useRef(null);
  const [openDropdown, setOpenDropdown] = useState(null);
  const handleDropdownToggle = (menuItemName) => {
    setOpenDropdown((prev) => (prev === menuItemName ? null : menuItemName));
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenDropdown(false);
    }
  };
  useEffect(() => {
    if (openDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Cleanup event listener on component unmount or when dropdown closes
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openDropdown]);
  return (
    <>
      {menuItems &&
        menuItems.length > 0 &&
        categoryData &&
        categoryData.length > 0 && (
          <DesktopNav
            className="desktop-navbar"
            style={{
              backgroundColor: currentTheme
                ? `${currentTheme.headerColor}`
                : " rgb(40, 110, 160)",
            }}
          >
            {currentTheme && currentTheme.headerLeftImage && (
              <img
                className="desktop-header-left-image"
                src={currentTheme.headerLeftImage}
                alt="logo"
              />
            )}

            <div className="desktop-menu">
              {menuItems
                .filter((menuItem) => {
                  return menuItem.id <= 3;
                })
                .map((menuItem) => {
                  if (menuItem.hasdropdown === "no") {
                    return (
                      <Link
                        key={menuItem.id}
                        className="nav-link-item "
                        to={menuItem.path}
                        style={{
                          color: currentTheme
                            ? `${currentTheme.headerTextColor}`
                            : "white",
                        }}
                      >
                        {menuItem.name}
                      </Link>
                    );
                  } else {
                    const categoriesAfterMenuItem = categoryData.filter(
                      (category) => {
                        return category.MenuItemId === menuItem.id;
                      }
                    );

                    return (
                      <div key={menuItem.id} className="dropdown">
                        <button
                          className="dropdown-btn d-flex align-items-center"
                          onClick={() => handleDropdownToggle(menuItem.name)}
                        >
                          <div
                            style={{
                              color: currentTheme
                                ? `${currentTheme.headerTextColor}`
                                : "white",
                            }}
                          >
                            {menuItem.name}
                          </div>

                          <i
                            className="fa fa-caret-down ms-1 "
                            style={{
                              color: currentTheme
                                ? `${currentTheme.headerTextColor}`
                                : "white",
                            }}
                          ></i>
                        </button>

                        {openDropdown === menuItem.name && (
                          <div
                            ref={dropdownRef}
                            className="dropdown-content"
                            style={{
                              backgroundColor: currentTheme
                                ? `${currentTheme.headerColor}`
                                : " rgb(40, 110, 160)",
                            }}
                          >
                            {categoriesAfterMenuItem &&
                              categoriesAfterMenuItem.length > 0 &&
                              categoriesAfterMenuItem.map((category) => {
                                return (
                                  <Link
                                    key={category.id}
                                    className="nav-link-item"
                                    to={`${menuItem.path}/${category.path}`}
                                    onClick={() => handleDropdownToggle(null)}
                                    style={{
                                      color: currentTheme
                                        ? `${currentTheme.headerTextColor}`
                                        : "white",
                                    }}
                                  >
                                    {category.name}
                                  </Link>
                                );
                              })}
                          </div>
                        )}
                      </div>
                    );
                  }
                })}

              <Link to="/">
                <img
                  src={currentTheme ? currentTheme.logoSvgSrc : ""}
                  alt="logo"
                  className="desktop-logo"
                />
              </Link>

              {menuItems
                .filter((menuItem) => {
                  return menuItem.id > 3;
                })
                .map((menuItem) => {
                  if (menuItem.hasdropdown === "no") {
                    return (
                      <Link
                        key={menuItem.id}
                        className="nav-link-item menu-item"
                        to={menuItem.path}
                        style={{
                          color: currentTheme
                            ? `${currentTheme.headerTextColor}`
                            : "white",
                        }}
                      >
                        {menuItem.name}
                      </Link>
                    );
                  } else {
                    const categoriesAfterMenuItem = categoryData.filter(
                      (category) => {
                        return category.MenuItemId === menuItem.id;
                      }
                    );

                    return (
                      <div key={menuItem.id} className="dropdown">
                        <button
                          className="dropdown-btn d-flex align-items-center"
                          onClick={() => handleDropdownToggle(menuItem.name)}
                        >
                          <div
                            style={{
                              color: currentTheme
                                ? `${currentTheme.headerTextColor}`
                                : "white",
                            }}
                          >
                            {menuItem.name}
                          </div>

                          <i
                            className="fa fa-caret-down ms-1 "
                            style={{
                              color: currentTheme
                                ? `${currentTheme.headerTextColor}`
                                : "white",
                            }}
                          ></i>
                        </button>

                        {openDropdown === menuItem.name && (
                          <div
                            ref={dropdownRef}
                            className="dropdown-content"
                            style={{
                              backgroundColor: currentTheme
                                ? `${currentTheme.headerColor}`
                                : " rgb(40, 110, 160)",
                            }}
                          >
                            {categoriesAfterMenuItem &&
                              categoriesAfterMenuItem.length > 0 &&
                              categoriesAfterMenuItem.map((category) => {
                                return (
                                  <Link
                                    key={category.id}
                                    className="nav-link-item"
                                    to={`${menuItem.path}/${category.path}`}
                                    onClick={() => handleDropdownToggle(null)}
                                    style={{
                                      color: currentTheme
                                        ? `${currentTheme.headerTextColor}`
                                        : "white",
                                    }}
                                  >
                                    {category.name}
                                  </Link>
                                );
                              })}
                          </div>
                        )}
                      </div>
                    );
                  }
                })}
            </div>
            {currentTheme && currentTheme.headerRightImage && (
              <img
                className="desktop-header-right-image"
                src={currentTheme.headerRightImage}
                alt="logo"
              />
            )}
          </DesktopNav>
        )}
    </>
  );
};

export default DesktopNavbar;
