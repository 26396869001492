import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import AppLayout from "./PageLayouts/AppLayout";
import HomePage from "../Pages/HomePage";
import ProductListPage from "../Pages/ProductListPage/ProductListPage";
import MenuItemPageLayout from "./PageLayouts/MenuItemPageLayout";
import ProductPage from "../Pages/ProductPage/ProductPage";

const Views = ({
  oldProductPricesObj,
  sideNav,
  showSideNav,
  modifiedData,
  menuItems,
  categoryData,
  subcategoriesData,
  categoryDescriptionsData,
  currentTheme,
  Axios,
}) => {
  const [allCompletProducts, setAllCompletProducts] = useState([]);

  //Gasesc toate produsele complete si apoi le sortez functie de menuitem sau categorie
  useEffect(() => {
    Axios.get("products/getAllCompletProducts").then((response) => {
      setAllCompletProducts(response.data);
    });
  }, [modifiedData]);
  //console.log("allCompletProducts", allCompletProducts);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <AppLayout
            menuItems={menuItems}
            categoryData={categoryData}
            categoryDescriptionsData={categoryDescriptionsData}
            sideNav={sideNav}
            showSideNav={showSideNav}
            currentTheme={currentTheme}
          />
        }
      >
        <Route
          index
          element={
            <HomePage
              currentTheme={currentTheme}
              categoryData={categoryData}
              menuItems={menuItems}
              subcategoriesData={subcategoriesData}
            />
          }
        />
        <Route
          path="produse/:id"
          element={
            <ProductPage
              allCompletProducts={allCompletProducts}
              currentTheme={currentTheme}
              oldProductPricesObj={oldProductPricesObj}
            />
          }
        />

        {/* ------------------Rute pentru ProductListPage----------------- */}
        {menuItems.map((menuItem) => {
          //Gasesc toate produsele pt acest menu item
          const productsForCurrentMenuItem = allCompletProducts.filter(
            (product) => {
              return product.selectedMenuItemsIds.includes(menuItem.id);
            }
          );
          //--------Descrierile categoriilor pt acest menuItem--------
          const categoriesAfterMenuItem = categoryData.filter((category) => {
            return category.MenuItemId === menuItem.id;
          });
          // console.log(
          //   `categoriesAfterMenuItem ${menuItem.name}`,
          //   categoriesAfterMenuItem
          // );

          if (menuItem.hasdropdown == "no" && menuItem.name != "Home") {
            return (
              <Route
                key={menuItem.id}
                path={menuItem.path}
                element={
                  <ProductListPage
                    pageTitle={menuItem.name}
                    currentTheme={currentTheme}
                    products={productsForCurrentMenuItem}
                    oldProductPricesObj={oldProductPricesObj}
                    selectOptionsObjs={categoriesAfterMenuItem}
                    selectOptionsType={"categories"}
                    categoryDescriptionsData={categoryDescriptionsData}
                  />
                }
              />
            );
          } else {
            return (
              <Route
                key={menuItem.id}
                path={menuItem.path}
                element={<MenuItemPageLayout />}
              >
                {categoriesAfterMenuItem.length > 0 &&
                  categoriesAfterMenuItem.map((category) => {
                    //Gasesc subcategoriile:
                    const subcategories = subcategoriesData.filter((subcat) => {
                      return subcat.CategoryId === category.id;
                    });
                    const productsForCurrentCategory =
                      allCompletProducts.filter((product) => {
                        const categoriesIds = product.categories.map(
                          (category) => category.id
                        );
                        return categoriesIds.includes(category.id);
                      });
                    return (
                      <Route
                        key={category.id}
                        path={`/${menuItem.path}/${category.path}`}
                        element={
                          <ProductListPage
                            pageTitle={category.name}
                            currentTheme={currentTheme}
                            products={productsForCurrentCategory}
                            oldProductPricesObj={oldProductPricesObj}
                            selectOptionsObjs={subcategories}
                            selectOptionsType={"subcategories"}
                            categoryDescriptionsData={categoryDescriptionsData}
                          />
                        }
                      />
                    );
                  })}
              </Route>
            );
          }
        })}
      </Route>
    </Routes>
  );
};

export default Views;
