import React, { useState, useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const EditTheme = ({
  modifiedData,
  setModifiedData,
  videoData,
  backgroundSvgsOrImagesData,
  Axios,
}) => {
  const params = useParams();
  const themeId = parseInt(params.id);
  const navigate = useNavigate();
  const [currentTheme, setCurrentTheme] = useState();

  const [isSelectedLogoSvg, setIsSelectedLogoSvg] = useState(null);
  const [isSelectedBackgroundImage, setIsSelectedBackgroundImage] =
    useState(null);
  const [isSelectedMenuItemSvg, setIsSelectedMenuItemSvg] = useState(null);
  const [isSelectedVideo, setIsSelectedVideo] = useState(null);
  const [isSelectedLucilleNaturalCare, setIsSelectedLucilleNaturalCare] =
    useState(null);
  const [isSelectedSvgCineSuntEu, setIsSelectedSvgCineSuntEu] = useState(null);
  const [
    isSelectedDesktopHeaderLeftImage,
    setIsSelectedDesktopHeaderLeftImage,
  ] = useState(null);
  const [
    isSelectedDesktopHeaderRightImage,
    setIsSelectedDesktopHeaderRightImage,
  ] = useState(null);
  const [isSelectedMobileLeftImage, setIsSelectedMobileHeaderLeftImage] =
    useState(null);
  const [isSelectedMobileRightImage, setIsSelectedMobileHeaderRightImage] =
    useState(null);
  const [
    isSelectedProductPageBackgroundImage,
    setIsSelectedProductPageBackgroundImage,
  ] = useState(null);
  const [
    isSelectedProductListSubcategoryIcon,
    setIsSelectedProductListSubcategoryIcon,
  ] = useState(null);
  useEffect(() => {
    Axios.get(`themes/${themeId}`)
      .then((response) => {
        setCurrentTheme(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [themeId]);

  const initialValues = {
    homePageVideoSrc: currentTheme?.homePageVideoSrc || "",
    homePageLucilleNaturalCare: currentTheme?.homePageLucilleNaturalCare || "",
    homePageBackgroundColor: currentTheme?.homePageBackgroundColor || "",
    homePageSoapsBackgroundColor:
      currentTheme?.homePageSoapsBackgroundColor || "",
    homePageSvgCineSuntEu: currentTheme?.homePageSvgCineSuntEu || "",
    name: currentTheme?.name || "",
    homePageBackgroundColor: currentTheme?.homePageBackgroundColor || "",
    headerColor: currentTheme?.headerColor || "",
    headerTextColor: currentTheme?.headerTextColor || "",
    logoSvgSrc: currentTheme?.logoSvgSrc || "",
    headerLeftImage: currentTheme?.headerLeftImage || "",
    headerRightImage: currentTheme?.headerRightImage || "",
    backgroundImage: currentTheme?.backgroundImage || "",
    productListPageBackgroundMaskColor:
      currentTheme?.productListPageBackgroundMaskColor || "",
    productsListBorder: currentTheme?.productsListBorder || "",
    listBackgroundColor: currentTheme?.listBackgroundColor || "",
    cardBackgroundColor: currentTheme?.cardBackgroundColor || "",
    cardTitleColor: currentTheme?.cardTitleColor || "",
    cardSpecificationsColor: currentTheme?.cardSpecificationsColor || "",
    cardPriceColor: currentTheme?.cardPriceColor || "",
    cardBtnBackgroundColor: currentTheme?.cardBtnBackgroundColor || "",
    cardBtnTextColor: currentTheme?.cardBtnTextColor || "",
    productListSubcategoryIcon: currentTheme?.productListSubcategoryIcon || "",

    productPageBackgroundImage: currentTheme?.productPageBackgroundImage || "",
    productPageBackgroundMaskColor:
      currentTheme?.productPageBackgroundMaskColor || "",
    productContainerBorder: currentTheme?.productContainerBorder || "",
    productContainerBackgroundColor:
      currentTheme?.productContainerBackgroundColor || "",
    productPageTitleColor: currentTheme?.productPageTitleColor || "",
    productPagePriceColor: currentTheme?.productPagePriceColor || "",
    productPageValabilityColor: currentTheme?.productPageValabilityColor || "",
    productPageTextColor: currentTheme?.productPageTextColor || "",
    mobileHeaderLeftImage: currentTheme?.mobileHeaderLeftImage || "",
    mobileHeaderRightImage: currentTheme?.mobileHeaderRightImage || "",
    startLinearGradientLucilles:
      currentTheme?.startLinearGradientLucilles || "",
    endLinearGradientLucilles: currentTheme?.endLinearGradientLucilles || "",
    svgMenuItem: currentTheme?.svgMenuItem || "",
    hambourgerColor: currentTheme?.hambourgerColor || "",
    sideNavMenuItemColor: currentTheme?.sideNavMenuItemColor || "",
    dropdownDivColor: currentTheme?.dropdownDivColor || "",
    dropdownDivTextColor: currentTheme?.dropdownDivTextColor || "",
    backgroundCloseIcon: currentTheme?.backgroundCloseIcon || "",
    closeIconXColor: currentTheme?.closeIconXColor || "",
    activeOrInactive: currentTheme?.activeOrInactive || "",
  };

  const validationSchema = Yup.object().shape({
    // homePageVideoSrc: Yup.string().required(),
    // homePageLucilleNaturalCare: Yup.string().required(),
    // homePageBackgroundColor: Yup.string().required(),
    // name: Yup.string().required("Nume obligatoriu!"),
    // homePageBackgroundColor: Yup.string().required(
    //   "Culoare background obligatorie!"
    // ),
    // homePageSoapsBackgroundColor: Yup.string().required(
    //   "Culoare background soaps obligatorie!"
    // ),
    // homePageSvgCineSuntEu: Yup.string().required("svg obligatoriu!"),
    // headerColor: Yup.string().required("Culoare header obligatorie!"),
    // headerTextColor: Yup.string().required("Culoare text header obligatorie!"),
    // logoSvgSrc: Yup.string().required("svg obligatoriu!"),
    // headerLeftImage: Yup.string().notRequired("Imagine obligatorie!"),
    // headerRightImage: Yup.string().notRequired("Imagine obligatorie!"),
    // backgroundImage: Yup.string().required("Imagine obligatorie!"),
    // listBackgroundColor: Yup.string().required(
    //   "Culoare background list obligatorie!"
    // ),
    // cardBackgroundColor: Yup.string().required("Culoare card obligatorie!"),
    // cardTitleColor: Yup.string().required("Culoare title card obligatorie!"),
    // cardSpecificationsColor: Yup.string().required(
    //   "Culoare specificatii card obligatorie!"
    // ),
    // cardPriceColor: Yup.string().required("Culoare pret card obligatorie!"),
    // cardBtnBackgroundColor: Yup.string().required(
    //   "Culoare buton card obligatorie!"
    // ),
    // cardBtnTextColor: Yup.string().required(
    //   "Culoare text buton card obligatorie!"
    // ),
    // productListSubcategoryIcon: Yup.string().required(
    //   "productListSubcategoryIcon obligatorie!"
    // ),
    // productPageBackgroundImage: Yup.string().required(
    //   "Imagine background Product Page obligatorie!"
    // ),
    // productPageTitleColor: Yup.string().required(
    //   "Culoare title Product Page obligatorie!"
    // ),
    // productPagePriceColor: Yup.string().required(
    //   "Culoare pret Product Page obligatorie!"
    // ),
    // productPageValabilityColor: Yup.string().required(
    //   "Culoare valabilitate Product Page obligatorie!"
    // ),
    // productPageTextColor: Yup.string().required(
    //   "Culoare text Product Page obligatorie!"
    // ),
    // mobileHeaderLeftImage: Yup.string().notRequired(
    //   "mobileHeaderLeftImage obligatorie!"
    // ),
    // mobileHeaderRightImage: Yup.string().notRequired(
    //   "mobileHeaderRightImage obligatorie!"
    // ),
    // startLinearGradientLucilles: Yup.string().required(
    //   "startLinearGradientLucilles obligatorie!"
    // ),
    // endLinearGradientLucilles: Yup.string().required(
    //   "endLinearGradientLucilles obligatorie!"
    // ),
    // svgMenuItem: Yup.string().required("svg MenuItem obligatoriu!"),
    // hambourgerColor: Yup.string().required("Culoare hamburger obligatorie!"),
    // sideNavMenuItemColor: Yup.string().required(
    //   "Culoare side nav menu item obligatorie!"
    // ),
    // dropdownDivColor: Yup.string().required(
    //   "Culoare dropdown div obligatorie!"
    // ),
    // dropdownDivTextColor: Yup.string().required(
    //   "Culoare dropdown div text obligatorie!"
    // ),
    // backgroundCloseIcon: Yup.string().required(
    //   "Culoare background close icon obligatorie!"
    // ),
    // closeIconXColor: Yup.string().required("Culoare close icon x obligatorie!"),
  });
  const onSubmit = (data) => {
    data.activeOrInactive = currentTheme?.activeOrInactive;
    console.log("data", data);
    const updatedtheme = data;

    Axios.put(`http://localhost:3001/themes/${themeId}`, updatedtheme).then(
      (response) => {
        console.log("updatedTheme:", response.data);
        setModifiedData(!modifiedData);
        navigate("/admin/lista-teme-site");
      }
    );
  };
  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <h3 className="mb-4 mt-4">Editeaza tema :</h3>
      {currentTheme && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ setFieldValue, values }) => {
            //console.log("Values", values);
            return (
              <Form
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <button
                  type="submit"
                  className="btn btn-primary mt-2 mb-5"
                  style={{ height: "70px", width: "50%" }}
                >
                  <div style={{ fontSize: "25px" }}> Editeaza tema</div>
                </button>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "90%",
                  }}
                >
                  <div
                    style={{ border: "2px dotted blue" }}
                    className="p-3 w-100 mb-5"
                  >
                    <h5
                      style={{ color: "blue" }}
                      className="mt-2 text-center mb-5"
                    >
                      HOME PAGE SI HEADER:
                    </h5>
                    <div
                      className=" d-flex align-items-center justify-content-around mb-3"
                      style={{ boxShadow: "0 0 6px grey" }}
                    >
                      {/* ------------------------NUME TEMA-------------------- */}
                      <div className=" p-2">
                        <label htmlFor="name" style={{ color: "red" }}>
                          Nume tema
                        </label>
                        <Field
                          name="name"
                          className="form-control"
                          type="text"
                          placeholder="Enter name"
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="alert alert-danger"
                        />
                      </div>
                      {/* ************************************TEMA ACTIVA SAU INACTIVA********************************* */}
                      <div className="  p-2">
                        <label
                          htmlFor="activeOrInactive"
                          style={{ color: "red" }}
                          className="form-label  "
                        >
                          Activa sau inactiva:
                        </label>

                        <Field
                          readOnly
                          name="activeOrInactive"
                          type="text"
                          className="form-control "
                        />
                      </div>
                      <div className=" p-2">
                        {/* -----------------------HOME PAGE BACKGROUND COLOR-------------------- */}
                        <label
                          htmlFor="homePageBackgroundColor"
                          style={{ color: "red" }}
                        >
                          Home Page background color
                        </label>
                        <div className="d-flex align-items-center">
                          <Field
                            name="homePageBackgroundColor"
                            className="form-control"
                            type="text"
                            placeholder="Enter homePageBackgroundColor"
                          />

                          <div
                            style={{
                              width: "50px",
                              height: "20px",
                              backgroundColor: values.homePageBackgroundColor,
                              marginLeft: "10px",
                            }}
                          />
                        </div>

                        <ErrorMessage
                          name="homePageBackgroundColor"
                          component="div"
                          className="alert alert-danger"
                        />
                      </div>

                      {/* -----------------------HOME PAGE SOAPS BACKGROUND COLOR-------------------- */}
                      <div
                        className="d-flex flex-column align-items-start justify-content-center "
                        style={{ width: "300px" }}
                      >
                        <label
                          htmlFor="homePageSoapsBackgroundColor"
                          style={{ color: "red" }}
                        >
                          BackgroundColor Soaps Section
                        </label>
                        <div className="d-flex align-items-center">
                          <Field
                            name="homePageSoapsBackgroundColor"
                            className="form-control"
                            type="text"
                            placeholder=""
                          />

                          <div
                            style={{
                              width: "50px",
                              height: "20px",
                              backgroundColor:
                                values.homePageSoapsBackgroundColor,
                              marginLeft: "10px",
                            }}
                          />
                        </div>

                        <ErrorMessage
                          name="homePageSoapsBackgroundColor"
                          component="div"
                          className="alert alert-danger"
                        />
                      </div>

                      {/* ------------------------CULOARE HEADER-------------------- */}
                      <div
                        className="d-flex flex-column align-items-start justify-content-center "
                        style={{ width: "300px" }}
                      >
                        <label htmlFor="headerColor" style={{ color: "red" }}>
                          Culoare header
                        </label>
                        <div className="d-flex align-items-center">
                          <Field
                            name="headerColor"
                            className="form-control"
                            type="text"
                            placeholder="Enter header color"
                          />
                          <div
                            style={{
                              width: "50px",
                              height: "20px",
                              backgroundColor: values.headerColor,
                              marginLeft: "10px",
                            }}
                          />
                        </div>
                        <ErrorMessage
                          name="headerColor"
                          component="div"
                          className="alert alert-danger"
                        />
                      </div>
                      {/* ------------------------CULOARE TEXT HEADER-------------------- */}
                      <div
                        className="d-flex flex-column align-items-start justify-content-center "
                        style={{ width: "300px" }}
                      >
                        <label
                          htmlFor="headerTextColor"
                          style={{ color: "red" }}
                        >
                          Culoare text header
                        </label>
                        <div className="d-flex align-items-center">
                          <Field
                            name="headerTextColor"
                            className="form-control"
                            type="text"
                            placeholder="Enter header text color"
                          />
                          <div
                            style={{
                              width: "50px",
                              height: "20px",
                              backgroundColor: values.headerTextColor,
                              marginLeft: "10px",
                            }}
                          />
                        </div>
                        <ErrorMessage
                          name="headerTextColor"
                          component="div"
                          className="alert alert-danger"
                        />
                      </div>
                    </div>

                    {/* -----------------------HOME PAGE video-------------------- */}
                    <div
                      className=" p-2 w-100 d-flex flex-column align-items-center justify-content-center mb-3"
                      style={{ boxShadow: "0 0 6px grey" }}
                    >
                      <label
                        htmlFor="homePageVideoSrc"
                        style={{ color: "red" }}
                      >
                        Alege video pentru home page:
                      </label>
                      <div className="d-flex flex-wrap">
                        {videoData &&
                          videoData.length > 0 &&
                          videoData.map((video) => {
                            return (
                              <div
                                className="d-flex flex-column align-items-center justify-content-center m-2"
                                key={video.id}
                                style={{
                                  border:
                                    isSelectedVideo &&
                                    isSelectedVideo === video.id
                                      ? "2px solid red"
                                      : "1px solid grey",
                                }}
                                onClick={() => {
                                  setFieldValue("homePageVideoSrc", video.src);
                                  setIsSelectedVideo(video.id);
                                  console.log(video);
                                }}
                              >
                                <div
                                  className="mt-3 d-flex  align-items-center justify-content"
                                  style={{
                                    border: "2px solid blue",
                                    height: "50px",
                                  }}
                                >
                                  <div className="p-2">
                                    Click aici pentru selectare video
                                  </div>
                                </div>
                                <video width="320" height="240" controls>
                                  <source src={video.src} type="video/mp4" />{" "}
                                </video>
                              </div>
                            );
                          })}
                      </div>
                    </div>

                    {/* -----------------------HOME PAGE LUCILLE NATURAL CARE-------------------- */}
                    <div
                      className="p-3 w-100 d-flex flex-column align-items-center justify-content-center mb-3"
                      style={{ boxShadow: "0 0 6px grey" }}
                    >
                      <label
                        htmlFor="homePageLucilleNaturalCare"
                        style={{ color: "red" }}
                        className="mt-2 mb-2"
                      >
                        Selecteaza imagine pentru lucille natural care :
                      </label>
                      <div className="d-flex flex-wrap">
                        {backgroundSvgsOrImagesData &&
                          backgroundSvgsOrImagesData.length > 0 &&
                          backgroundSvgsOrImagesData.map((imageOrSvg) => {
                            return (
                              <div key={imageOrSvg.id} className="p-2">
                                <img
                                  src={imageOrSvg.src}
                                  style={{
                                    width: "120px",
                                    cursor: "pointer",
                                    border:
                                      isSelectedLucilleNaturalCare &&
                                      isSelectedLucilleNaturalCare ===
                                        imageOrSvg.id
                                        ? "2px solid red"
                                        : "1px solid grey",
                                  }}
                                  onClick={() => {
                                    setFieldValue(
                                      "homePageLucilleNaturalCare",
                                      imageOrSvg.src
                                    );
                                    setIsSelectedLucilleNaturalCare(
                                      imageOrSvg.id
                                    );
                                  }}
                                />
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    {/* -----------------------HOME PAGE SVG CINE SUNT EU-------------------- */}
                    <div
                      className="p-3 w-100 d-flex flex-column align-items-center justify-content-center mb-3"
                      style={{ boxShadow: "0 0 6px grey" }}
                    >
                      <label
                        htmlFor="homePageSvgCineSuntEu"
                        style={{ color: "red" }}
                        className="mt-2 mb-2"
                      >
                        Selecteaza imagine pentru sectiunea "Cine sunt eu" :
                      </label>
                      <div className="d-flex flex-wrap">
                        {backgroundSvgsOrImagesData &&
                          backgroundSvgsOrImagesData.length > 0 &&
                          backgroundSvgsOrImagesData.map((imageOrSvg) => {
                            return (
                              <div key={imageOrSvg.id} className="p-2">
                                <img
                                  src={imageOrSvg.src}
                                  style={{
                                    width: "120px",
                                    cursor: "pointer",
                                    border:
                                      isSelectedSvgCineSuntEu &&
                                      isSelectedSvgCineSuntEu === imageOrSvg.id
                                        ? "2px solid red"
                                        : "1px solid grey",
                                  }}
                                  onClick={() => {
                                    setFieldValue(
                                      "homePageSvgCineSuntEu",
                                      imageOrSvg.src
                                    );
                                    setIsSelectedSvgCineSuntEu(imageOrSvg.id);
                                  }}
                                />
                              </div>
                            );
                          })}
                      </div>
                    </div>

                    {/* ------------------------SVG LOGO-------------------- */}
                    <div
                      className="d-flex flex-column align-items-center justify-content-center mt-3 w-100"
                      style={{ boxShadow: "0 0 6px grey" }}
                    >
                      <div>
                        <div style={{ color: "red" }} className="mt-2 mb-2">
                          Logo Svg actual:
                        </div>
                        <img
                          src={currentTheme.logoSvgSrc}
                          alt="logo"
                          style={{
                            width: "120px",
                          }}
                        />
                      </div>
                      <div className="d-flex flex-column align-items-center justify-content-center">
                        <label
                          htmlFor="logoSvgSrc"
                          style={{ color: "red" }}
                          className="mt-2 mb-2"
                        >
                          Selecteaza svg pentru logo :
                        </label>
                        <div className="d-flex flex-wrap">
                          {backgroundSvgsOrImagesData &&
                            backgroundSvgsOrImagesData.length > 0 &&
                            backgroundSvgsOrImagesData.map((image) => {
                              const currentImage =
                                backgroundSvgsOrImagesData.find(
                                  (item) => item.id === image.id
                                );
                              return (
                                <div key={image.id} className="p-2">
                                  <img
                                    src={image.src}
                                    alt={image.name}
                                    style={{
                                      width: "120px",
                                      cursor: "pointer",
                                      border:
                                        isSelectedLogoSvg &&
                                        isSelectedLogoSvg.id === image.id
                                          ? "2px solid red"
                                          : "1px solid grey",
                                    }}
                                    onClick={() => {
                                      setFieldValue(
                                        "logoSvgSrc",
                                        currentImage.src
                                      );
                                      setIsSelectedLogoSvg(currentImage);
                                    }}
                                  />
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>

                    {/* ----------------------DESKTOP HEADER LEFT IMAGE-------------------- */}
                    <div
                      className="p-3 w-100 d-flex flex-column align-items-center justify-content-center mb-3"
                      style={{ boxShadow: "0 0 6px grey" }}
                    >
                      <label
                        htmlFor="headerLeftImage"
                        style={{ color: "red" }}
                        className="mt-2 mb-2"
                      >
                        Desktop Header Left Image:
                      </label>
                      <div className="d-flex flex-wrap">
                        {backgroundSvgsOrImagesData &&
                          backgroundSvgsOrImagesData.length > 0 &&
                          backgroundSvgsOrImagesData.map((imageOrSvg) => {
                            return (
                              <div key={imageOrSvg.id} className="p-2">
                                <img
                                  src={imageOrSvg.src}
                                  style={{
                                    width: "120px",
                                    cursor: "pointer",
                                    border:
                                      isSelectedDesktopHeaderLeftImage &&
                                      isSelectedDesktopHeaderLeftImage ===
                                        imageOrSvg.id
                                        ? "2px solid red"
                                        : "1px solid grey",
                                  }}
                                  onClick={() => {
                                    setFieldValue(
                                      "headerLeftImage",
                                      imageOrSvg.src
                                    );
                                    setIsSelectedDesktopHeaderLeftImage(
                                      imageOrSvg.id
                                    );
                                  }}
                                />
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    {/* ----------------------DESKTOP HEADER RIGHT IMAGE-------------------- */}
                    <div
                      className="p-3 w-100 d-flex flex-column align-items-center justify-content-center mb-3"
                      style={{ boxShadow: "0 0 6px grey" }}
                    >
                      <label
                        htmlFor="headerRightImage"
                        style={{ color: "red" }}
                        className="mt-2 mb-2"
                      >
                        Header Right image:
                      </label>
                      <div className="d-flex flex-wrap">
                        {backgroundSvgsOrImagesData &&
                          backgroundSvgsOrImagesData.length > 0 &&
                          backgroundSvgsOrImagesData.map((imageOrSvg) => {
                            return (
                              <div key={imageOrSvg.id} className="p-2">
                                <img
                                  src={imageOrSvg.src}
                                  style={{
                                    width: "120px",
                                    cursor: "pointer",
                                    border:
                                      isSelectedDesktopHeaderRightImage &&
                                      isSelectedDesktopHeaderRightImage ===
                                        imageOrSvg.id
                                        ? "2px solid red"
                                        : "1px solid grey",
                                  }}
                                  onClick={() => {
                                    setFieldValue(
                                      "headerRightImage",
                                      imageOrSvg.src
                                    );
                                    setIsSelectedDesktopHeaderRightImage(
                                      imageOrSvg.id
                                    );
                                  }}
                                />
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>

                  <div
                    style={{ border: "2px dotted blue" }}
                    className="p-3  w-100 d-flex flex-column align-items-center justify-content-center"
                  >
                    <h5 style={{ color: "blue" }} className="mt-4">
                      PRODUCT LIST PAGE :
                    </h5>
                    {/* ------------------------PRODUCT LIST PAGE BACKGROUND IMAGE-------------------- */}
                    <div
                      className="d-flex flex-column align-items-center justify-content-center w-100"
                      style={{ boxShadow: "0 0 6px grey" }}
                    >
                      <div>
                        <div style={{ color: "red" }} className="mt-2 mb-3">
                          Imagine fundal actuala:
                        </div>
                        <img
                          src={currentTheme.backgroundImage}
                          alt="logo"
                          style={{
                            width: "120px",
                          }}
                        />
                      </div>
                      <div className="d-flex flex-column align-items-center justify-content-center">
                        <label
                          htmlFor="backgroundImage"
                          style={{ color: "red" }}
                          className="mt-2 mb-3"
                        >
                          Selecteaza imagine de fundal :
                        </label>
                        <div className="d-flex flex-wrap p-2">
                          {backgroundSvgsOrImagesData &&
                            backgroundSvgsOrImagesData.length > 0 &&
                            backgroundSvgsOrImagesData.map((image) => {
                              return (
                                <div key={image.id}>
                                  <img
                                    src={image.src}
                                    alt={image.name}
                                    style={{
                                      width: "120px",
                                      cursor: "pointer",
                                      border:
                                        isSelectedBackgroundImage &&
                                        isSelectedBackgroundImage === image.id
                                          ? "2px solid red"
                                          : "1px solid grey",
                                    }}
                                    onClick={() => {
                                      setFieldValue(
                                        "backgroundImage",
                                        image.src
                                      );
                                      setIsSelectedBackgroundImage(image.id);
                                    }}
                                  />
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                    {/* ------------------------PRODUCT LIST PAGE SUBCATEGORY ICON-------------------- */}
                    <div
                      className="d-flex flex-column align-items-center justify-content-center mt-3 w-100"
                      style={{ boxShadow: "0 0 6px grey" }}
                    >
                      <div>
                        <div style={{ color: "red" }} className="mt-2 mb-2">
                          Select list icon actual:
                        </div>
                        <img
                          src={currentTheme.productListSubcategoryIcon}
                          alt="logo"
                          style={{
                            width: "120px",
                          }}
                        />
                      </div>
                      <label
                        htmlFor="productListSubcategoryIcon"
                        style={{ color: "red" }}
                        className="mt-2 mb-3"
                      >
                        Selecteaza icon pentru select list item :
                      </label>
                      <div className="d-flex flex-wrap ">
                        {backgroundSvgsOrImagesData &&
                          backgroundSvgsOrImagesData.length > 0 &&
                          backgroundSvgsOrImagesData.map((image) => {
                            return (
                              <div key={image.id} className="p-2">
                                <img
                                  src={image.src}
                                  alt={image.name}
                                  style={{
                                    width: "120px",
                                    cursor: "pointer",
                                    border:
                                      isSelectedProductListSubcategoryIcon &&
                                      isSelectedProductListSubcategoryIcon ===
                                        image.id
                                        ? "2px solid red"
                                        : "1px solid grey",
                                  }}
                                  onClick={() => {
                                    setFieldValue(
                                      "productListSubcategoryIcon",
                                      image.src
                                    );
                                    setIsSelectedProductListSubcategoryIcon(
                                      image.id
                                    );
                                  }}
                                />
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    <div
                      style={{ boxShadow: "0 0 6px grey" }}
                      className="w-100 p-3 mt-3 d-flex flex-wrap align-items-center justify-content-start"
                    >
                      {/* ------------------------PRODUCT LIST PAGE BACKGROUND MASK COLOR-------------------- */}
                      <div
                        className="d-flex flex-column align-items-start justify-content-center "
                        style={{ width: "300px" }}
                      >
                        <label
                          htmlFor="productListPageBackgroundMaskColor"
                          style={{ color: "red" }}
                          className="mt-2"
                        >
                          Culoare background la masca fundal :
                        </label>
                        <div>
                          <div className="d-flex align-items-center">
                            <Field
                              name="productListPageBackgroundMaskColor"
                              className="form-control"
                              type="text"
                              placeholder="Enter productListPageBackgroundMaskColor"
                            />
                            <div
                              style={{
                                width: "50px",
                                height: "20px",
                                backgroundColor:
                                  values.productListPageBackgroundMaskColor,
                                marginLeft: "10px",
                              }}
                            />
                          </div>
                          <ErrorMessage
                            name="productListPageBackgroundMaskColor"
                            component="div"
                            className="alert alert-danger"
                          />
                        </div>
                      </div>
                      {/* ------------------------PRODUCT LIST BORDER-------------------- */}
                      <div
                        className="d-flex flex-column align-items-start justify-content-center "
                        style={{ width: "300px" }}
                      >
                        <label
                          htmlFor="productsListBorder"
                          style={{ color: "red" }}
                          className="mt-2"
                        >
                          Border lista produse :
                        </label>
                        <div>
                          {" "}
                          <div className="d-flex align-items-center">
                            <Field
                              name="productsListBorder"
                              className="form-control"
                              type="text"
                              placeholder="Enter productsListBorder"
                            />
                            <div
                              style={{
                                width: "50px",
                                height: "20px",
                                backgroundColor: values.productsListBorder,
                                marginLeft: "10px",
                              }}
                            />
                          </div>
                          <ErrorMessage
                            name="productsListBorder"
                            component="div"
                            className="alert alert-danger"
                          />
                        </div>
                      </div>

                      {/* ------------------------PRODUCT LIST BACKGROUND COLOR-------------------- */}
                      <div
                        className="d-flex flex-column align-items-start justify-content-center "
                        style={{ width: "300px" }}
                      >
                        {" "}
                        <label
                          htmlFor="listBackgroundColor"
                          style={{ color: "red" }}
                          className="mt-2"
                        >
                          Culoare background la lista produse
                        </label>
                        <div>
                          <div className="d-flex align-items-center">
                            <Field
                              name="listBackgroundColor"
                              className="form-control"
                              type="text"
                              placeholder="Enter list background color"
                            />
                            <div
                              style={{
                                width: "50px",
                                height: "20px",
                                backgroundColor: values.listBackgroundColor,
                                marginLeft: "10px",
                              }}
                            />
                          </div>
                          <ErrorMessage
                            name="listBackgroundColor"
                            component="div"
                            className="alert alert-danger"
                          />
                        </div>
                      </div>

                      {/* ------------------------CARD BACKGROUND COLOR-------------------- */}
                      <div
                        className="d-flex flex-column align-items-start justify-content-center "
                        style={{ width: "300px" }}
                      >
                        {" "}
                        <label
                          htmlFor="cardBackgroundColor"
                          style={{ color: "red" }}
                        >
                          Culoare background card:
                        </label>
                        <div>
                          {" "}
                          <div className="d-flex align-items-center">
                            <Field
                              name="cardBackgroundColor"
                              className="form-control"
                              type="text"
                              placeholder="Enter cardBackgroundColor"
                            />
                            <div
                              style={{
                                width: "50px",
                                height: "20px",
                                backgroundColor: values.cardBackgroundColor,
                                marginLeft: "10px",
                              }}
                            />
                          </div>
                          <ErrorMessage
                            name="cardBackgroundColor"
                            component="div"
                            className="alert alert-danger"
                          />
                        </div>
                      </div>

                      {/* ------------------------CARD TITLE COLOR-------------------- */}
                      <div
                        className="d-flex flex-column align-items-start justify-content-center "
                        style={{ width: "300px" }}
                      >
                        {" "}
                        <label
                          htmlFor="cardTitleColor"
                          style={{ color: "red" }}
                        >
                          Culoare titlu card:
                        </label>
                        <div>
                          {" "}
                          <div className="d-flex align-items-center">
                            <Field
                              name="cardTitleColor"
                              className="form-control"
                              type="text"
                              placeholder="Enter cardTitleColor"
                            />
                            <div
                              style={{
                                width: "50px",
                                height: "20px",
                                backgroundColor: values.cardTitleColor,
                                marginLeft: "10px",
                              }}
                            />
                          </div>
                          <ErrorMessage
                            name="cardTitleColor"
                            component="div"
                            className="alert alert-danger"
                          />
                        </div>
                      </div>

                      {/* ------------------------CARD SPECIFICATIONS COLOR-------------------- */}
                      <div
                        className="d-flex flex-column align-items-start justify-content-center "
                        style={{ width: "300px" }}
                      >
                        {" "}
                        <label
                          htmlFor="cardSpecificationsColor"
                          style={{ color: "red" }}
                        >
                          Culoare specifications card:
                        </label>
                        <div>
                          {" "}
                          <div className="d-flex align-items-center">
                            <Field
                              name="cardSpecificationsColor"
                              className="form-control"
                              type="text"
                              placeholder="Enter cardSpecificationsColor"
                            />
                            <div
                              style={{
                                width: "50px",
                                height: "20px",
                                backgroundColor: values.cardSpecificationsColor,
                                marginLeft: "10px",
                              }}
                            />
                          </div>
                          <ErrorMessage
                            name="cardSpecificationsColor"
                            component="div"
                            className="alert alert-danger"
                          />
                        </div>
                      </div>

                      {/* ------------------------CARD PRICE COLOR-------------------- */}
                      <div
                        className="d-flex flex-column align-items-start justify-content-center "
                        style={{ width: "300px" }}
                      >
                        <label
                          htmlFor="cardPriceColor"
                          style={{ color: "red" }}
                        >
                          Culoare pret card:
                        </label>
                        <div>
                          {" "}
                          <div className="d-flex align-items-center">
                            <Field
                              name="cardPriceColor"
                              className="form-control"
                              type="text"
                              placeholder="Enter cardPriceColor"
                            />
                            <div
                              style={{
                                width: "50px",
                                height: "20px",
                                backgroundColor: values.cardPriceColor,
                                marginLeft: "10px",
                              }}
                            />
                          </div>
                          <ErrorMessage
                            name="cardPriceColor"
                            component="div"
                            className="alert alert-danger"
                          />
                        </div>
                      </div>

                      {/* ------------------------CARD BUTTON BACKGROUND COLOR-------------------- */}
                      <div
                        className="d-flex flex-column align-items-start justify-content-center "
                        style={{ width: "300px" }}
                      >
                        {" "}
                        <label
                          htmlFor="cardBtnBackgroundColor"
                          style={{ color: "red" }}
                        >
                          Culoare buton card:
                        </label>
                        <div>
                          {" "}
                          <div className="d-flex align-items-center">
                            <Field
                              name="cardBtnBackgroundColor"
                              className="form-control"
                              type="text"
                              placeholder="Enter cardBtnBackgroundColor"
                            />
                            <div
                              style={{
                                width: "50px",
                                height: "20px",
                                backgroundColor: values.cardBtnBackgroundColor,
                                marginLeft: "10px",
                              }}
                            />
                          </div>
                          <ErrorMessage
                            name="cardBtnBackgroundColor"
                            component="div"
                            className="alert alert-danger"
                          />
                        </div>
                      </div>

                      {/* ------------------------CARD BUTTON TEXT COLOR-------------------- */}
                      <div
                        className="d-flex flex-column align-items-start justify-content-center "
                        style={{ width: "300px" }}
                      >
                        {" "}
                        <label
                          htmlFor="cardBtnTextColor"
                          style={{ color: "red" }}
                        >
                          Culoare text buton card:
                        </label>
                        <div>
                          {" "}
                          <div className="d-flex align-items-center">
                            <Field
                              name="cardBtnTextColor"
                              className="form-control"
                              type="text"
                              placeholder="Enter cardBtnTextColor"
                            />
                            <div
                              style={{
                                width: "50px",
                                height: "20px",
                                backgroundColor: values.cardBtnTextColor,
                                marginLeft: "10px",
                              }}
                            />
                          </div>
                          <ErrorMessage
                            name="cardBtnTextColor"
                            component="div"
                            className="alert alert-danger"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ border: "2px dotted blue" }}
                    className="p-3 w-100 mb-5 mt-3"
                  >
                    <h5
                      style={{ color: "blue" }}
                      className="mt-2 text-center mb-3"
                    >
                      PRODUCT PAGE :
                    </h5>
                    {/* ------------------------PRODUCT PAGE BACKGROUND IMAGE-------------------- */}
                    <div
                      className="d-flex flex-column align-items-center justify-content-center p-3"
                      style={{ boxShadow: "0 0 6px grey" }}
                    >
                      <div>
                        <div style={{ color: "red" }} className="mt-2 mb-2">
                          Imagine Product Page fundal actuala:
                        </div>
                        <img
                          src={currentTheme.productPageBackgroundImage}
                          alt="logo"
                          style={{
                            width: "120px",
                          }}
                        />
                      </div>
                      <div className="w-100 d-flex flex-column align-items-center justify-content-center">
                        <label
                          htmlFor="productPageBackgroundImage"
                          style={{ color: "red" }}
                          className="mt-3 mb-3"
                        >
                          Selecteaza imagine de fundal Product Page :
                        </label>
                        <div className="d-flex flex-wrap">
                          {backgroundSvgsOrImagesData &&
                            backgroundSvgsOrImagesData.length > 0 &&
                            backgroundSvgsOrImagesData.map((image) => {
                              return (
                                <div key={image.id} className="p-2">
                                  <img
                                    src={image.src}
                                    alt={image.name}
                                    style={{
                                      width: "120px",
                                      cursor: "pointer",
                                      border:
                                        isSelectedProductPageBackgroundImage &&
                                        isSelectedProductPageBackgroundImage ===
                                          image.id
                                          ? "2px solid red"
                                          : "1px solid grey",
                                    }}
                                    onClick={() => {
                                      setFieldValue(
                                        "productPageBackgroundImage",
                                        image.src
                                      );
                                      setIsSelectedProductPageBackgroundImage(
                                        image.id
                                      );
                                    }}
                                  />
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                    <div
                      className="d-flex flex-wrap w-100 p-3"
                      style={{ boxShadow: "0 0 6px grey" }}
                    >
                      {" "}
                      {/* ------------------------PRODUCT PAGE BACKGROUND MASK COLOR-------------------- */}
                      <div
                        className="d-flex flex-column align-items-start justify-content-center "
                        style={{ width: "300px" }}
                      >
                        {" "}
                        <label
                          htmlFor="productPageBackgroundMaskColor"
                          style={{ color: "red" }}
                        >
                          Product Page Background Mask Color:
                        </label>
                        <div>
                          {" "}
                          <div className="d-flex align-items-center">
                            <Field
                              name="productPageBackgroundMaskColor"
                              className="form-control"
                              type="text"
                              placeholder="Enter productPageBackgroundMaskColor"
                            />
                            <div
                              style={{
                                width: "50px",
                                height: "20px",
                                backgroundColor:
                                  values.productPageBackgroundMaskColor,
                                marginLeft: "10px",
                              }}
                            />
                          </div>
                          <ErrorMessage
                            name="productPageBackgroundMaskColor"
                            component="div"
                            className="alert alert-danger"
                          />
                        </div>
                      </div>
                      {/* ------------------------PRODUCT PAGE CONTAINER BORDER-------------------- */}
                      <div
                        className="d-flex flex-column align-items-start justify-content-center "
                        style={{ width: "300px" }}
                      >
                        {" "}
                        <label
                          htmlFor="productContainerBorder"
                          style={{ color: "red" }}
                        >
                          Product Page Container Border:
                        </label>
                        <div>
                          {" "}
                          <div className="d-flex align-items-center">
                            <Field
                              name="productContainerBorder"
                              className="form-control"
                              type="text"
                              placeholder="Enter productContainerBorder"
                            />
                            <div
                              style={{
                                width: "50px",
                                height: "20px",
                                backgroundColor: values.productContainerBorder,
                                marginLeft: "10px",
                              }}
                            />
                          </div>
                          <ErrorMessage
                            name="productContainerBorder"
                            component="div"
                            className="alert alert-danger"
                          />
                        </div>
                      </div>
                      {/* ------------------------PRODUCT CONTAINER BACKGROUND COLOR-------------------- */}
                      <div
                        className="d-flex flex-column align-items-start justify-content-center "
                        style={{ width: "300px" }}
                      >
                        {" "}
                        <label
                          htmlFor="productContainerBackgroundColor"
                          style={{ color: "red" }}
                        >
                          Product Container Background Color:
                        </label>
                        <div>
                          {" "}
                          <div className="d-flex align-items-center">
                            <Field
                              name="productContainerBackgroundColor"
                              className="form-control"
                              type="text"
                              placeholder="Enter productContainerBackgroundColor"
                            />
                            <div
                              style={{
                                width: "50px",
                                height: "20px",
                                backgroundColor:
                                  values.productContainerBackgroundColor,
                                marginLeft: "10px",
                              }}
                            />
                          </div>
                          <ErrorMessage
                            name="productContainerBackgroundColor"
                            component="div"
                            className="alert alert-danger"
                          />
                        </div>
                      </div>
                      {/* ------------------------PRODUCT PAGE TITLE COLOR-------------------- */}
                      <div
                        className="d-flex flex-column align-items-start justify-content-center "
                        style={{ width: "300px" }}
                      >
                        {" "}
                        <label
                          htmlFor="productPageTitleColor"
                          style={{ color: "red" }}
                        >
                          Product Page Title Color
                        </label>
                        <div>
                          {" "}
                          <div className="d-flex align-items-center">
                            <Field
                              name="productPageTitleColor"
                              className="form-control"
                              type="text"
                              placeholder="Enter productPageTitleColor"
                            />
                            <div
                              style={{
                                width: "50px",
                                height: "20px",
                                backgroundColor: values.productPageTitleColor,
                                marginLeft: "10px",
                              }}
                            />
                          </div>
                          <ErrorMessage
                            name="productPageTitleColor"
                            component="div"
                            className="alert alert-danger"
                          />
                        </div>
                      </div>
                      {/* ------------------------PRODUCT PAGE PRICE COLOR-------------------- */}
                      <div
                        className="d-flex flex-column align-items-start justify-content-center "
                        style={{ width: "300px" }}
                      >
                        <label
                          htmlFor="productPagePriceColor"
                          style={{ color: "red" }}
                        >
                          Product Page price Color
                        </label>
                        <div>
                          {" "}
                          <div className="d-flex align-items-center">
                            <Field
                              name="productPagePriceColor"
                              className="form-control"
                              type="text"
                              placeholder="Enter productPagePriceColor"
                            />
                            <div
                              style={{
                                width: "50px",
                                height: "20px",
                                backgroundColor: values.productPagePriceColor,
                                marginLeft: "10px",
                              }}
                            />
                          </div>
                          <ErrorMessage
                            name="productPagePriceColor"
                            component="div"
                            className="alert alert-danger"
                          />
                        </div>
                      </div>
                      {/* ------------------------PRODUCT PAGE VALABILITY COLOR-------------------- */}
                      <div
                        className="d-flex flex-column align-items-start justify-content-center "
                        style={{ width: "300px" }}
                      >
                        {" "}
                        <label
                          htmlFor="productPageValabilityColor"
                          style={{ color: "red" }}
                        >
                          Product Page valability Color
                        </label>
                        <div>
                          {" "}
                          <div className="d-flex align-items-center">
                            <Field
                              name="productPageValabilityColor"
                              className="form-control"
                              type="text"
                              placeholder="Enter productPageValabilityColor"
                            />
                            <div
                              style={{
                                width: "50px",
                                height: "20px",
                                backgroundColor:
                                  values.productPageValabilityColor,
                                marginLeft: "10px",
                              }}
                            />
                          </div>
                          <ErrorMessage
                            name="productPageValabilityColor"
                            component="div"
                            className="alert alert-danger"
                          />
                        </div>
                      </div>
                      {/* ------------------------PRODUCT PAGE TEXT COLOR-------------------- */}
                      <div
                        className="d-flex flex-column align-items-start justify-content-center "
                        style={{ width: "300px" }}
                      >
                        {" "}
                        <label
                          htmlFor="productPageTextColor"
                          style={{ color: "red" }}
                        >
                          Product Page Text Color
                        </label>
                        <div>
                          {" "}
                          <div className="d-flex align-items-center">
                            <Field
                              name="productPageTextColor"
                              className="form-control"
                              type="text"
                              placeholder="Enter product page text color"
                            />
                            <div
                              style={{
                                width: "50px",
                                height: "20px",
                                backgroundColor: values.productPageTextColor,
                                marginLeft: "10px",
                              }}
                            />
                          </div>
                          <ErrorMessage
                            name="productPageTextColor"
                            component="div"
                            className="alert alert-danger"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{ border: "2px dotted blue" }}
                    className="w-100 p-3 d-flex flex-column align-items-center justify-content-center"
                  >
                    <h5 style={{ color: "blue" }} className="mt-4">
                      MOBILE
                    </h5>

                    {/* ---------------------- MOBILE HEADER LEFT IMAGE-------------------- */}
                    <div
                      className="p-3 w-100 d-flex flex-column align-items-center justify-content-center mb-3"
                      style={{ boxShadow: "0 0 6px grey" }}
                    >
                      <label
                        htmlFor="mobileHeaderLeftImage"
                        style={{ color: "red" }}
                        className="mt-2 mb-2"
                      >
                        Mobile Header Left Image:
                      </label>
                      <div className="d-flex flex-wrap">
                        {backgroundSvgsOrImagesData &&
                          backgroundSvgsOrImagesData.length > 0 &&
                          backgroundSvgsOrImagesData.map((imageOrSvg) => {
                            return (
                              <div key={imageOrSvg.id} className="p-2">
                                <img
                                  src={imageOrSvg.src}
                                  style={{
                                    width: "120px",
                                    cursor: "pointer",
                                    border:
                                      isSelectedMobileLeftImage &&
                                      isSelectedMobileLeftImage ===
                                        imageOrSvg.id
                                        ? "2px solid red"
                                        : "1px solid grey",
                                  }}
                                  onClick={() => {
                                    setFieldValue(
                                      "mobileHeaderLeftImage",
                                      imageOrSvg.src
                                    );
                                    setIsSelectedMobileHeaderLeftImage(
                                      imageOrSvg.id
                                    );
                                  }}
                                />
                              </div>
                            );
                          })}
                      </div>
                    </div>

                    {/* ---------------------- MOBILE HEADER RIGHT IMAGE-------------------- */}
                    <div
                      className="p-3 w-100 d-flex flex-column align-items-center justify-content-center mb-3"
                      style={{ boxShadow: "0 0 6px grey" }}
                    >
                      <label
                        htmlFor="mobileHeaderRightImage"
                        style={{ color: "red" }}
                        className="mt-2 mb-2"
                      >
                        Mobile Header Right Image:
                      </label>
                      <div className="d-flex flex-wrap">
                        {backgroundSvgsOrImagesData &&
                          backgroundSvgsOrImagesData.length > 0 &&
                          backgroundSvgsOrImagesData.map((imageOrSvg) => {
                            return (
                              <div key={imageOrSvg.id} className="p-2">
                                <img
                                  src={imageOrSvg.src}
                                  style={{
                                    width: "120px",
                                    cursor: "pointer",
                                    border:
                                      isSelectedMobileRightImage &&
                                      isSelectedMobileRightImage ===
                                        imageOrSvg.id
                                        ? "2px solid red"
                                        : "1px solid grey",
                                  }}
                                  onClick={() => {
                                    setFieldValue(
                                      "mobileHeaderRightImage",
                                      imageOrSvg.src
                                    );
                                    setIsSelectedMobileHeaderRightImage(
                                      imageOrSvg.id
                                    );
                                  }}
                                />
                              </div>
                            );
                          })}
                      </div>
                    </div>

                    {/* ------------------------SVG MENU ITEM-------------------- */}
                    <div
                      className="d-flex flex-column align-items-center justify-content-center p-3 w-100"
                      style={{ boxShadow: "0 0 6px grey" }}
                    >
                      <div>
                        <div style={{ color: "red" }} className="mt-3">
                          Logo Svg actual:
                        </div>
                        <img
                          src={currentTheme.logoSvgSrc}
                          alt="logo"
                          style={{
                            width: "120px",
                          }}
                        />
                      </div>
                      <div className="d-flex flex-column align-items-center justify-content-center">
                        <label
                          htmlFor="svgMenuItem"
                          style={{ color: "red" }}
                          className="mt-2 mb-2"
                        >
                          Selecteaza svg pentru menu item mobil :
                        </label>
                        <div className="d-flex flex-wrap ">
                          {backgroundSvgsOrImagesData &&
                            backgroundSvgsOrImagesData.length > 0 &&
                            backgroundSvgsOrImagesData.map((imageOrSvg) => {
                              const currentImageOrSvg =
                                backgroundSvgsOrImagesData.find(
                                  (item) => item.id === imageOrSvg.id
                                );
                              return (
                                <div key={imageOrSvg.id} className="p-2">
                                  <img
                                    src={imageOrSvg.src}
                                    alt={imageOrSvg.name}
                                    style={{
                                      width: "120px",
                                      cursor: "pointer",
                                      border:
                                        isSelectedMenuItemSvg &&
                                        isSelectedMenuItemSvg.id ===
                                          imageOrSvg.id
                                          ? "2px solid red"
                                          : "1px solid grey",
                                    }}
                                    onClick={() => {
                                      setFieldValue(
                                        "svgMenuItem",
                                        currentImageOrSvg.src
                                      );
                                      setIsSelectedMenuItemSvg(
                                        currentImageOrSvg
                                      );
                                    }}
                                  />
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                    <div
                      className="w-100 p-3 mt-3 d-flex flex-wrap align-items-center "
                      style={{ boxShadow: "0 0 6px grey" }}
                    >
                      {/* ------------------------LUCILLE'S TEXT START LINEAR GRADIENT-------------------- */}
                      <div
                        className="d-flex flex-column align-items-start justify-content-center"
                        style={{ width: "300px" }}
                      >
                        <label
                          htmlFor="startLinearGradientLucilles"
                          style={{ color: "red" }}
                          className="mt-2"
                        >
                          Start lucille's linear gradient
                        </label>
                        <div>
                          {" "}
                          <div className="d-flex align-items-center">
                            <Field
                              name="startLinearGradientLucilles"
                              className="form-control"
                              type="text"
                              placeholder="Enter start lucille's linear gradient"
                            />
                            <div
                              style={{
                                width: "50px",
                                height: "20px",
                                backgroundColor:
                                  values.startLinearGradientLucilles,
                                marginLeft: "10px",
                              }}
                            />
                          </div>
                          <ErrorMessage
                            name="startLinearGradientLucilles"
                            component="div"
                            className="alert alert-danger"
                          />
                        </div>
                      </div>
                      {/* ------------------------LUCILLE'S TEXT END LINEAR GRADIENT-------------------- */}
                      <div
                        className="d-flex flex-column align-items-start justify-content-center"
                        style={{ width: "300px" }}
                      >
                        <label
                          htmlFor="endLinearGradientLucilles"
                          style={{ color: "red" }}
                          className="mt-2"
                        >
                          End lucille's linear gradient
                        </label>
                        <div>
                          {" "}
                          <div className="d-flex align-items-center">
                            <Field
                              name="endLinearGradientLucilles"
                              className="form-control"
                              type="text"
                              placeholder="Enter start lucille's linear gradient"
                            />
                            <div
                              style={{
                                width: "50px",
                                height: "20px",
                                backgroundColor:
                                  values.endLinearGradientLucilles,
                                marginLeft: "10px",
                              }}
                            />
                          </div>
                          <ErrorMessage
                            name="endLinearGradientLucilles"
                            component="div"
                            className="alert alert-danger"
                          />
                        </div>
                      </div>

                      {/* ------------------------HAMBOURGER COLOR-------------------- */}
                      <div
                        className="d-flex flex-column align-items-start justify-content-center"
                        style={{ width: "300px" }}
                      >
                        <label
                          htmlFor="hambourgerColor"
                          style={{ color: "red" }}
                          className="mt-2"
                        >
                          Culoare hambourger
                        </label>
                        <div>
                          {" "}
                          <div className="d-flex align-items-center">
                            <Field
                              name="hambourgerColor"
                              className="form-control"
                              type="text"
                              placeholder="Enter hambourger color"
                            />
                            <div
                              style={{
                                width: "50px",
                                height: "20px",
                                backgroundColor: values.hambourgerColor,
                                marginLeft: "10px",
                              }}
                            />
                          </div>
                          <ErrorMessage
                            name="hambourgerColor"
                            component="div"
                            className="alert alert-danger"
                          />
                        </div>
                      </div>

                      {/* ------------------------SIDENAV MENU ITEM COLOR-------------------- */}
                      <div
                        className="d-flex flex-column align-items-start justify-content-center"
                        style={{ width: "300px" }}
                      >
                        <label
                          htmlFor="sideNavMenuItemColor"
                          style={{ color: "red" }}
                          className="mt-2"
                        >
                          Culoare sidenav menu item
                        </label>
                        <div>
                          <div className="d-flex align-items-center">
                            <Field
                              name="sideNavMenuItemColor"
                              className="form-control"
                              type="text"
                              placeholder="Enter sideNavMenuItemColor"
                            />
                            <div
                              style={{
                                width: "50px",
                                height: "20px",
                                backgroundColor: values.sideNavMenuItemColor,
                                marginLeft: "10px",
                              }}
                            />
                          </div>
                          <ErrorMessage
                            name="sideNavMenuItemColor"
                            component="div"
                            className="alert alert-danger"
                          />
                        </div>
                      </div>

                      {/* ------------------------DROPDOWN DIV COLOR-------------------- */}
                      <div
                        className="d-flex flex-column align-items-start justify-content-center"
                        style={{ width: "300px" }}
                      >
                        {" "}
                        <label
                          htmlFor="dropdownDivColor"
                          style={{ color: "red" }}
                          className="mt-2"
                        >
                          Culoare dropdown div
                        </label>
                        <div>
                          {" "}
                          <div className="d-flex align-items-center">
                            <Field
                              name="dropdownDivColor"
                              className="form-control"
                              type="text"
                              placeholder="Enter dropdownDivColor"
                            />
                            <div
                              style={{
                                width: "50px",
                                height: "20px",
                                backgroundColor: values.dropdownDivColor,
                                marginLeft: "10px",
                              }}
                            />
                          </div>
                          <ErrorMessage
                            name="dropdownDivColor"
                            component="div"
                            className="alert alert-danger"
                          />
                        </div>
                      </div>

                      {/* ------------------------DROPDOWN DIV TEXT COLOR-------------------- */}
                      <div
                        className="d-flex flex-column align-items-start justify-content-center"
                        style={{ width: "300px" }}
                      >
                        {" "}
                        <label
                          htmlFor="dropdownDivTextColor"
                          style={{ color: "red" }}
                          className="mt-2"
                        >
                          Culoare dropdown div text color
                        </label>
                        <div>
                          {" "}
                          <div className="d-flex align-items-center">
                            <Field
                              name="dropdownDivTextColor"
                              className="form-control"
                              type="text"
                              placeholder="Enter dropdownDivTextColor"
                            />
                            <div
                              style={{
                                width: "50px",
                                height: "20px",
                                backgroundColor: values.dropdownDivTextColor,
                                marginLeft: "10px",
                              }}
                            />
                          </div>
                          <ErrorMessage
                            name="dropdownDivTextColor"
                            component="div"
                            className="alert alert-danger"
                          />
                        </div>
                      </div>

                      {/* ------------------------BACKGROUND CLOSE ICON COLOR-------------------- */}
                      <div
                        className="d-flex flex-column align-items-start justify-content-center"
                        style={{ width: "300px" }}
                      >
                        <label
                          htmlFor="backgroundCloseIcon"
                          style={{ color: "red" }}
                          className="mt-2"
                        >
                          Background Close Icon Color
                        </label>
                        <div>
                          {" "}
                          <div className="d-flex align-items-center">
                            <Field
                              name="backgroundCloseIcon"
                              className="form-control"
                              type="text"
                              placeholder="Enter backgroundCloseIcon"
                            />
                            <div
                              style={{
                                width: "50px",
                                height: "20px",
                                backgroundColor: values.backgroundCloseIcon,
                                marginLeft: "10px",
                              }}
                            />
                          </div>
                          <ErrorMessage
                            name="backgroundCloseIcon"
                            component="div"
                            className="alert alert-danger"
                          />
                        </div>
                      </div>

                      {/* ------------------------X CLOSE ICON COLOR-------------------- */}
                      <div
                        className="d-flex flex-column align-items-start justify-content-center"
                        style={{ width: "300px" }}
                      >
                        <label
                          htmlFor="closeIconXColor"
                          style={{ color: "red" }}
                          className="mt-2"
                        >
                          Background Close Icon Color
                        </label>
                        <div>
                          {" "}
                          <div className="d-flex align-items-center">
                            <Field
                              name="closeIconXColor"
                              className="form-control"
                              type="text"
                              placeholder="Enter closeIconXColor"
                            />
                            <div
                              style={{
                                width: "50px",
                                height: "20px",
                                backgroundColor: values.closeIconXColor,
                                marginLeft: "10px",
                              }}
                            />
                          </div>
                          <ErrorMessage
                            name="closeIconXColor"
                            component="div"
                            className="alert alert-danger"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary mt-2 mb-5"
                  style={{ height: "70px", width: "50%" }}
                >
                  <div style={{ fontSize: "25px" }}> Editeaza tema</div>
                </button>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default EditTheme;
