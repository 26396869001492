import React from "react";
import "./LoginDiv.css";
import { useState } from "react";

const LoginDiv = ({ setLogged }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (username == "adminbumbunnyca" && password == "bumbunnyca!85") {
      setLogged(true);
    } else {
      if (username != "adminbumbunnyca") {
        alert("Username gresit");
      }
      if (password != "bumbunnyca!85") {
        alert("Parola gresita");
      }
    }
  };
  return (
    <div className="form">
      <form onSubmit={(e) => handleSubmit(e)}>
        <h3>LOGIN</h3>

        <div className="mb-3">
          <label>Username</label>
          <input
            type="text"
            className="form-control"
            placeholder=""
            onChange={(event) => {
              setUsername(event.target.value);
            }}
          />
        </div>
        <div className="mb-3">
          <label>Password</label>
          <input
            type="password"
            className="form-control"
            placeholder=""
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />
        </div>
        <div className="d-grid">
          <button type="submit" className="btn btn-warning">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default LoginDiv;
