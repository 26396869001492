import React, { useState, useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const EditSubcategory = ({ modifiedData, setModifiedData, Axios }) => {
  const navigate = useNavigate();
  const params = useParams();
  const [currentSubcategory, setCurrentSubcategory] = useState(null);

  useEffect(() => {
    Axios.get(`subcategories/${params.id}`).then((response) => {
      setCurrentSubcategory(response.data);
    });
  }, [params.id]);
  console.log(currentSubcategory);
  const initialValues = {
    name: currentSubcategory?.name,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Nume obligatoriu!"),
  });

  const onSubmit = (data) => {
    Axios.patch(`subcategories/${params.id}`, data).then((response) => {
      console.log(response.data);
      setModifiedData(!modifiedData);
      navigate("/admin/lista-subcategorii");
    });
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <h5>Editează subcategorie :</h5>
      {currentSubcategory && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form>
            <ErrorMessage
              name="name"
              component="div"
              className="alert alert-danger"
            />
            <Field
              name="name"
              type="text"
              className="form-control"
              placeholder="name"
            />

            <button type="submit" className="btn btn-primary mt-2 mb-4">
              Editează
            </button>
          </Form>
        </Formik>
      )}
    </div>
  );
};

export default EditSubcategory;
