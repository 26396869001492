import React from "react";

import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const AddIngredient = ({ modifiedData, setModifiedData, Axios }) => {
  let navigate = useNavigate();
  const initialValues = {
    name: "",
    description: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Nume obligatoriu!"),
    description: Yup.string().required("Descriere obligatorie!"),
  });

  const onSubmit = (data) => {
    Axios.post("ingredients", data).then((response) => {
      setModifiedData(!modifiedData);
      navigate("/admin/lista-ingrediente");
    });
  };

  return (
    <div className="w-100 d-flex align-items-center justify-content-center">
      <div style={{ width: "80%" }}>
        <h5 className="text-center">Adauga ingredient:</h5>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form>
            <ErrorMessage
              name="name"
              component="div"
              className="alert alert-danger"
            />
            <Field
              name="name"
              type="text"
              className="form-control"
              placeholder="nume"
              style={{
                width: "100%",
              }}
            />
            <ErrorMessage
              name="description"
              component="div"
              className="alert alert-danger"
            />
            <Field
              name="description"
              as="textarea"
              className="form-control mt-2"
              placeholder="descriere"
              style={{
                width: "100%",
                height: "150px",
                resize: "vertical", // Enables resizing vertically
                overflowY: "scroll",
              }}
            />
            <button type="submit" className="btn btn-primary mt-2">
              Adauga
            </button>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default AddIngredient;
