import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";

const AddSubcategory = ({
  categoryData,
  modifiedData,
  setModifiedData,
  Axios,
}) => {
  const navigate = useNavigate();
  const [categoriesOptions, setCategoriesOptions] = useState([]);

  const initialValues = {
    name: "",
    selectedCategoryId: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Nume obligatoriu!"),
    selectedCategoryId: Yup.string().required("Categorie obligatorie!"),
  });

  const onSubmit = (data) => {
    //console.log(data);
    Axios.post("subcategories", data).then((response) => {
      setModifiedData(!modifiedData);
      navigate("/admin/lista-subcategorii");
    });
  };
  useEffect(() => {
    const transformedData = categoryData.map((category) => {
      return { value: category.id, label: category.name };
    });
    setCategoriesOptions(transformedData);
  }, []);
  return (
    <div className="d-flex flex-column align-items-center justify-content-center w-100">
      <div
        className="d-flex flex-column align-items-center justify-content-center "
        style={{ width: "50%" }}
      >
        <h5>Adauga subcategorie:</h5>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue }) => {
            //console.log(values);
            return (
              <Form>
                <ErrorMessage
                  name="name"
                  component="div"
                  className="alert alert-danger"
                />
                <Field
                  name="name"
                  type="text"
                  className="form-control"
                  placeholder="name"
                />

                <label htmlFor="selectedCategoryId" style={{ color: "red" }}>
                  Alege categoria:
                </label>
                <Select
                  name="selectedCategoryId"
                  options={categoriesOptions}
                  className="basic-single-select mb-2"
                  classNamePrefix="select"
                  onChange={(selectedoption) =>
                    setFieldValue("selectedCategoryId", selectedoption.value)
                  }
                />

                <button type="submit" className="btn btn-primary mb-5 mt-2">
                  Adauga subategorie
                </button>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AddSubcategory;
