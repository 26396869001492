import "./App.css";
import { useState } from "react";
import Views from "./Components/Views";
import { useEffect } from "react";
import MyModal from "./Components/MyModal";
import { Axios } from "./config";
import LoadingSpinner from "./Components/LoadingSpinner";
import AdminViews from "./Components/AdminViews";
import { Routes, Route } from "react-router-dom";

function App() {
  const [sideNav, setSideNav] = useState(false);
  const showSideNav = () => {
    setSideNav(!sideNav);
  };
  const [isThemeLoaded, setIsThemeLoaded] = useState(false);
  const [priceOffPercentage, setPriceOffPercentage] = useState(0);
  const [priceOffObjs, setPriceOffObjs] = useState([]);
  const [oldProductPricesObj, setOldProductPricesObj] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modifiedData, setModifiedData] = useState(false);
  const [logged, setLogged] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [subcategoriesData, setSubcategoriesData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [categoryDescriptionsData, setCategoryDescriptionsData] = useState([]);
  const [imagesData, setImagesData] = useState([]);
  const [productCategoryData, setProductCategoryData] = useState([]);
  const [descriptionsData, setDescriptionsData] = useState([]);
  const [pricesByQntData, setPricesByQntData] = useState([]);
  const [ingredientsData, setIngredientsData] = useState([]);
  const [productIngredientData, setProductIngredientData] = useState([]);
  const [specificationsData, setSpecificationsData] = useState([]);
  const [backgroundSvgsOrImagesData, setBackgroundSvgsOrImagesData] = useState(
    []
  );

  const [videoData, setVideoData] = useState([]);
  const [themesData, setThemesData] = useState([]);
  const [currentTheme, setCurrentTheme] = useState();
  const [modalsData, setModalsData] = useState([]);

  // Simulate loading the theme
  useEffect(() => {
    if (currentTheme) {
      // If the theme is available, mark as loaded
      setIsThemeLoaded(true);
    }
  }, [currentTheme]);

  //*********************PRICE OFF FROM DB AS vector with one OBJ********************************
  useEffect(() => {
    Axios.get("priceoff").then((response) => {
      setPriceOffObjs(response.data);
    });
  }, [modifiedData]);

  //*********************PRICE OFF FROM DB AS OLD PRODUCT PRICESBYQNT ********************************
  useEffect(() => {
    Axios.get("oldpricesbyqnt").then((response) => {
      setOldProductPricesObj(response.data);
    });
  }, [modifiedData]);

  //*********************ALL MENU ITEMS********************************
  useEffect(() => {
    Axios.get("menuitems").then((response) => {
      setMenuItems(response.data);
    });
  }, [modifiedData]);

  //*********************ALL SUBCATEGORIES********************************
  useEffect(() => {
    Axios.get("subcategories").then((response) => {
      setSubcategoriesData(response.data);
    });
  }, [modifiedData]);

  //*********************ALL CATEGORIES********************************
  useEffect(() => {
    Axios.get("categories").then((response) => {
      setCategoryData(response.data);
    });
  }, [modifiedData]);

  //*********************ALL CATEGORIES DEESCRIPTIONS********************************
  useEffect(() => {
    Axios.get("categorydescriptions").then((response) => {
      setCategoryDescriptionsData(response.data);
    });
  }, [modifiedData]);

  //*********************ALL IMAGES********************************
  useEffect(() => {
    Axios.get("images").then((response) => {
      setImagesData(response.data);
    });
  }, [modifiedData]);

  //*********************ALL PRODUCTCATEGORY********************************
  useEffect(() => {
    Axios.get("productcategory").then((response) => {
      setProductCategoryData(response.data);
    });
  }, [modifiedData]);
  //*********************ALL PRICES BY QNTS********************************
  useEffect(() => {
    Axios.get("pricesbyqnt").then((response) => {
      setPricesByQntData(response.data);
    });
  }, [modifiedData]);

  //*********************ALL  DEESCRIPTIONS********************************
  useEffect(() => {
    Axios.get("descriptions").then((response) => {
      setDescriptionsData(response.data);
    });
  }, [modifiedData]);

  //*********************ALL CATEGORIES DEESCRIPTIONS********************************
  useEffect(() => {
    Axios.get("ingredients").then((response) => {
      setIngredientsData(response.data);
    });
  }, [modifiedData]);

  //*********************ALL FROM PRODUCTINGREDIENT********************************
  useEffect(() => {
    Axios.get("productingredient").then((response) => {
      setProductIngredientData(response.data);
    });
  }, [modifiedData]);

  //*********************ALL FROM BACKGROUNDSVGSORIMAGES********************************
  useEffect(() => {
    Axios.get("backgroundsvgsorimages").then((response) => {
      setBackgroundSvgsOrImagesData(response.data);
    });
  }, [modifiedData]);

  //*********************ALL  VIDEO DATA********************************
  useEffect(() => {
    Axios.get("myvideos").then((response) => {
      setVideoData(response.data);
    });
  }, [modifiedData]);

  //*********************ALL FROM THEMES********************************
  useEffect(() => {
    Axios.get("themes").then((response) => {
      setThemesData(response.data);
    });
  }, [modifiedData]);

  //*********************ALL FROM MODALS********************************
  useEffect(() => {
    Axios.get("mymodals/completModals").then((response) => {
      setModalsData(response.data);
    });
  }, [modifiedData]);

  //*********************CURRENT THEME********************************
  useEffect(() => {
    const currentTheme = themesData.find(
      (theme) => theme.activeOrInactive === "Activa"
    );
    setCurrentTheme(currentTheme);
  }, [themesData]);

  //*****************FIND ALL  SPECIFICATIONS ****************** */
  useEffect(() => {
    Axios.get("specifications").then((response) => {
      setSpecificationsData(response.data);
    });
  }, [modifiedData]);

  //*****************CURRENT MODAL****************** */
  const [currentmodal, setCurrentModal] = useState();
  useEffect(() => {
    if (modalsData && modalsData.length > 0) {
      const currModal = modalsData.find((modal) => modal.status === "Activ");
      setCurrentModal(currModal);
    }
  }, [modalsData]);
  //console.log("currentTheme", currentTheme);
  // console.log("oldProductPricesObj", oldProductPricesObj);

  return (
    <div style={{ width: "100%" }}>
      {currentmodal && showModal && (
        <MyModal currentmodal={currentmodal} setShowModal={setShowModal} />
      )}
      <AdminViews
        currentTheme={currentTheme}
        sideNav={sideNav}
        showSideNav={showSideNav}
        menuItems={menuItems}
        isThemeLoaded={isThemeLoaded}
        priceOffPercentage={priceOffPercentage}
        Axios={Axios}
        priceOffObjs={priceOffObjs}
        setPriceOffPercentage={setPriceOffPercentage}
        setModifiedData={setModifiedData}
        modifiedData={modifiedData}
        logged={logged}
        setLogged={setLogged}
        categoryData={categoryData}
        subcategoriesData={subcategoriesData}
        categoryDescriptionsData={categoryDescriptionsData}
        imagesData={imagesData}
        productCategoryData={productCategoryData}
        descriptionsData={descriptionsData}
        pricesByQntData={pricesByQntData}
        ingredientsData={ingredientsData}
        productIngredientData={productIngredientData}
        specificationsData={specificationsData}
        videoData={videoData}
        themesData={themesData}
        backgroundSvgsOrImagesData={backgroundSvgsOrImagesData}
        modalsData={modalsData}
      />
      {isThemeLoaded && (
        <Views
          sideNav={sideNav}
          showSideNav={showSideNav}
          modifiedData={modifiedData}
          menuItems={menuItems}
          setMenuItems={setMenuItems}
          subcategoriesData={subcategoriesData}
          categoryData={categoryData}
          categoryDescriptionsData={categoryDescriptionsData}
          currentTheme={currentTheme}
          Axios={Axios}
          oldProductPricesObj={oldProductPricesObj}
        />
      )}
    </div>
  );
}

export default App;
