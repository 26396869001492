import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const ListOfMyVideos = ({
  videoData,
  modifiedData,
  setModifiedData,
  Axios,
}) => {
  const deleteVideo = (id) => {
    Axios.delete(`myvideos/${id}`).then((response) => {
      console.log(response.data);
      setModifiedData(!modifiedData);
    });
  };
  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <h4>List of videos:</h4>
      <Link to="/admin/adauga-video">Add video</Link>

      <ul>
        {videoData.map((video) => (
          <li
            key={video.id}
            className="d-flex flex-column align-items-center justify-content-center"
          >
            <h4>{video.name}</h4>
            <video width="320" height="240" controls>
              <source src={video.src} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <button
              className="btn btn-primary"
              onClick={() => deleteVideo(video.id)}
            >
              Delete
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ListOfMyVideos;
