import React from "react";
import "./MobileHeader.css";
import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import Sidenav from "../Sidenav/Sidenav";

const appear = keyframes`
from{
  opacity:0;}
  to{
    opacity:1;}
  
  `;
const MobileNavbar = styled.div`
  animation: ${appear} 1s ease forwards;
`;
const MobileHeader = ({
  currentTheme,
  sideNav,
  showSideNav,
  menuItems,
  categoryData,
}) => {
  return (
    <MobileNavbar
      className="mobile-header"
      style={{
        backgroundColor: currentTheme
          ? `${currentTheme.headerColor}`
          : " rgba(243, 218, 214, 1)",
      }}
    >
      {currentTheme && currentTheme.mobileHeaderLeftImage && (
        <img
          className="mobile-header-left-image"
          src={currentTheme.mobileHeaderLeftImage}
          alt=""
        />
      )}
      <div className="mobile-header-content">
        <Link to="/" style={{ textDecoration: "none" }}>
          <div
            className="lucilles"
            style={{
              backgroundImage: currentTheme
                ? `linear-gradient(${currentTheme.startLinearGradientLucilles}, 
            ${currentTheme.endLinearGradientLucilles})`
                : `linear-gradient(to right, #8f1e35, #e9b3b3)`,
              backgroundClip: "text",
              WebkitBackgroundClip: "text", // for cross-browser support
              color: "transparent", // makes the gradient visible on the text
              WebkitTextFillColor: "transparent", // also for cross-browser support
            }}
          >
            Lucille's
          </div>
        </Link>
        <div className="hambourger-icon" onClick={showSideNav}>
          <FaBars
            style={{
              color: currentTheme ? currentTheme.hambourgerColor : "black",
            }}
          />
        </div>
      </div>
      {sideNav && (
        <Sidenav
          sideNav={sideNav}
          showSideNav={showSideNav}
          menuItems={menuItems}
          categoryData={categoryData}
          currentTheme={currentTheme}
        />
      )}
      {currentTheme && currentTheme.mobileHeaderRightImage && (
        <img
          className="mobile-header-right-image"
          src={currentTheme.mobileHeaderRightImage}
          alt=""
        />
      )}
    </MobileNavbar>
  );
};

export default MobileHeader;
