import React, { useState, useEffect } from "react";
import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { Container, Row, Col } from "react-bootstrap";
import SingleCard from "../../Components/SingleCard/SingleCard";
import Select, { components } from "react-select";
import { FaChevronDown } from "react-icons/fa";

const appear = keyframes`
from{
  opacity:0;}
  to{
    opacity:1;}
  
  `;
const Wrap = styled.div`
  width: 100%;
  padding: 25px;
  margin-top: 90px;
  min-height: calc(100vh - 90px);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${appear} 1.3s ease forwards;
  @media screen and (max-width: 500px) {
    padding: 25px 10px;
  }
`;
const ContainerWrap = styled.div`
  border-radius: 9px;
  width: 70%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: ${appear} 1.8s ease forwards;
  @media screen and (max-width: 600px) {
    width: 90%;
  }
`;
const BackgroundMask = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: -99;
  top: 90px;
`;
const DescriptionDiv = styled.div`
  width: 100%;
  border-radius: 7px;
`;
const Title = styled.div`
  text-align: center;
  padding: 1rem;
  font-size: 30px;
  font-weight: 600;
`;
const SelectWrap = styled.div`
  width: 350px;
  margin-bottom: 30px;
  @media screen and (max-width: 600px) {
    width: 270px;
  }
`;
// Custom Dropdown Indicator Component
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FaChevronDown style={{ color: "black", marginRight: "8px" }} />
    </components.DropdownIndicator>
  );
};

const ProductListPage = ({
  products,
  pageTitle,
  oldProductPricesObj,
  currentTheme,
  selectOptionsObjs,
  selectOptionsType,
  categoryDescriptionsData,
}) => {
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);

  console.log("products", products);
  console.log("categoryDescriptionsData", categoryDescriptionsData);

  useEffect(() => {
    setFilteredProducts(products);
  }, [products]);

  //Formez optiunile pt select din selectOptionsObjs:
  useEffect(() => {
    const options = selectOptionsObjs.map((item) => {
      return {
        value: item.id,
        label: item.name,
        icon: (
          <img
            src={currentTheme ? currentTheme.productListSubcategoryIcon : ""}
            alt=" icon"
            style={{ width: "20px", height: "20px" }}
          />
        ),
      };
    });
    const allOptions = [
      {
        value: 0,
        label: "Toate",
        icon: (
          <img
            src={currentTheme ? currentTheme.productListSubcategoryIcon : ""}
            alt=" icon"
            style={{ width: "20px", height: "20px" }}
          />
        ),
      },
      ...options,
    ];
    setSelectOptions(allOptions);
  }, [selectOptionsObjs]);

  const selectStyles = {
    control: (provided, state) => ({
      ...provided,
      outline: "none",
      width: "100%",
      border: `1px solid  rgb(196, 202, 206)`,
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "black",
      fontSize: "16px",
      fontWeight: "400",
      marginLeft: "4px",
      width: "100%",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: "5px",
    }),

    menu: (provided) => ({
      ...provided,
      backgroundColor: "white",
      zIndex: 999,
      width: "100%",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: "10px",
      backgroundColor: "inherit",
      borderRadius: "5px",
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  const customOption = (props) => {
    const { data, innerRef, innerProps, isSelected, isFocused } = props;

    return (
      <div
        ref={innerRef}
        {...innerProps}
        style={{
          display: "flex",
          alignItems: "center",
          color: "black",
          fontWeight: "400",
          padding: "6px 10px",
          cursor: "pointer",
          ...(isFocused && {
            backgroundColor: "rgb(230, 248, 255)",
          }),
        }}
      >
        <span style={{ marginRight: "10px" }}>{data.icon}</span>
        <span>{data.label}</span>
      </div>
    );
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption && selectedOption.value === 0) {
      setFilteredProducts(products);
    } else {
      console.log("selectedOption", selectedOption);
      if (selectOptionsType === "categories") {
        const newFilteredProducts = products.filter((product) => {
          const categoriesIds = product.categories.map(
            (category) => category.id
          );
          return categoriesIds.includes(selectedOption.value);
        });
        setFilteredProducts(newFilteredProducts);
      } else {
        const newFilteredProducts = products.filter((product) => {
          const subcategoriesIds = product.subcategories.map(
            (subcategory) => subcategory.id
          );
          return subcategoriesIds.includes(selectedOption.value);
        });
        setFilteredProducts(newFilteredProducts);
      }
    }
  };
  return (
    <>
      {filteredProducts && (
        <Wrap>
          <BackgroundMask
            style={{
              backgroundColor: currentTheme
                ? `${currentTheme.productListPageBackgroundMaskColor}`
                : " rgba(0,0,0,0.1)",
            }}
          />
          <div
            style={{
              backgroundImage: currentTheme
                ? `url(${currentTheme.backgroundImage})`
                : "",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",

              position: "fixed",
              width: "100%",
              height: "100vh",
              zIndex: -100,
              top: 90,
            }}
          />

          <ContainerWrap
            style={{
              backgroundColor: currentTheme
                ? currentTheme.listBackgroundColor
                : "rgba(243, 218, 214, 1)",
              border: currentTheme
                ? `1px solid ${currentTheme.productsListBorder}`
                : "grey",
            }}
          >
            <DescriptionDiv>
              <Title
                style={{
                  color: currentTheme ? currentTheme.cardTitleColor : "black",
                }}
              >
                {pageTitle}
              </Title>
            </DescriptionDiv>

            {selectOptionsObjs && selectOptionsObjs.length > 0 && (
              <SelectWrap>
                <Select
                  options={selectOptions}
                  getOptionLabel={(e) => e.label}
                  getOptionValue={(e) => e.value}
                  styles={selectStyles}
                  placeholder="Selectează"
                  components={{
                    DropdownIndicator: DropdownIndicator,
                    Option: customOption,
                  }}
                  onChange={handleSelectChange}
                />
              </SelectWrap>
            )}

            <Row xs="auto" className="justify-content-center">
              {filteredProducts.map((item) => {
                return (
                  <Col key={item.id}>
                    {item.visibility == "Vizibil" && (
                      <SingleCard
                        oldProductPricesObj={oldProductPricesObj}
                        currentProduct={item}
                        currentTheme={currentTheme}
                      />
                    )}
                  </Col>
                );
              })}
            </Row>
          </ContainerWrap>
        </Wrap>
      )}
    </>
  );
};

export default ProductListPage;
