import React, { useState } from "react";
import "./Sidenav.css";
import { Link } from "react-router-dom";
import * as AiIcons from "react-icons/ai";

const Sidenav = ({
  sideNav,
  showSideNav,
  menuItems,
  categoryData,
  currentTheme,
}) => {
  const [openDropdown, setOpenDropdown] = useState(null);
  const handleDropdownToggle = (menuItemName) => {
    setOpenDropdown((prev) => (prev === menuItemName ? null : menuItemName));
  };

  return (
    <>
      <div className="dark-mask"></div>
      <div className="sidenav">
        {/* -------------X-ul de inchidere a side nav---------------- */}

        <button
          className="close-btn"
          onClick={showSideNav}
          style={{
            color: currentTheme ? `${currentTheme.closeIconXColor}` : "black",
            backgroundColor: currentTheme
              ? `${currentTheme.backgroundCloseIcon}`
              : " rgba(243, 218, 214, 1)",
          }}
        >
          <AiIcons.AiOutlineClose />
        </button>
        {menuItems &&
          menuItems.length > 0 &&
          categoryData &&
          categoryData.length > 0 && (
            <div className="side-menu">
              {menuItems.map((menuItem) => {
                if (menuItem.hasdropdown === "no") {
                  return (
                    <Link
                      key={menuItem.id}
                      className="nav-link-item "
                      to={menuItem.path}
                      style={{
                        color: currentTheme
                          ? `${currentTheme.sideNavMenuItemColor}`
                          : "white",
                      }}
                      onClick={() => {
                        showSideNav(!sideNav);
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <img
                          src={
                            currentTheme ? `${currentTheme.svgMenuItem}` : ""
                          }
                          className="menu-item-svg"
                        />
                        {menuItem.name}
                      </div>
                    </Link>
                  );
                } else {
                  const categoriesAfterMenuItem = categoryData.filter(
                    (category) => {
                      return category.MenuItemId === menuItem.id;
                    }
                  );

                  return (
                    <div key={menuItem.id} className="dropdown-sidenav">
                      <button
                        className="dropdown-btn-side-nav "
                        onClick={() => handleDropdownToggle(menuItem.name)}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={
                              currentTheme ? `${currentTheme.svgMenuItem}` : ""
                            }
                            className="menu-item-svg"
                          />
                          <div
                            style={{
                              color: currentTheme
                                ? `${currentTheme.sideNavMenuItemColor}`
                                : "white",
                            }}
                          >
                            {menuItem.name}
                          </div>
                          <i
                            className="fa fa-caret-down ms-1 "
                            style={{
                              color: currentTheme
                                ? `${currentTheme.sideNavMenuItemColor}`
                                : "white",
                            }}
                          ></i>
                        </div>
                      </button>

                      {openDropdown === menuItem.name && (
                        <div
                          className="dropdown-content-sidenav"
                          style={{
                            backgroundColor: currentTheme
                              ? `${currentTheme.dropdownDivColor}`
                              : "white",
                          }}
                        >
                          {categoriesAfterMenuItem &&
                            categoriesAfterMenuItem.length > 0 &&
                            categoriesAfterMenuItem.map((category) => {
                              return (
                                <Link
                                  key={category.id}
                                  className="nav-link-item-dropdown"
                                  to={`${menuItem.path}/${category.path}`}
                                  onClick={() => {
                                    handleDropdownToggle(null);
                                    showSideNav(!sideNav);
                                  }}
                                  style={{
                                    color: currentTheme
                                      ? `${currentTheme.dropdownDivTextColor}`
                                      : "white",
                                  }}
                                >
                                  {category.name}
                                </Link>
                              );
                            })}
                        </div>
                      )}
                    </div>
                  );
                }
              })}
            </div>
          )}
      </div>
      ;
    </>
  );
};

export default Sidenav;
