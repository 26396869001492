import React, { useState, useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const EditCategoryDescriptions = ({ modifiedData, setModifiedData, Axios }) => {
  const navigate = useNavigate();
  const params = useParams();
  const descriptionId = params.id;
  const [currentCategoryDescription, setCurrentCategoryDescription] =
    useState();

  //Find current category description after description id
  useEffect(() => {
    Axios.get(`categorydescriptions/${descriptionId}`).then((response) => {
      setCurrentCategoryDescription(response.data);
    });
  }, [descriptionId]);

  const initialValues = {
    description: currentCategoryDescription?.description,
  };
  const validationSchema = Yup.object().shape({
    description: Yup.string().required("Descriere obligatoriu!"),
  });

  const onSubmit = (data) => {
    Axios.patch(`categorydescriptions/${descriptionId}`, data).then(() => {
      setModifiedData(!modifiedData);
      navigate("/admin/lista-descrieri-categorii");
    });
  };

  return (
    <>
      {currentCategoryDescription && (
        <>
          <h5>Editeaza descriere categorie</h5>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Form>
              <ErrorMessage
                name="description"
                component="div"
                className="alert alert-danger"
              />
              <Field
                name="description"
                type="text"
                className="form-control"
                placeholder="description"
                style={{
                  width: "100%",
                  height: "100px",
                  resize: "vertical", // Enables resizing vertically
                  overflowY: "scroll",
                }}
              />

              <button type="submit" className="btn btn-primary mt-2">
                Editeaza Categorie
              </button>
            </Form>
          </Formik>
        </>
      )}
    </>
  );
};

export default EditCategoryDescriptions;
