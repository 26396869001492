import React from "react";

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const ListOfSubcategories = ({
  subcategoriesData,
  categoryData,
  modifiedData,
  setModifiedData,
  Axios,
}) => {
  const deleteSubcategory = (id) => {
    Axios.delete(`subcategories/${id}`).then((response) => {
      setModifiedData(!modifiedData);
    });
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <h4>Lista subcategorii</h4>
      <Link
        to="/admin/adauga-subcategorie"
        style={{ fontSize: "17px", fontWeight: "bold" }}
      >
        ADAUGĂ SUBCATEGORIE
      </Link>
      <div style={{ width: "60%" }}>
        {subcategoriesData &&
          subcategoriesData.length > 0 &&
          categoryData &&
          categoryData.length > 0 && (
            <ul className="list-group mb-5">
              {subcategoriesData.map((item) => {
                const category = categoryData.find((category) => {
                  return category.id === item.CategoryId;
                });
                const categoryName = category ? category.name : "";

                return (
                  <li className="list-group-item" key={item.id}>
                    <div className="d-flex justify-content-between ">
                      <div>
                        <div className="me-5">
                          <span className="text-danger ">Subcategoria:</span>{" "}
                          {item.name}
                        </div>
                        <div>
                          <span className="text-danger">Categoria:</span>
                          {categoryName}
                        </div>
                      </div>
                      <div>
                        <button
                          className="btn me-2"
                          style={{ backgroundColor: " rgba(255,215,0,1)" }}
                        >
                          <Link
                            to={`/admin/editeaza-subcategorie/${item.id}`}
                            style={{
                              textDecoration: "none",
                              color: "white",
                            }}
                          >
                            Editează
                          </Link>
                        </button>
                        <button
                          className="btn me-2"
                          style={{
                            backgroundColor: "rgba(184,15,10,1)",
                            color: "white",
                          }}
                          onClick={() => deleteSubcategory(item.id)}
                        >
                          Sterge
                        </button>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
      </div>
    </div>
  );
};

export default ListOfSubcategories;
