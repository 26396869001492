import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";

const AddCategoryDescriptions = ({ modifiedData, setModifiedData, Axios }) => {
  const navigate = useNavigate();
  const [categoriesList, setCategoriesList] = useState([]);

  const initialValues = {
    description: "",
    selectedCategory: [],
  };

  const validationSchema = Yup.object().shape({
    description: Yup.string().required("Descriere obligatoriu!"),
    selectedCategory: Yup.array()
      .min(1, "Select at least one category!")
      .max(1, "Select only one category!")
      .required("Select at least one category!"),
  });

  //Get all categories:
  useEffect(() => {
    Axios.get("categories").then((response) => {
      // Transform the data for react-select
      const transformedData = response.data.map((category) => ({
        value: category.id,
        label: category.name,
      }));
      setCategoriesList(transformedData);
    });
  }, []);

  const onSubmit = (data) => {
    console.log(data);
    const description = {
      description: data.description,
      categoryId: data.selectedCategory[0],
    };
    console.log(description);
    Axios.post("categorydescriptions", description).then((response) => {
      setModifiedData(!modifiedData);
      navigate("/admin/lista-descrieri-categorii");
    });
  };

  return (
    <>
      {categoriesList && (
        <>
          <h5>Adauga descriere categorie</h5>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ setFieldValue, setFieldTouched, values }) => (
              <Form>
                {/* Searchable Input for Category */}
                <label htmlFor="selectedCategory" style={{ color: "red" }}>
                  Alege categoria:
                </label>
                <Select
                  isMulti
                  name="selectedCategory"
                  options={categoriesList}
                  className="basic-multi-select mb-2"
                  classNamePrefix="select"
                  onChange={(selectedOptions) => {
                    const values = selectedOptions.map(
                      (option) => option.value
                    );
                    setFieldValue("selectedCategory", values);
                  }}
                  onBlur={() => {
                    if (values.selectedCategory.length > 0) {
                      setFieldTouched("selectedCategory", true); // Trigger touched state only when at least one item is selected
                    }
                  }}
                />
                <ErrorMessage
                  name="selectedCategory"
                  component="div"
                  className="alert alert-danger"
                />

                <Field
                  name="description"
                  type="textarea"
                  className="form-control"
                  placeholder="description"
                  style={{
                    width: "100%",
                    height: "100px",
                    resize: "vertical", // Enables resizing vertically
                    overflowY: "scroll",
                  }}
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="alert alert-danger"
                />

                <button type="submit" className="btn btn-primary mt-2 mb-5">
                  Adauga Descriere Categorie
                </button>
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
};

export default AddCategoryDescriptions;
