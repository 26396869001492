import React from "react";

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SearchBar from "../../../../Components/SearchBar";
import styled from "styled-components";

const CategoriesList = styled.div`
  width: 70%;
  @media screen and (max-width: 1000px) {
    width: 90%;
  }
`;
const CategoryList = ({
  categoryData,
  modifiedData,
  setModifiedData,
  menuItems,
  Axios,
}) => {
  const [filteredCategories, setFilteredCategories] = useState([]);
  useEffect(() => {
    setFilteredCategories(categoryData);
  }, [categoryData]);

  const deleteCategory = (id) => {
    Axios.delete(`categories/${id}`).then((response) => {
      setModifiedData(!modifiedData);
    });
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <h4>Lista categorii :</h4>
      <Link
        to="/admin/adauga-categorie"
        style={{ fontSize: "17px", fontWeight: "bold" }}
      >
        ADAUGĂ CATEGORIE
      </Link>
      <CategoriesList>
        <SearchBar
          className="mb-4"
          onResults={setFilteredCategories}
          list={categoryData}
        />
        <ul className="list-group mb-5">
          {filteredCategories.map((item) => {
            const menuItemName = menuItems.find(
              (menuItem) => menuItem.id === item.MenuItemId
            ).name;
            return (
              <li className="list-group-item skin-type-item" key={item.id}>
                <div className="d-flex justify-content-between">
                  <div>Nume: {item.name}</div>
                  <div>MenuItem : {menuItemName}</div>
                  <div>Path : {item.path}</div>
                  <div>
                    <button
                      className="btn me-2"
                      style={{ backgroundColor: " rgba(255,215,0,1)" }}
                    >
                      <Link
                        to={`/admin/editeaza-categorie/${item.id}`}
                        style={{
                          textDecoration: "none",
                          color: "white",
                        }}
                      >
                        Editează
                      </Link>
                    </button>
                    <button
                      className="btn me-2"
                      style={{
                        backgroundColor: "rgba(184,15,10,1)",
                        color: "white",
                      }}
                      onClick={() => deleteCategory(item.id)}
                    >
                      Sterge
                    </button>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </CategoriesList>
    </div>
  );
};

export default CategoryList;
