import React from "react";
import { Outlet } from "react-router-dom";
import LoginDiv from "../../../Components/Admin/LoginDiv/LoginDiv";
import AdminMenu from "../../../Components/Admin/AdminMenu/AdminMenu";

const LoginPage = ({
  logged,
  setLogged,
  modifiedData,
  setModifiedData,
  priceOffPercentage,
  setPriceOffPercentage,
  Axios,
  priceOffObjs,
}) => {
  return (
    <div
      className="login-wrap "
      style={{
        width: "100%",

        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {!logged && <LoginDiv setLogged={setLogged} />}
      {logged && (
        <div style={{ width: "100%" }}>
          <AdminMenu
            setPriceOffPercentage={setPriceOffPercentage}
            priceOffPercentage={priceOffPercentage}
            Axios={Axios}
            modifiedData={modifiedData}
            setModifiedData={setModifiedData}
            priceOffObjs={priceOffObjs}
          />
          <Outlet
            context={{
              setLogged: setLogged,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default LoginPage;
