import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SearchBar from "../../../../Components/SearchBar";
import styled from "styled-components";

const ContainerWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
const Content = styled.div`
  width: 80%;
`;
const IngredientsContent = styled.div`
  width: 100%;
`;
const IngredientName = styled.div``;
const IngredientWrap = styled.div`
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  padding: 20px;
  width: 100%;
  margin-bottom: 10px;
`;
const IngredientDescription = styled.div`
  word-wrap: break-word; // Allows long words to break and wrap to the next line
  white-space: normal; // Ensures text can wrap onto the next line
`;
const IngredientsList = ({
  ingredientsData,
  modifiedData,
  setModifiedData,
  Axios,
}) => {
  const [filteredIngredients, setFilteredIngredients] = useState([]);

  useEffect(() => {
    setFilteredIngredients(ingredientsData);
  }, [ingredientsData]);

  const deleteIngredient = (id) => {
    Axios.delete(`ingredients/${id}`).then((response) => {
      console.log(response.data);
      setModifiedData(!modifiedData);
    });
  };

  return (
    <ContainerWrap>
      <h5>Lista ingrediente</h5>
      <Link to="/admin/adauga-ingredient">Adauga ingredient</Link>
      <Content>
        <SearchBar onResults={setFilteredIngredients} list={ingredientsData} />
        <IngredientsContent>
          {filteredIngredients.map((item) => {
            return (
              <IngredientWrap key={item.id}>
                <div className="p-2">
                  <button
                    className="btn me-2 mb-2"
                    style={{ backgroundColor: " rgba(255,215,0,1)" }}
                  >
                    <Link
                      to={`/admin/editeaza-ingredient/${item.id}`}
                      style={{
                        textDecoration: "none",
                        color: "white",
                      }}
                    >
                      Editează
                    </Link>
                  </button>
                  <button
                    className="btn me-2"
                    style={{
                      backgroundColor: "rgba(184,15,10,1)",
                      color: "white",
                    }}
                    onClick={() => deleteIngredient(item.id)}
                  >
                    Sterge
                  </button>
                </div>
                <div>
                  {" "}
                  <IngredientName>
                    <span className="text-danger">Nume:</span> {item.name}
                  </IngredientName>
                  <IngredientDescription>
                    <div className="text-danger">Descriere : </div>
                    <div>{item.description}</div>
                  </IngredientDescription>
                </div>
              </IngredientWrap>
            );
          })}
        </IngredientsContent>
      </Content>
    </ContainerWrap>
  );
};

export default IngredientsList;
