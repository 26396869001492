import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import Select from "react-select";

const CategoryDescriptionsList = ({
  categoryDescriptionsData,
  categoryData,
  modifiedData,
  setModifiedData,
  Axios,
}) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectListCategories, setSelectListCategories] = useState([]);
  const [filteredDescriptions, setFilteredDescriptions] = useState([]);

  useEffect(() => {
    const transformedData = categoryData.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setSelectListCategories(transformedData);
  }, [categoryData]);

  useEffect(() => {
    if (selectedCategory != null) {
      const filtered = categoryDescriptionsData.filter(
        (item) => item.categoryId === selectedCategory.value
      );
      setFilteredDescriptions(filtered);
    } else {
      setFilteredDescriptions(categoryDescriptionsData);
    }
  }, [selectedCategory, categoryDescriptionsData]);

  const deleteDescription = (id) => {
    Axios.delete(`categorydescriptions/${id}`).then((response) => {
      setModifiedData(!modifiedData);
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <h5>Lista descrieri categorii</h5>
      <Link to="/admin/adauga-descriere-categorie">
        Adauga descriere categorie
      </Link>

      <div style={{ width: "60%" }}>
        {/* Searchable Input for Category */}
        <div style={{ color: "red" }}>Cauta categoria:</div>
        <Select
          name="selectedCategory"
          options={selectListCategories}
          onChange={(selectedOption) => {
            setSelectedCategory(selectedOption);
          }}
          className="mb-2 mt-2"
        />

        <ul className="list-group">
          {filteredDescriptions.map((item) => {
            const itemCategory = categoryData.find(
              (cat) => cat.id === item.categoryId
            );
            return (
              <li className="list-group-item " key={item.id}>
                {selectedCategory != null ? (
                  <div style={{ color: "red" }}>
                    Categoria : {selectedCategory.label}
                  </div>
                ) : (
                  <div style={{ color: "red" }}>
                    Categoria : {itemCategory.name}
                  </div>
                )}

                <div className="d-flex justify-content-between">
                  <div> {item.description}</div>

                  <div>
                    <button
                      className="btn me-2"
                      style={{ backgroundColor: " rgba(255,215,0,1)" }}
                    >
                      <Link
                        to={`/admin/editeaza-descriere-categorie/${item.id}`}
                        style={{
                          textDecoration: "none",
                          color: "white",
                        }}
                      >
                        Editează
                      </Link>
                    </button>
                    <button
                      className="btn me-2"
                      style={{
                        backgroundColor: "rgba(184,15,10,1)",
                        color: "white",
                      }}
                      onClick={() => deleteDescription(item.id)}
                    >
                      Sterge
                    </button>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default CategoryDescriptionsList;
