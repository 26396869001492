import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import PreviewImage from "../../../../Components/Admin/UploadImage.js/PreviewImage";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const FormWrap = styled.div`
  width: 80%;
`;
const SpecificationDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  border: 1px dotted blue;
  padding: 10px;
`;

const RemoveBtn = styled.button`
  width: 80px;
  height: 40px;
`;

const CustomList = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;
const ListDiv = styled.div`
  flex-grow: 1;
`;
const Label = styled.div`
  color: green;
`;
const ActualProductImg = styled.img`
  width: 100px;
`;
const SelectListsContainer = styled.div``;

const EditProduct = ({
  modifiedData,
  setModifiedData,
  menuItems,
  ingredientsData,
  categoryData,
  subcategoriesData,
  Axios,
}) => {
  const navigate = useNavigate();
  const fileRef = useRef(null);
  const [menuItemsList, setMenuItemsList] = useState([]);
  const [ingredientsList, setIngredientsList] = useState([]);
  const [visibility, setVisibility] = useState(true);
  const params = useParams();
  const productId = params.id;
  const [allCompletProducts, setAllCompletProducts] = useState([]);
  const [product, setProduct] = useState();
  const [transformedSpecifications, setTransformedSpecifications] = useState(
    []
  );

  const [transformedDescriptions, setTransformedDescriptions] = useState([]);
  const [transformedUseInstructions, seTransformedUseInstructions] = useState(
    []
  );
  const [transformedWarnings, setTransformedWarnings] = useState([]);
  const [transformedPricesByQnt, setTransformedPricesByQnt] = useState([]);
  // const [transformedIngredients, setTransformedIngredients] = useState([]);
  const [
    productMenuItemsCategoriesSubcategories,
    setProductMenuItemsCategoriesSubcategories,
  ] = useState([]);

  //get all complet products
  useEffect(() => {
    Axios.get("products/getAllCompletProducts")
      .then((response) => {
        setAllCompletProducts(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [modifiedData]);

  //Gasesc produsul dupa id:
  useEffect(() => {
    if (allCompletProducts && allCompletProducts.length > 0) {
      const product = allCompletProducts.find(
        (product) => parseInt(product.id) === parseInt(productId)
      );
      setProduct(product);
    }
  }, [productId, allCompletProducts]);

  console.log("Product to be edited: ", product);

  //--------------Options for menu items----------------
  useEffect(() => {
    if (menuItems) {
      const transformedData = menuItems.map((menuItem) => ({
        value: menuItem.id,
        label: menuItem.name,
      }));
      setMenuItemsList(transformedData);
    }
  }, [menuItems]);
  //--------------Options for ingredients----------------
  useEffect(() => {
    const transformedData = ingredientsData.map((ingredient) => ({
      value: ingredient.id,
      label: ingredient.name,
    }));
    setIngredientsList(transformedData);
  }, [ingredientsData]);

  // Transform the objects for product.pricesByQnt sub forma:
  // [{ price: "", quantity: "" }],
  useEffect(() => {
    if (product) {
      const transformedPricesByQnt = product.pricesByQnt.map((item) => {
        return {
          price: item.price,
          quantity: item.quantity,
        };
      });
      setTransformedPricesByQnt(transformedPricesByQnt);
    }
  }, [product]);
  //console.log("transformedPricesByQnt", transformedPricesByQnt);

  useEffect(() => {
    if (product) {
      const transformedSpecifications = product.specifications.map((item) => {
        return { title: item.title, specification: item.specification };
      });
      setTransformedSpecifications(transformedSpecifications);
    }
  }, [product]);
  //console.log("transformedSpecifications", transformedSpecifications);

  useEffect(() => {
    if (product) {
      const transformedDescriptions = product.descriptions.map((item) => {
        return item.description;
      });
      setTransformedDescriptions(transformedDescriptions);
    }
  }, [product]);
  useEffect(() => {
    if (product) {
      const transformedUseInstructions = product.useInstructions.map((item) => {
        return item.instruction;
      });
      seTransformedUseInstructions(transformedUseInstructions);
    }
  }, [product]);
  useEffect(() => {
    if (product && product.warnings) {
      const transformedWarnings = product.warnings.map((item) => {
        return item.warning;
      });
      setTransformedWarnings(transformedWarnings);
    }
  }, [product]);
  // useEffect(() => {
  //   if (product) {
  //     const transformedIngredients = product.ingredients.map((item) => {
  //       return {
  //         value: item.id,
  //         label: item.name,
  //       };
  //     });
  //     setTransformedIngredients(transformedIngredients);
  //   }
  // }, [product]);

  //console.log("transformedIngredients", transformedIngredients);

  // Creez vectorul de obiecte selectedMenuItemsCategoriesSubcategories
  // de tip
  //   selectedMenuItemsCategoriesSubcategories:[
  //     {
  //       menuItemId:"",
  //       menuItemObj:{}.
  //       filteredCategoriesObj:[{}],
  //       selectedCategoriesIds:[],
  //       categories:[
  //         {
  //           categoryId:"",
  //           categoryObj:{},
  //           filteredSubcategoriesObj:[{}],
  //           subcategories: [],
  //          subcategoriesIds:[],
  //         }
  //       ]
  // }]

  useEffect(() => {
    const productMenuItemsCategoriesSubcategories = [];

    if (product) {
      product.selectedMenuItemsIds.forEach((menuItemId) => {
        //gasesc obiectul menuItem cu id menuItemId:
        const menuItemObj = menuItems.find(
          (menuItem) => menuItem.id === menuItemId
        );

        //gasesc selectedCategoriesIds pentru menuItemId:

        const selectedCategoriesIds = product.categories
          .filter((category) => {
            return category.MenuItemId === menuItemId;
          })
          .map((category) => {
            return category.id;
          });

        //gasesc filteredCategoriesObj pentru menuItemId:
        const filteredCategoriesObj = categoryData
          .filter((category) => {
            return category.MenuItemId === menuItemId;
          })
          .map((category) => {
            return {
              value: category.id,
              label: category.name,
            };
          });

        //gasesc categories pentru menuItemId:

        const categories = [];
        product.categories
          .filter((cat) => {
            return cat.MenuItemId === menuItemId;
          })
          .forEach((category) => {
            const categoryId = category.id;
            const categoryObj = category;
            const filteredSubcategoriesObj = subcategoriesData
              .filter((subcategory) => {
                return subcategory.CategoryId === categoryId;
              })
              .map((subcategory) => {
                return {
                  value: subcategory.id,
                  label: subcategory.name,
                };
              });
            const subcategories = product.subcategories.filter((subcat) => {
              return subcat.CategoryId === category.id;
            });
            const subcategoriesIds = subcategories.map((subcategory) => {
              return subcategory.id;
            });

            const categoryElem = {
              categoryId: categoryId,
              categoryObj: categoryObj,
              filteredSubcategoriesObj: filteredSubcategoriesObj,
              subcategories: subcategories,
              subcategoriesIds: subcategoriesIds,
            };
            categories.push(categoryElem);
          });

        const elem = {
          menuItemId: menuItemId,
          menuItemObj: menuItemObj,
          selectedCategoriesIds: selectedCategoriesIds,
          filteredCategoriesObj: filteredCategoriesObj,
          categories: categories,
        };
        productMenuItemsCategoriesSubcategories.push(elem);
      });
    }

    setProductMenuItemsCategoriesSubcategories(
      productMenuItemsCategoriesSubcategories
    );
  }, [product]);
  console.log(
    "productMenuItemsCategoriesSubcategories",
    productMenuItemsCategoriesSubcategories
  );

  const handleMenuItemsChange = (selected, setFieldValue, values) => {
    console.log("selectedMenuItems", selected);

    //Cand adaugam un element:
    if (
      selected.length > values.selectedMenuItemsCategoriesSubcategories.length
    ) {
      //creez un nou element in values.selectedMenuItemsCategoriesSubcategories:

      //Gasesc menuItemId adaugat:
      const menuItemId = selected[selected.length - 1].value;
      //gasesc obiectul menuItem cu id menuItemId:
      const menuItemObj = menuItems.find(
        (menuItem) => menuItem.id === menuItemId
      );

      const filteredCategoriesObj = categoryData
        .filter((category) => {
          return category.MenuItemId === menuItemId;
        })
        .map((category) => {
          return {
            value: category.id,
            label: category.name,
          };
        });
      const selectedCategoriesIds = [];
      const categories = [];

      const elem = {
        menuItemId: menuItemId,
        menuItemObj: menuItemObj,
        selectedCategoriesIds: selectedCategoriesIds,
        filteredCategoriesObj: filteredCategoriesObj,
        categories: categories,
      };
      setFieldValue("selectedMenuItemsCategoriesSubcategories", [
        ...values.selectedMenuItemsCategoriesSubcategories,
        elem,
      ]);
    } else {
      //Cand stergem un element:

      const selectedMenuItemsCategoriesSubcategoriesAfterDeleteElem =
        values.selectedMenuItemsCategoriesSubcategories.filter((item) => {
          return selected
            .map((selectedMenuItem) => {
              return selectedMenuItem.value;
            })
            .includes(item.menuItemId);
        });
      setFieldValue(
        "selectedMenuItemsCategoriesSubcategories",
        selectedMenuItemsCategoriesSubcategoriesAfterDeleteElem
      );
    }
  };
  const handleCategoryChange = (
    selected,
    setFieldValue,
    values,
    menuItemIndex
  ) => {
    console.log(
      `selectedCategories for menu item index ${menuItemIndex}`,
      selected
    );
    //Daca adaugam:
    if (
      values.selectedMenuItemsCategoriesSubcategories[menuItemIndex]
        .selectedCategoriesIds == [] ||
      selected.length >
        values.selectedMenuItemsCategoriesSubcategories[menuItemIndex]
          .selectedCategoriesIds.length
    ) {
      //Cream un nou element in values.selectedMenuItemsCategoriesSubcategories:
      const selectedCategoriesIds = selected.map((category) => category.value);
      setFieldValue(
        `selectedMenuItemsCategoriesSubcategories[${menuItemIndex}].selectedCategoriesIds`,
        selectedCategoriesIds
      );
      //Adaug noua categorie la categories:

      //gasesc noul categoryId adaugat:
      const categoryId = selected[selected.length - 1].value;
      //gasesc obiectul category cu id categoryId:
      const categoryObj = categoryData.find(
        (category) => category.id === categoryId
      );
      //gasesc subcategories pentru categoryId:
      const filteredSubcategoriesObj = subcategoriesData
        .filter((subcategory) => {
          return subcategory.CategoryId === categoryId;
        })
        .map((subcategory) => {
          return {
            value: subcategory.id,
            label: subcategory.name,
          };
        });
      const categories =
        values.selectedMenuItemsCategoriesSubcategories[menuItemIndex]
          .categories;
      const categoryElem = {
        categoryId: categoryId,
        categoryObj: categoryObj,
        filteredSubcategoriesObj: filteredSubcategoriesObj,
        subcategories: [],
        subcategoriesIds: [],
      };
      categories.push(categoryElem);
      setFieldValue(
        `selectedMenuItemsCategoriesSubcategories[${menuItemIndex}].categories`,
        categories
      );
    } else {
      //Daca stergem:
      const selectedMenuItemsCategoriesSubcategoriesAfterDeleteElem =
        values.selectedMenuItemsCategoriesSubcategories.map((item, index) => {
          if (index === menuItemIndex) {
            item.selectedCategoriesIds = selected.map(
              (category) => category.value
            );
            //sterg din categories elementul aferent idului sters
            item.categories = item.categories.filter((category) => {
              return selected
                .map((selectedCategory) => {
                  return selectedCategory.value;
                })
                .includes(category.categoryId);
            });
            return item;
          } else {
            return item;
          }
        });
      setFieldValue(
        "selectedMenuItemsCategoriesSubcategories",
        selectedMenuItemsCategoriesSubcategoriesAfterDeleteElem
      );
    }
  };
  const handleSubcategoryChange = (
    selected,
    setFieldValue,
    values,
    categoryIndex,
    menuItemIndex
  ) => {
    console.log(
      `selectedSubcategories for category index ${categoryIndex} and menu item index ${menuItemIndex}`,
      selected
    );
    //daca adaugam:
    if (
      selected.length >
      values.selectedMenuItemsCategoriesSubcategories[menuItemIndex].categories[
        categoryIndex
      ].subcategoriesIds.length
    ) {
      //Cream un nou element in values.selectedMenuItemsCategoriesSubcategories:
      const selectedSubcategoriesIds = selected.map((subcategory) => {
        return subcategory.value;
      });
      setFieldValue(
        `selectedMenuItemsCategoriesSubcategories[${menuItemIndex}].categories[${categoryIndex}].subcategoriesIds`,
        selectedSubcategoriesIds
      );
      //gasesc id-ul adaugat si adaug la subcategories:
      const subcategoryId = selected[selected.length - 1].value;
      const subcategoryObj = subcategoriesData.find(
        (subcategory) => subcategory.id === subcategoryId
      );
      const subcategories =
        values.selectedMenuItemsCategoriesSubcategories[menuItemIndex]
          .categories[categoryIndex].subcategories;

      subcategories.push(subcategoryObj);
      setFieldValue(
        `selectedMenuItemsCategoriesSubcategories[${menuItemIndex}].categories[${categoryIndex}].subcategories`,
        subcategories
      );
    } else {
      //daca stergem :
      const selectedMenuItemsCategoriesSubcategoriesAfterDeleteElem =
        values.selectedMenuItemsCategoriesSubcategories.map((item, index) => {
          if (index === menuItemIndex) {
            item.categories[categoryIndex].subcategoriesIds = selected.map(
              (subcategory) => subcategory.value
            );
            //sterg din subcategories elementul aferent idului sters
            item.categories[categoryIndex].subcategories = item.categories[
              categoryIndex
            ].subcategories.filter((subcategory) => {
              return selected
                .map((selectedSubcategory) => {
                  return selectedSubcategory.value;
                })
                .includes(subcategory.id);
            });
            return item;
          } else {
            return item;
          }
        });
      setFieldValue(
        "selectedMenuItemsCategoriesSubcategories",
        selectedMenuItemsCategoriesSubcategoriesAfterDeleteElem
      );
    }
  };
  const onSubmit = (data) => {
    console.log("Data to be edited", data);
    const formData = new FormData();
    data.selectedMenuItemsCategoriesSubcategories?.forEach((item) => {
      formData.append("selectedMenuItemsIds", item.menuItemId);
      item.categories?.forEach((category) => {
        formData.append("selectedCategoriesIds", category.categoryId);
        category.subcategoriesIds?.forEach((subcategoryId) => {
          formData.append("selectedSubcategoriesIds", subcategoryId);
        });
      });
    });
    data.specifications.forEach((specification) => {
      formData.append("specificationsTitles", specification.title);
    });
    data.useInstructions.forEach((instruction) => {
      formData.append("useInstructions", instruction);
    });
    data.specifications.forEach((specification) => {
      formData.append(
        "specificationsDescriptions",
        specification.specification
      );
    });
    data.warnings.forEach((warning) => {
      formData.append("warnings", warning);
    });
    formData.append("name", data.name);
    formData.append("image", data.image);
    formData.append("recipe", data.recipe);
    formData.append("valability", data.valability);
    formData.append("unitOfMeasure", data.unitOfMeasure);
    formData.append("visibility", visibility ? "Vizibil" : "Invizibil");

    data.descriptions.forEach((description) => {
      formData.append("descriptions", description);
    });

    data.pricesByQnt.forEach((priceByQnt) => {
      formData.append(`prices`, priceByQnt.price);
      formData.append(`quantities`, priceByQnt.quantity);
    });
    data.selectedIngredients.forEach((ingredientObj) => {
      formData.append("selectedIngredients", ingredientObj.id);
    });
    // /Log the FormData entries
    for (let pair of formData.entries()) {
      console.log(pair[0], pair[1]);
    }
    //Editare produs:
    Axios.patch(`products/${productId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((res) => {
      console.log("Editate:", res.data);
      setModifiedData(!modifiedData);
    });
    navigate("/admin/lista-produse");
  };
  //--------------Initial values----------------
  const initialValues = {
    selectedMenuItemsCategoriesSubcategories:
      productMenuItemsCategoriesSubcategories || [],
    name: product?.name || "",
    image: null,
    recipe: product?.recipe || "",
    valability: product?.valability || "",
    unitOfMeasure: product?.unitOfMeasure || "l",
    visibility: visibility || "",
    useInstructions: transformedUseInstructions || [""],
    specifications: transformedSpecifications || [
      { title: "", specification: "" },
    ],
    warnings: transformedWarnings || [],
    descriptions: transformedDescriptions || [""],
    pricesByQnt: transformedPricesByQnt || [{ price: 0, quantity: 0 }],
    selectedIngredients: product?.ingredients || [],
  };

  return (
    <div className="d-flex  flex-column align-items-center w-100">
      <h5>Editeaza produs :</h5>
      <FormWrap>
        {product &&
          productMenuItemsCategoriesSubcategories &&
          productMenuItemsCategoriesSubcategories.length > 0 && (
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
              {({ values, setFieldValue }) => {
                console.log("Values Edit Product:", values);
                console.log("Initial values for Edit Product:", initialValues);

                return (
                  <Form>
                    <SelectListsContainer>
                      {/*-------------------- SELECT LIST MENU ITEMS----------------- */}
                      <div>
                        <label>Menu Items</label>
                        <Select
                          value={values.selectedMenuItemsCategoriesSubcategories.map(
                            (menuItem) => {
                              return {
                                value: menuItem.menuItemId,
                                label: menuItem.menuItemObj.name,
                              };
                            }
                          )}
                          isMulti
                          name="menuItems"
                          options={menuItemsList}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={(selected) =>
                            handleMenuItemsChange(
                              selected,
                              setFieldValue,
                              values
                            )
                          }
                        />
                      </div>
                      {/*-------------------- SELECT LIST CATEGORIES & SUBCATEGORIES------------------ */}
                      {values.selectedMenuItemsCategoriesSubcategories &&
                        values.selectedMenuItemsCategoriesSubcategories.length >
                          0 &&
                        values.selectedMenuItemsCategoriesSubcategories.map(
                          (menuItem, menuItemIndex) => {
                            return (
                              <div key={menuItemIndex}>
                                {menuItem.filteredCategoriesObj &&
                                  menuItem.filteredCategoriesObj.length > 0 && (
                                    <div>
                                      {/*  ----------Categorii---------------------- */}
                                      <div>
                                        <label>
                                          Categorii pentru{" "}
                                          {`${menuItem.menuItemObj.name}`}
                                        </label>

                                        <Select
                                          value={menuItem.categories.map(
                                            (cat) => {
                                              return {
                                                value: cat.categoryId,
                                                label: cat.categoryObj.name,
                                              };
                                            }
                                          )}
                                          isMulti
                                          name={`selectedMenuItemsCategoriesSubcategories[${menuItemIndex}].categories`}
                                          options={
                                            menuItem.filteredCategoriesObj
                                          }
                                          className="basic-multi-select"
                                          classNamePrefix="select"
                                          onChange={(selected) => {
                                            handleCategoryChange(
                                              selected,
                                              setFieldValue,
                                              values,
                                              menuItemIndex
                                            );
                                          }}
                                        />
                                      </div>
                                      {/* -------Subcategorii---------- */}
                                      {menuItem.categories &&
                                        menuItem.categories.length > 0 &&
                                        menuItem.categories.map(
                                          (category, categoryIndex) => {
                                            return (
                                              <div key={categoryIndex}>
                                                {category.filteredSubcategoriesObj &&
                                                  category
                                                    .filteredSubcategoriesObj
                                                    .length > 0 && (
                                                    <div>
                                                      <label>
                                                        Subcategorii pentru{" "}
                                                        {`${category.categoryObj.name}`}
                                                      </label>
                                                      <Select
                                                        value={menuItem.categories[
                                                          categoryIndex
                                                        ].subcategories.map(
                                                          (subcat) => {
                                                            return {
                                                              value: subcat.id,
                                                              label:
                                                                subcat.name,
                                                            };
                                                          }
                                                        )}
                                                        isMulti
                                                        name={`selectedMenuItemsCategoriesSubcategories[${menuItemIndex}].categories[${categoryIndex}.subcategoriesIds]`}
                                                        options={
                                                          category.filteredSubcategoriesObj
                                                        }
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        onChange={(
                                                          selected
                                                        ) => {
                                                          handleSubcategoryChange(
                                                            selected,
                                                            setFieldValue,
                                                            values,
                                                            categoryIndex,
                                                            menuItemIndex
                                                          );
                                                        }}
                                                      />
                                                    </div>
                                                  )}
                                              </div>
                                            );
                                          }
                                        )}
                                    </div>
                                  )}
                              </div>
                            );
                          }
                        )}
                    </SelectListsContainer>
                    {/*-------------------- NAME----------------- */}
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="alert alert-danger"
                    />
                    <Label htmlFor="name">Nume produs</Label>
                    <Field
                      name="name"
                      type="text"
                      className="form-control mb-2"
                      placeholder="name"
                    />
                    {/* --------------------IMAGE---------------------------*/}
                    <div className="d-flex align-items-center justify-content-around mb-2">
                      <div>
                        <div className="mb-2 text-danger">Imagine actuala:</div>
                        <ActualProductImg
                          src={product.image ? product.image.imgSrc : ""}
                          alt="edit product image"
                        />
                      </div>
                      <div className="text-danger">Incarca imagine:</div>
                      <input
                        id="image"
                        name="image"
                        ref={fileRef}
                        hidden
                        type="file"
                        onChange={(e) => {
                          setFieldValue("image", e.target.files[0]);
                        }}
                      />
                      {values.image && <PreviewImage file={values.image} />}

                      <button
                        type="button"
                        onClick={() => {
                          fileRef.current.click();
                        }}
                        className="btn btn-outline-info"
                      >
                        Upload
                      </button>
                    </div>
                    {/* --------------------RECIPE---------------------------*/}

                    <ErrorMessage
                      name="recipe"
                      component="div"
                      className="alert alert-danger"
                    />
                    <Label htmlFor="recipe">Reteta</Label>
                    <Field
                      name="recipe"
                      as="textarea"
                      rows={6}
                      className="form-control mb-2"
                      placeholder="recipe"
                    />

                    {/* --------------------VALABILITY---------------------------*/}
                    <ErrorMessage
                      name="valability"
                      component="div"
                      className="alert alert-danger"
                    />
                    <Label htmlFor="valability">Valability</Label>
                    <Field
                      name="valability"
                      as="textarea"
                      className="form-control mb-2"
                      placeholder="valability"
                      rows={2}
                    />

                    {/* --------------------UNIT OF MEASURE---------------------------*/}
                    <ErrorMessage
                      name="unitOfMeasure"
                      component="div"
                      className="alert alert-danger"
                    />
                    <Label htmlFor="unitOfMeasure">
                      Unitate de masura (ml,g)
                    </Label>
                    <Field
                      name="unitOfMeasure"
                      type="text"
                      className="form-control mb-2"
                      placeholder="unitOfMeasure"
                    />
                    {/* --------------------USE INSTRUCTIONS---------------------------*/}
                    <Label htmlFor="useInstructions">Use Instructions:</Label>
                    <FieldArray name="useInstructions">
                      {({ push, remove }) => (
                        <div className="mb-2">
                          {values.useInstructions.map((_, index) => (
                            <CustomList key={index}>
                              <ListDiv>
                                <Field
                                  name={`useInstructions[${index}]`}
                                  as="textarea"
                                  rows={3}
                                  placeholder="Use Instruction"
                                  className="form-control"
                                />
                                <ErrorMessage
                                  name={`useInstructions[${index}]`}
                                  component="div"
                                  className="alert alert-danger"
                                />
                              </ListDiv>

                              <RemoveBtn
                                type="button"
                                className="btn btn-danger"
                                onClick={() => remove(index)}
                              >
                                Remove
                              </RemoveBtn>
                            </CustomList>
                          ))}
                          <button
                            type="button"
                            className="btn btn-info"
                            onClick={() => push("")}
                          >
                            Add Use Instruction
                          </button>
                        </div>
                      )}
                    </FieldArray>

                    {/* --------------------SPECIFICATIONS---------------------------*/}
                    <Label htmlFor="specifications">Specificatii</Label>
                    <FieldArray name="specifications">
                      {({ push, remove }) => (
                        <div className="mb-2">
                          {values.specifications.map((_, index) => (
                            <SpecificationDiv key={index}>
                              <ListDiv>
                                {/* Textarea for the title value */}
                                <Field
                                  name={`specifications[${index}].title`}
                                  type="text"
                                  placeholder="Titlu specificatie"
                                  className="form-control"
                                />
                                <ErrorMessage
                                  name={`specifications[${index}].title`}
                                  component="div"
                                  className="alert alert-danger"
                                />
                                {/* Textarea for the specification value */}
                                <Field
                                  name={`specifications[${index}].specification`}
                                  as="textarea"
                                  rows={2}
                                  placeholder="Titlu specificatie"
                                  className="form-control"
                                />
                                <ErrorMessage
                                  name={`specifications[${index}].specification`}
                                  component="div"
                                  className="alert alert-danger"
                                />
                              </ListDiv>

                              <RemoveBtn
                                type="button"
                                className="btn btn-danger"
                                onClick={() => remove(index)}
                              >
                                Remove
                              </RemoveBtn>
                            </SpecificationDiv>
                          ))}
                          <button
                            type="button"
                            className="btn btn-info"
                            onClick={() => push("")}
                          >
                            Add Specification
                          </button>
                        </div>
                      )}
                    </FieldArray>
                    {/* --------------------WARNINGS---------------------------*/}
                    <Label htmlFor="warnings">Atentionari:</Label>
                    <FieldArray name="warnings">
                      {({ push, remove }) => (
                        <div className="mb-2">
                          {values.warnings.map((_, index) => (
                            <CustomList key={index}>
                              <ListDiv>
                                <Field
                                  name={`warnings[${index}]`}
                                  as="textarea"
                                  rows={2}
                                  placeholder="Atentionare"
                                  className="form-control"
                                />
                                <ErrorMessage
                                  name={`warnings[${index}]`}
                                  component="div"
                                  className="alert alert-danger"
                                />
                              </ListDiv>

                              <RemoveBtn
                                type="button"
                                className="btn btn-danger"
                                onClick={() => remove(index)}
                              >
                                Remove
                              </RemoveBtn>
                            </CustomList>
                          ))}
                          <button
                            type="button"
                            className="btn btn-info"
                            onClick={() => push("")}
                          >
                            Add Warning
                          </button>
                        </div>
                      )}
                    </FieldArray>

                    {/* --------------------DESCRIPTIONS---------------------------*/}
                    <Label htmlFor="descriptions">Descrieri</Label>
                    <FieldArray name="descriptions">
                      {({ push, remove }) => (
                        <div className="mb-2">
                          {values.descriptions.map((_, index) => (
                            <div
                              key={index}
                              className="d-flex align-items-center mb-1"
                            >
                              <Field
                                name={`descriptions[${index}]`}
                                as="textarea"
                                rows={3}
                                placeholder={`Description ${index + 1}`}
                                className="form-control"
                              />
                              <ErrorMessage
                                name={`descriptions[${index}]`}
                                component="div"
                                className="alert alert-danger"
                              />
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => remove(index)}
                              >
                                Remove
                              </button>
                            </div>
                          ))}
                          <button
                            type="button"
                            className="btn btn-info"
                            onClick={() => push("")}
                          >
                            Add Description
                          </button>
                        </div>
                      )}
                    </FieldArray>
                    {/* --------------------PRICES BY QNT---------------------------*/}
                    {/* Dynamic Prices By Quantity */}
                    <Label htmlFor="pricesByQnt">Preturi</Label>
                    <FieldArray name="pricesByQnt">
                      {({ push, remove }) => (
                        <div className="mb-2">
                          {values.pricesByQnt.map((_, index) => (
                            <div key={index} className="d-flex mb-1">
                              <Field
                                name={`pricesByQnt[${index}].quantity`}
                                type="number"
                                placeholder="Quantity"
                                className="form-control"
                              />
                              <ErrorMessage
                                name={`pricesByQnt[${index}].quantity`}
                                component="div"
                                className="alert alert-danger"
                              />

                              <Field
                                name={`pricesByQnt[${index}].price`}
                                type="number"
                                placeholder="Price"
                                className="form-control"
                              />
                              <ErrorMessage
                                name={`pricesByQnt[${index}].price`}
                                component="div"
                                className="alert alert-danger"
                              />
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => remove(index)}
                              >
                                Remove
                              </button>
                            </div>
                          ))}
                          <button
                            type="button"
                            className="btn btn-info"
                            onClick={() => push({ quantity: "", price: "" })}
                          >
                            Add Price
                          </button>
                        </div>
                      )}
                    </FieldArray>
                    {/* --------------------INGREDIENTS---------------------------*/}
                    {/* Searchable Input for Ingredients */}
                    <Label htmlFor="selectedIngredients">
                      Ingrediente descrise
                    </Label>
                    <Select
                      isSearchable={true}
                      value={values.selectedIngredients.map((ingred) => {
                        return { value: ingred.id, label: ingred.name };
                      })}
                      isMulti
                      name="selectedIngredients"
                      options={ingredientsList}
                      className="basic-multi-select mb-2"
                      classNamePrefix="select"
                      onChange={(selectedOptions) => {
                        const values = selectedOptions.map(
                          (option) => option.value
                        );
                        const ingredientsObj = ingredientsData.filter(
                          (ingred) => values.includes(ingred.id)
                        );
                        setFieldValue("selectedIngredients", ingredientsObj);
                      }}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          height: "150px",
                          minHeight: "60px",
                          fontSize: "16px",
                        }),
                      }}
                    />
                    <ErrorMessage
                      name="selectedIngredients"
                      component="div"
                      className="alert alert-danger"
                    />

                    <button type="submit" className="btn btn-primary mb-5">
                      Editeaza Produs
                    </button>
                  </Form>
                );
              }}
            </Formik>
          )}
      </FormWrap>
    </div>
  );
};

export default EditProduct;
