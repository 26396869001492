import React from "react";

const PreviewImage = ({ file }) => {
  const [preview, setPreview] = React.useState("");

  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    setPreview(reader.result);
  };
  return (
    <div className="p-2">
      <img
        src={preview}
        style={{ width: "100px", border: "1px solid blgreyack" }}
      />
    </div>
  );
};

export default PreviewImage;
