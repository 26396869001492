import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import styled from "styled-components";

const AddCategory = ({ modifiedData, setModifiedData, menuItems, Axios }) => {
  const navigate = useNavigate();
  const [menuItemsOptions, setMenuItemsOptions] = useState([]);

  const initialValues = {
    name: "",
    selectedMenuItems: [],
    path: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Nume obligatoriu!"),
    selectedMenuItems: Yup.array()
      .min(1, "Select at least one category!")
      .max(1, "Select only one category!")
      .required("Select at least one category!"),
    path: Yup.string().required("path obligatoriu!"),
  });

  useEffect(() => {
    const transformedData = menuItems.map((menuItem) => {
      return { value: menuItem.id, label: menuItem.name };
    });
    setMenuItemsOptions(transformedData);
  }, [menuItems]);

  const onSubmit = (data) => {
    Axios.post("categories", data).then((response) => {
      setModifiedData(!modifiedData);
      navigate("/admin/lista-categorii");
    });
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center w-100">
      <div
        className="d-flex flex-column align-items-center justify-content-center "
        style={{ width: "50%" }}
      >
        <h5>Adauga categorie</h5>
        {menuItemsOptions.length > 0 && (
          <div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ values, setFieldValue, setFieldTouched }) => (
                <Form>
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="alert alert-danger"
                  />
                  <Field
                    name="name"
                    type="text"
                    className="form-control"
                    placeholder="name"
                  />
                  {/* Searchable Input for Category */}
                  <label htmlFor="selectedMenuItem" style={{ color: "red" }}>
                    Alege menu tem:
                  </label>
                  <Select
                    isMulti
                    name="selectedMenuItems"
                    options={menuItemsOptions}
                    className="basic-multi-select mb-2"
                    classNamePrefix="select"
                    onChange={(selectedOptions) => {
                      const values = selectedOptions.map(
                        (option) => option.value
                      );
                      setFieldValue("selectedMenuItems", values);
                    }}
                    onBlur={() => {
                      if (values.selectedMenuItems.length > 0) {
                        setFieldTouched("selectedMenuItems", true); // Trigger touched state only when at least one item is selected
                      }
                    }}
                  />
                  <ErrorMessage
                    name="path"
                    component="div"
                    className="alert alert-danger"
                  />
                  <Field
                    name="path"
                    type="text"
                    className="form-control"
                    placeholder="path"
                  />

                  <button type="submit" className="btn btn-primary">
                    Adauga Categorie
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddCategory;
