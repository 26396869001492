import React from "react";

import { Link } from "react-router-dom";

const MenuItemsList = ({ menuItems, modifiedData, setModifiedData, Axios }) => {
  const deleteMenuItem = (id) => {
    Axios.delete(`menuitems/${id}`).then((response) => {
      console.log("MenuItem cu id ", id, "deleted");
      setModifiedData(!modifiedData);
    });
  };
  return (
    <div className="d-flex flex-column align-items-center justify-content-center w-100">
      <div
        className="d-flex flex-column align-items-center justify-content-center "
        style={{ width: "50%" }}
      >
        <h5>Lista menu items</h5>
        <Link to="/admin/adauga-menu-item">Adauga menu item</Link>

        <ul className="list-group w-100">
          {menuItems.map((item) => {
            return (
              <li className="list-group-item skin-type-item" key={item.id}>
                <div className="d-flex justify-content-between">
                  <div> {item.name}</div>
                  <div>{item.hasdropdown}</div>
                  <div>{item.path}</div>
                  <div>
                    <button
                      className="btn me-2"
                      style={{ backgroundColor: " rgba(255,215,0,1)" }}
                    >
                      <Link
                        to={`/admin/editeaza-menu-item/${item.id}`}
                        style={{
                          textDecoration: "none",
                          color: "white",
                        }}
                      >
                        Editează
                      </Link>
                    </button>
                    <button
                      className="btn me-2"
                      style={{
                        backgroundColor: "rgba(184,15,10,1)",
                        color: "white",
                      }}
                      onClick={() => deleteMenuItem(item.id)}
                    >
                      Sterge
                    </button>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default MenuItemsList;
