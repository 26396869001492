import React from "react";

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const ModalsContainer = styled.div`
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

const ListOfMyModals = ({ modifiedData, setModifiedData, Axios }) => {
  const [modals, setModals] = useState([]);

  useEffect(() => {
    Axios.get("mymodals/completModals").then((response) => {
      setModals(response.data);
    });
  }, [modifiedData]);

  const deleteModal = (id) => {
    Axios.delete(`mymodals/${id}`).then(() => {
      setModifiedData(!modifiedData);
    });
  };
  console.log("modals", modals);
  const changeModalStatus = (status, id) => {
    const data = {
      id: id,
      status: status,
    };
    Axios.patch(`mymodals/cangeModalStatus/${id}`, data).then((res) => {
      console.log(res.data);
      setModifiedData(!modifiedData);
    });
  };

  return (
    <ModalsContainer>
      <h5>Lista modals</h5>
      <Link to="/admin/adauga-modal">Adauga modal</Link>

      {modals &&
        modals.length > 0 &&
        modals.map((modal) => (
          <div
            key={modal.id}
            className="d-flex justify-content-between align-items-center mt-3 p-2"
            style={{ border: "1px solid black" }}
          >
            <div>
              <div>Name : {modal.name}</div>
              <div>Title : {modal.title}</div>
              <div>Text : {modal.text}</div>
              <div>Status: {modal.status}</div>
              <div>
                {modal.image != null && (
                  <img
                    src={modal?.image.src}
                    alt="modalImage"
                    style={{ width: "100px" }}
                  />
                )}
              </div>
            </div>
            <div>
              <button
                className="btn me-2"
                style={{ backgroundColor: " rgba(255,215,0,1)" }}
              >
                <Link
                  to={`/admin/editeaza-modal/${modal.id}`}
                  style={{
                    textDecoration: "none",
                    color: "white",
                  }}
                >
                  Editează
                </Link>
              </button>
              <button
                className="btn me-2"
                style={{
                  backgroundColor: "rgba(184,15,10,1)",
                  color: "white",
                }}
                onClick={() => deleteModal(modal.id)}
              >
                Sterge
              </button>
              <button
                className="btn"
                style={{
                  backgroundColor:
                    modal.status == "Activ" ? "rgba(0,128,0,1)" : "grey",
                  color: "white",
                }}
                onClick={() => changeModalStatus(modal.status, modal.id)}
              >
                {modal.status}
              </button>
            </div>
          </div>
        ))}
    </ModalsContainer>
  );
};

export default ListOfMyModals;
