import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import Select, { components } from "react-select";
import { FaChevronDown } from "react-icons/fa";

const appear = keyframes`
from{
  opacity:0;}
  to{
    opacity:1;}
  
  `;

const FullPageWrap = styled.div`
  margin-top: 90px;
  width: 100%;
  min-height: calc(100vh - 90px);
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  animation: ${appear} 2s ease forwards;
`;
const BackgroundMask = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -99;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-position: center center; /* Default: center of the image is focused */
  position: absolute;
  object-fit: cover;
  z-index: -100;
`;
const ProductWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 15px;
  border-radius: 25px;
  animation: ${appear} 2.5s ease forwards;

  @media screen and (min-width: 1300px) {
    width: 40%;
    padding: 60px;
    font-size: 17px;
    padding: 60px;
  }

  @media screen and (min-width: 1000px) and (max-width: 1300px) {
    width: 75%;
    padding: 40px;
  }
  @media screen and (min-width: 700px) and (max-width: 1000px) {
    width: 85%;
    padding: 40px;
  }
  @media screen and (min-width: 500px) and (max-width: 700px) {
    width: 95%;
    padding: 30px;
  }
  @media screen and (max-width: 500px) {
    width: 97%;
    padding: 20px;
  }
`;
const ProductWrapContent = styled.div`
  //border: 4px solid blue;
`;
const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 700px) {
    // flex-direction: column;
  }
`;
const ProductImage = styled.img`
  border-radius: 10px;
  flex-shrink: 0;
  width: 200px;
  height: 266px;
  @media screen and (max-width: 700px) {
    width: 150px;
    height: 200px;
  }
`;

const Title = styled.div`
  //border: 4px solid blue;
  padding: 10px 20px;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  @media screen and (max-width: 700px) {
    font-size: 18px;
  }
`;
const ImageAndPriceAndValability = styled.div`
  //border: 4px solid blue;
  display: flex;
  align-items: center;
`;
const PriceAndValability = styled.div`
  // border: 4px solid red;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 700px) {
    padding: 0 20px;
  }
`;
const PriceAndQuantitiesOptions = styled.div`
  // /border: 4px solid red;
`;
const Price = styled.div`
  //border: 4px solid blue;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CurrentPrice = styled.div`
  font-weight: 600;
  font-size: 18px;

  @media screen and (max-width: 400px) {
    font-size: 17px;
  }
`;
const OldPrice = styled.div`
  text-decoration: line-through;
  margin-left: 10px;
`;
const Valability = styled.div`
  padding: 5px 10px;
  margin-top: 10px;
`;
const ValabilityTitle = styled.span`
  font-weight: 600;
  color: rgb(210, 4, 45);
`;

//-------------QUANTITIES OPTIONS-------------
const QuantitiesOptions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const OptionsDiv = styled.div`
  display: flex;
  justify-content: start;
  font-size: 16px;
`;
const Body = styled.div`
  // border: 4px solid black;
  width: 100%;
`;
//-------------SPECIFICATIONS-------------
const SpecificationsBody = styled.div`
  width: 100%;
  margin-top: 20px;
  border-radius: 10px;
  font-size: 16px;
  line-height: 20px;
`;

const SingleSpecification = styled.div`
  // /border: 2px solid black;
  display: flex;
  align-items: center;
  padding: 5px 10px;

  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
`;
const SpecificationTitle = styled.div`
  // border: 2px solid black;
  width: 200px;
  font-weight: 600;
  flex-shrink: 0;
  color: red;
  @media screen and (max-width: 700px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
const SpecificationDescription = styled.div`
  flex-grow: 1;
  //border: 4px solid green;
  padding: 2px 10px;
`;
const RecipeWrap = styled.div`
  margin-top: 20px;
`;
const ProductDescriptionWrap = styled.div``;
const ProductDescriptionTitle = styled.div``;
const ProductDescriptions = styled.ul``;
const Description = styled.li``;
//-------------SELECT LIST INGREDIENTS-------------
const Ingredients = styled.div`
  margin-top: 20px;
`;
const SelectIngredientDiv = styled.div`
  width: 100%;
  //overflow: hidden;
`;
const appearIngredientDiv = keyframes`
from{
opacity:0;
}
to{
opacity:1;}`;
const IngredientDiv = styled.div`
  display: none;
  animation: ${appearIngredientDiv} 1s ease forwards;
`;
const IngredientName = styled.span`
  font-weight: 600;
`;
const IngredientDescription = styled.span``;

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FaChevronDown style={{ color: "black" }} />
    </components.DropdownIndicator>
  );
};
const CustomMenu = (props) => {
  const menuRef = useRef(null);

  useEffect(() => {
    if (menuRef.current) {
      console.log("Menu height:", menuRef.current.offsetHeight);
    }
  }, [props.children]);

  return (
    <components.Menu {...props} innerRef={menuRef}>
      {props.children}
    </components.Menu>
  );
};

// ------------USE INSTRUCTIONS----------------
const UseInstructions = styled.div`
  margin-top: 20px;
`;

const UseInstructionsTitle = styled.div``;
const UseInstructionsList = styled.ul``;
const Instruction = styled.li``;
//-----------------------WARNINGS-----------------------
const Warnings = styled.ul``;
const WarningsTitle = styled.div``;
const WarningsWrap = styled.div``;
const Warning = styled.li``;

const ProductPage = ({
  allCompletProducts,
  currentTheme,
  oldProductPricesObj,
}) => {
  const params = useParams();
  const productId = params.id;
  const [product, setProduct] = useState();
  const [productOldPricesByQntObjs, setProductOldPricesByQntObjs] = useState(
    []
  );
  const [selectedIngredientId, setSelectedIngredientId] = useState(0);
  const [ingredientsOptions, setIngredientsOptions] = useState([]);
  const [currentPrice, setCurrentPrice] = useState(null);
  const [currentOldPrice, setCurrentOldPrice] = useState(null);
  const [isQuantitySelected, setIsQuantitySelected] = useState(0);
  const [selectContainerHeight, setSelectContainerHeight] = useState("auto");
  const [ingredientDivStyle, setIngredientDivStyle] = useState({});

  //Gasesc produsul cu id
  useEffect(() => {
    if (allCompletProducts && allCompletProducts.length > 0) {
      const product = allCompletProducts.find((item) => {
        return item.id === parseInt(productId);
      });
      setProduct(product);
    }
  }, [allCompletProducts, productId]);
  console.log("product", product);
  //gasesc preturile vechi pt acest produs
  useEffect(() => {
    if (product) {
      const productOldPricesByQntObjs = oldProductPricesObj.filter((item) => {
        return item.ProductId === product.id;
      });
      setProductOldPricesByQntObjs(productOldPricesByQntObjs);
    }
  }, [product]);

  //Setez pretul initial al produsului la cea mai mica cantitate:
  useEffect(() => {
    if (product) {
      const price = product.pricesByQnt[0].price;
      setCurrentPrice(price);
      setIsQuantitySelected(product.pricesByQnt[0].id);
      if (oldProductPricesObj && oldProductPricesObj.length > 0) {
        const oldPrice = oldProductPricesObj.sort(
          (a, b) => a.quantity - b.quantity
        )[0].price;
        setCurrentOldPrice(oldPrice);
      }
    }
  }, [product]);

  //Formez optiunile pt ingredientele produsului:
  useEffect(() => {
    if (product) {
      const ingredients = product.ingredients.map((item) => {
        return { value: item.id, label: item.name };
      });
      setIngredientsOptions(ingredients);
    }
  }, [product]);

  const handleQuantityChange = (item) => {
    console.log("item", item);
    setIsQuantitySelected(item.id);
    setCurrentPrice(item.price);
    if (productOldPricesByQntObjs && productOldPricesByQntObjs.length > 0) {
      const oldPrice = productOldPricesByQntObjs.find((elem) => {
        return elem.quantity === item.quantity;
      }).price;
      setCurrentOldPrice(oldPrice);
    }
  };

  //******************SELECT INGREDIENTS******************************************************
  //******************************************************************************************* */ */
  const ingredientsStyles = {
    control: (provided, state) => ({
      ...provided,
      outline: "none",
      width: "100%",
      height: "30px",
      border: `1px solid 
      ${
        currentTheme
          ? currentTheme.productListPageSelectListControlBorderColor
          : "grey"
      }`,
    }),
    placeholder: (provided) => ({
      ...provided,
      color: currentTheme ? currentTheme.productPageTextColor : "grey",
      fontSize: "16px",
      fontWeight: "400",
      marginLeft: "4px",
      width: "100%",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: "5px",
    }),

    menu: (provided) => ({
      ...provided,
      backgroundColor: "white",
      maxHeight: "200px",
      overflowY: "auto",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      zIndex: 999,
      borderRadius: "0 0 10px 10px",
    }),
    menuList: (provided) => ({
      ...provided,

      backgroundColor: "inherit",
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };
  const customOption = (props) => {
    const { data, innerRef, innerProps, isSelected, isFocused } = props;

    return (
      <div
        ref={innerRef}
        {...innerProps}
        style={{
          display: "flex",
          alignItems: "center",
          color: "black",
          fontWeight: "400",
          padding: "10px 15px",
          cursor: "pointer",
          ...(isFocused && {
            backgroundColor: currentTheme
              ? currentTheme.productListPageSelectListCustomOptionFocusBackgroundColor
                ? currentTheme.productListPageSelectListCustomOptionFocusBackgroundColor
                : "lightgrey"
              : "lightgrey",
          }),
        }}
      >
        <span style={{ marginRight: "10px" }}>{data.icon}</span>
        <span>{data.label}</span>
      </div>
    );
  };

  return (
    <>
      {product && (
        <FullPageWrap>
          <BackgroundMask
            $maskBackgroundColor={
              currentTheme
                ? currentTheme.productPageBackgroundMaskColor
                : " rgba(0,0,0,0.1)"
            }
          />
          <BackgroundImage
            src={currentTheme ? currentTheme.productPageBackgroundImage : ""}
            alt="background img Product Page"
          />
          <ProductWrap
            style={{
              backgroundColor: currentTheme
                ? `${currentTheme.productContainerBackgroundColor}`
                : "rgba(240, 248, 255,0.5)",
              border: `1px solid ${
                currentTheme
                  ? currentTheme.productContainerBorder
                  : "rgb(196, 202, 206)"
              }`,
            }}
          >
            <ProductWrapContent ba>
              <Header>
                <Title>{product.name}</Title>
                <ImageAndPriceAndValability>
                  <ProductImage
                    src={product.image ? product.image.imgSrc : ""}
                    alt="product-image-product-page"
                  />

                  <PriceAndValability>
                    <PriceAndQuantitiesOptions>
                      <Price>
                        <CurrentPrice
                          style={{
                            color: currentTheme
                              ? `${currentTheme.productPagePriceColor}`
                              : "black",
                          }}
                        >
                          <span style={{ marginRight: "1px" }}>
                            {currentPrice}
                          </span>
                          Ron
                        </CurrentPrice>
                        {currentOldPrice && (
                          <OldPrice>
                            <span style={{ marginRight: "1px" }}>
                              {currentOldPrice}
                            </span>
                            Ron
                          </OldPrice>
                        )}
                      </Price>
                      <QuantitiesOptions>
                        {/* <div style={{ marginBottom: "5px" }}>Ambalaj</div> */}
                        <OptionsDiv>
                          {product.pricesByQnt.map((item) => {
                            return (
                              <div
                                onClick={() => {
                                  handleQuantityChange(item);
                                }}
                                key={item.id}
                                className="d-flex"
                                style={{
                                  color: currentTheme
                                    ? `${currentTheme.productPageTextColor}`
                                    : "black",
                                  marginRight: "8px",
                                  border:
                                    isQuantitySelected === item.id
                                      ? "1px solid rgb(210, 4, 45)"
                                      : "1px solid rgb(169, 169, 169) ",
                                }}
                              >
                                <div
                                  style={{
                                    padding: "3px 5px",
                                    cursor: "pointer",
                                  }}
                                >
                                  {item.quantity}
                                  {product.unitOfMeasure}
                                </div>
                              </div>
                            );
                          })}
                        </OptionsDiv>
                      </QuantitiesOptions>
                    </PriceAndQuantitiesOptions>
                    <Valability>
                      <ValabilityTitle
                        style={{
                          color: currentTheme
                            ? currentTheme.productPageValabilityColor
                            : "rgb(210, 4, 45)",
                        }}
                      >
                        Valabilitate:{" "}
                      </ValabilityTitle>
                      {product.valability}
                    </Valability>
                  </PriceAndValability>
                </ImageAndPriceAndValability>
              </Header>
              <Body>
                <SpecificationsBody>
                  {product.specifications.map((specification, index) => {
                    return (
                      <SingleSpecification
                        key={specification.id}
                        style={{
                          backgroundColor:
                            index % 2 === 0
                              ? currentTheme
                                ? currentTheme.headerColor
                                : "rgb(40, 110, 160)"
                              : "rgba(255,255,255,0.8)",

                          color:
                            index % 2 === 0
                              ? currentTheme
                                ? currentTheme.headerTextColor
                                : "white"
                              : currentTheme
                              ? currentTheme.productPageTextColor
                              : "rgb(210, 4, 45)",
                          borderRadius:
                            index === 0
                              ? "10px 10px 0 0"
                              : index === product.specifications.length - 1
                              ? "0 0 10px 10px"
                              : "",
                        }}
                      >
                        <SpecificationTitle
                          style={{
                            color:
                              index % 2 === 0
                                ? "white"
                                : currentTheme
                                ? currentTheme.productPageValabilityColor
                                : "rgb(210, 4, 45)",
                          }}
                        >
                          {specification.title}:
                        </SpecificationTitle>
                        <SpecificationDescription>
                          {specification.specification}
                        </SpecificationDescription>
                      </SingleSpecification>
                    );
                  })}
                </SpecificationsBody>
                <RecipeWrap>
                  <span
                    style={{
                      fontWeight: "600",
                      color: currentTheme
                        ? currentTheme.productPageValabilityColor
                        : "rgb(210, 4, 45)",
                    }}
                  >
                    INCI:{" "}
                  </span>
                  {product.recipe}
                </RecipeWrap>
                <Ingredients>
                  <div
                    style={{
                      color: currentTheme
                        ? currentTheme.productPageValabilityColor
                        : "rgb(210, 4, 45)",

                      fontWeight: "600",
                      marginTop: "10px",
                      marginBottom: "5px",
                    }}
                  >
                    Descrierea ingredientelor:
                  </div>
                  {ingredientsOptions && ingredientsOptions.length > 0 && (
                    <SelectIngredientDiv
                      style={{
                        transition: "height 0.9s ease",
                        height: selectContainerHeight,
                        overflow: "hidden", // Prevent overflow when closed
                        padding: "5px 0px",
                      }}
                    >
                      <Select
                        menuPortalTarget={document.body}
                        onMenuOpen={() => {
                          setSelectContainerHeight("260px");
                        }}
                        onMenuClose={() => setSelectContainerHeight("auto")}
                        options={ingredientsOptions}
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        styles={ingredientsStyles}
                        placeholder="Selectează ingredient"
                        components={{
                          DropdownIndicator: DropdownIndicator,
                          Option: customOption,
                          Menu: CustomMenu,
                        }}
                        onChange={(selectedOption) => {
                          setSelectedIngredientId(selectedOption.value);
                          setIngredientDivStyle({
                            color: selectedOption.color || "black",
                            backgroundColor: selectedOption.bgColor || "white",
                            border: `1px solid  ${
                              currentTheme
                                ? currentTheme.productListPageSelectListControlBorderColor
                                : "grey"
                            }`,
                            padding: "10px",
                            display: "block",
                            borderRadius: "0 0 10px 10px",

                            // transition: "1s",
                          });
                        }}
                      />
                      {product &&
                        product.ingredients.map((item) => {
                          if (item.id === selectedIngredientId) {
                            return (
                              <IngredientDiv
                                key={item.id}
                                style={{
                                  ...ingredientDivStyle,
                                }}
                              >
                                <IngredientDescription>
                                  {item.description}
                                </IngredientDescription>
                              </IngredientDiv>
                            );
                          }
                        })}
                    </SelectIngredientDiv>
                  )}
                </Ingredients>
                {product.descriptions && product.descriptions.length > 0 && (
                  <ProductDescriptionWrap>
                    <ProductDescriptionTitle
                      style={{
                        color: currentTheme
                          ? currentTheme.productPageValabilityColor
                          : "rgb(210, 4, 45)",
                        fontWeight: "600",
                      }}
                    >
                      Descrierea produsului:
                    </ProductDescriptionTitle>
                    <ProductDescriptions>
                      {product.descriptions.map((description) => {
                        return (
                          <Description>{description.description}</Description>
                        );
                      })}
                    </ProductDescriptions>
                  </ProductDescriptionWrap>
                )}

                {product.useInstructions &&
                  product.useInstructions.length > 0 && (
                    <UseInstructions>
                      <UseInstructionsTitle
                        style={{
                          color: currentTheme
                            ? currentTheme.productPageValabilityColor
                            : "rgb(210, 4, 45)",
                          fontWeight: "600",
                        }}
                      >
                        Recomandări de utilizare:
                      </UseInstructionsTitle>
                      <UseInstructionsList>
                        {product.useInstructions.map((instruction) => {
                          return (
                            <Instruction>{instruction.instruction}</Instruction>
                          );
                        })}
                      </UseInstructionsList>
                    </UseInstructions>
                  )}
                {product.warnings && product.warnings.length > 0 && (
                  <WarningsWrap>
                    <WarningsTitle
                      style={{
                        color: currentTheme
                          ? currentTheme.productPageValabilityColor
                          : "rgb(210, 4, 45)",

                        fontWeight: "600",
                      }}
                    >
                      Atenționări:{" "}
                    </WarningsTitle>
                    <Warnings>
                      {product.warnings.map((warning) => {
                        return <Warning>{warning.warning}</Warning>;
                      })}
                    </Warnings>
                  </WarningsWrap>
                )}
              </Body>
            </ProductWrapContent>
          </ProductWrap>
        </FullPageWrap>
      )}
    </>
  );
};

export default ProductPage;
