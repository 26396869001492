import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios, { all } from "axios";

const Wrap = styled.div`
  width: 90%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  padding: 20px;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;
const BackgroundSvgsOrImagesContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const ListOfBackgroundSvgsOrImages = ({
  modifiedData,
  setModifiedData,
  backgroundSvgsOrImagesData,
  Axios,
}) => {
  const deleteBackgroundSvgOrImage = (id) => {
    Axios.delete(`backgroundsvgsorimages/${id}`).then((response) => {
      console.log("response.data:", response.data);
      setModifiedData(!modifiedData);
    });
  };

  return (
    <>
      {backgroundSvgsOrImagesData && backgroundSvgsOrImagesData.length > 0 && (
        <div className="d-flex justify-content-center w-100">
          <Wrap>
            <div className="d-flex flex-column align-items-center ">
              <h5>Lista svg-uri, imagini pentru teme:</h5>
              <Link to="/admin/adauga-background-svg-or-image">
                Adauga svg-uri sau imagini!
              </Link>
            </div>
            <BackgroundSvgsOrImagesContainer>
              {backgroundSvgsOrImagesData.map((item) => {
                return (
                  <div key={item.id} className="d-flex flex-column p-2">
                    <img
                      src={item.src}
                      alt="item"
                      style={{ width: "100px", border: "1px solid grey" }}
                    />
                    <button
                      className="btn me-2"
                      style={{
                        backgroundColor: "rgba(184,15,10,1)",
                        color: "white",
                      }}
                      onClick={() => deleteBackgroundSvgOrImage(item.id)}
                    >
                      Sterge
                    </button>
                  </div>
                );
              })}
            </BackgroundSvgsOrImagesContainer>
          </Wrap>
        </div>
      )}
    </>
  );
};

export default ListOfBackgroundSvgsOrImages;
