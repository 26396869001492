import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const ThemeContainer = styled.div`
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  padding: 15px;
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const BtnsContainer = styled.div`
  margin-bottom: 20px;
  margin-top: 20px;
`;
const PageTitle = styled.h5`
  color: green;
`;
const Video = styled.video`
  width: 200px;
`;
const PageWrap = styled.div`
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-top: 20px;
`;
const SpanTitle = styled.div`
  font-weight: bold;
  margin-bottom: 15px;
  color: red;
  font-size: 14px;
`;
const ImagesContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-warp: wrap;
  gap: 10px;
`;
const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border: 1px dotted grey;
`;
const Image = styled.img`
  width: 90px;
`;
const ColorsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 20px;
  padding: 15px;
`;
const SingleColorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 15px;
`;
const ColorDiv = styled.div`
  display: flex;
  align-items: center;
`;
const ColorString = styled.div`
  color: green;
`;
const ColorSquare = styled.div`
  width: 50px;
  height: 20px;
  margin-left: 10px;
  border: 1px dotted lightgrey;
`;
const ListOfThemes = ({ themesData, modifiedData, setModifiedData, Axios }) => {
  const changeActiveTheme = (id) => {
    Axios.post(`themes/changeActiveTheme/${id}`).then((res) => {
      console.log(res.data);
      setModifiedData(!modifiedData);
    });
  };
  const deleteTheme = (id) => {
    Axios.delete(`themes/${id}`).then((res) => {
      console.log(res.data);
      setModifiedData(!modifiedData);
    });
  };
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <h5>Lista teme site:</h5>
      <Link to="/admin/adauga-tema">Adauga tema</Link>

      {themesData.map((theme) => {
        return (
          <div key={theme.id} className="w-100">
            <ThemeContainer className="container">
              <h6 style={{ fontWeight: "bold", color: "blue" }}>
                {theme.name}
              </h6>
              <BtnsContainer>
                <button
                  onClick={() => deleteTheme(theme.id)}
                  className="btn me-2"
                  style={{
                    backgroundColor: "rgba(184,15,10,1)",
                    color: "white",
                  }}
                >
                  Sterge
                </button>
                <button
                  className="btn me-2"
                  style={{ backgroundColor: " rgba(255,215,0,1)" }}
                >
                  <Link
                    to={`/admin/editeaza-tema/${theme.id}`}
                    style={{
                      textDecoration: "none",
                      color: "white",
                    }}
                  >
                    Editează
                  </Link>
                </button>
                <button
                  className="btn"
                  style={{
                    backgroundColor:
                      theme.activeOrInactive == "Activa"
                        ? "rgba(0,128,0,1)"
                        : "grey",
                    color: "white",
                  }}
                  onClick={() => changeActiveTheme(theme.id)}
                >
                  {theme.activeOrInactive}
                </button>
              </BtnsContainer>

              {/* **************************************************HOME PAGE*********************************************
               *****************************************************************************************************/}
              <PageWrap>
                <PageTitle>Home Page:</PageTitle>
                <ImagesContainer>
                  {/* ------------------  VIDEO HOME PAGE------------------- */}
                  <ImageContainer>
                    <SpanTitle>Home Page Video:</SpanTitle>
                    <Video width="320" height="240" controls>
                      <source src={theme.homePageVideoSrc} type="video/mp4" />{" "}
                    </Video>
                  </ImageContainer>
                  {/* ------------------  LUCILLENATURAL CARE IMAGE------------------- */}
                  <ImageContainer>
                    <SpanTitle>Imagine Lucille Natural Care:</SpanTitle>
                    <Image
                      src={theme.homePageLucilleNaturalCare}
                      alt="lucille natural care img"
                    />
                  </ImageContainer>
                  {/* ------------------  CINE SUNT EU IMAGE------------------- */}
                  <ImageContainer>
                    <SpanTitle>Imagine Cine sunt eu :</SpanTitle>
                    <Image
                      src={theme.homePageSvgCineSuntEu}
                      alt="cine sunt eu img"
                    />
                  </ImageContainer>
                </ImagesContainer>
                <ColorsContainer>
                  {/* ------------------  CINE SUNT EU BACKGROUND COLOR------------------- */}
                  <SingleColorContainer className="d-flex align-items-center justify-content-center">
                    <SpanTitle>Cine sunt eu background color:</SpanTitle>
                    <ColorDiv>
                      <ColorString>{theme.homePageBackgroundColor}</ColorString>
                      <ColorSquare
                        style={{
                          backgroundColor: theme.homePageBackgroundColor,
                        }}
                      />
                    </ColorDiv>
                  </SingleColorContainer>
                  {/* ------------------  SOAPS SECTION BACKGROUND COLOR------------------- */}
                  <SingleColorContainer className="d-flex align-items-center justify-content-center">
                    <SpanTitle>Soaps background color:</SpanTitle>
                    <ColorDiv>
                      <ColorString>
                        {theme.homePageSoapsBackgroundColor}
                      </ColorString>
                      <ColorSquare
                        style={{
                          backgroundColor: theme.homePageSoapsBackgroundColor,
                        }}
                      />
                    </ColorDiv>
                  </SingleColorContainer>
                </ColorsContainer>
              </PageWrap>
              {/* **************************************************HEADER*********************************************
               **************************************************************************************************** */}
              <PageWrap>
                <PageTitle>Home Page:</PageTitle>
                <ImagesContainer>
                  {/* ------------------  HEADER LOGO IMAGE------------------- */}
                  <ImageContainer>
                    <SpanTitle>Header logo: :</SpanTitle>
                    <Image src={theme.logoSvgSrc} alt="header logo img" />
                  </ImageContainer>
                  {/* ------------------  HEADER LEFT IMAGE------------------- */}
                  <ImageContainer>
                    <SpanTitle>Header left image:</SpanTitle>
                    <Image src={theme.headerLeftImage} alt="header left img" />
                  </ImageContainer>
                  {/* ------------------  HEADER RIGHT IMAGE------------------- */}
                  <ImageContainer>
                    <SpanTitle>Header right image:</SpanTitle>
                    <Image
                      src={theme.headerRightImage}
                      alt="header right img"
                    />
                  </ImageContainer>
                </ImagesContainer>

                <ColorsContainer>
                  {/* ------------------  HEADER COLOR------------------- */}
                  <SingleColorContainer className="d-flex align-items-center justify-content-center">
                    <SpanTitle>Header color:</SpanTitle>
                    <ColorDiv>
                      {" "}
                      <ColorString> {theme.headerColor}</ColorString>
                      <ColorSquare
                        style={{
                          backgroundColor: theme.headerColor,
                        }}
                      ></ColorSquare>
                    </ColorDiv>
                  </SingleColorContainer>
                  {/* ------------------  HEADER TEXT COLOR------------------- */}
                  <SingleColorContainer className="d-flex align-items-center justify-content-center">
                    <SpanTitle>Header color:</SpanTitle>
                    <ColorDiv>
                      {" "}
                      <ColorString> {theme.headerTextColor}</ColorString>
                      <ColorSquare
                        style={{
                          backgroundColor: theme.headerTextColor,
                        }}
                      ></ColorSquare>
                    </ColorDiv>
                  </SingleColorContainer>
                </ColorsContainer>
              </PageWrap>

              <PageWrap>
                <PageTitle>Product List Page:</PageTitle>
                <ImagesContainer>
                  {/* ------------------ BACKGROUND IMAGE PRODUCT LIST PAGE------------------- */}
                  <ImageContainer>
                    <SpanTitle>Background image:</SpanTitle>
                    <Image src={theme.backgroundImage} alt="header right img" />
                  </ImageContainer>
                  {/* ------------------ PRODUCT LIST PAGE SUBCATEGORY ICON------------------- */}
                  <ImageContainer>
                    <SpanTitle>Subcategory Icon :</SpanTitle>
                    <Image
                      src={theme.productListSubcategoryIcon}
                      alt="header right img"
                    />
                  </ImageContainer>
                </ImagesContainer>

                <ColorsContainer>
                  {/* ------------------  BACKGROUND MASK COLOR------------------- */}
                  <SingleColorContainer className="d-flex align-items-center justify-content-center">
                    <SpanTitle>Background Mask Color:</SpanTitle>
                    <ColorDiv>
                      <ColorString>
                        {theme.productListPageBackgroundMaskColor}
                      </ColorString>
                      <ColorSquare
                        style={{
                          backgroundColor:
                            theme.productListPageBackgroundMaskColor,
                        }}
                      ></ColorSquare>
                    </ColorDiv>
                  </SingleColorContainer>
                  {/* ------------------PRODUCTS  LIST BACKGROUND COLOR------------------- */}
                  <SingleColorContainer className="d-flex align-items-center justify-content-center">
                    <SpanTitle>Products List Background:</SpanTitle>
                    <ColorDiv>
                      <ColorString> {theme.listBackgroundColor}</ColorString>
                      <ColorSquare
                        style={{
                          backgroundColor: theme.listBackgroundColor,
                        }}
                      ></ColorSquare>
                    </ColorDiv>
                  </SingleColorContainer>
                  {/* ------------------PRODUCT LIST BORDER------------------- */}
                  <SingleColorContainer className="d-flex align-items-center justify-content-center">
                    <SpanTitle>Products List Border:</SpanTitle>
                    <ColorDiv>
                      <ColorString> {theme.productsListBorder}</ColorString>
                      <ColorSquare
                        style={{
                          backgroundColor: theme.productsListBorder,
                        }}
                      ></ColorSquare>
                    </ColorDiv>
                  </SingleColorContainer>
                  {/* ------------------ CARD BACKGROUND COLOR------------------- */}
                  <SingleColorContainer className="d-flex align-items-center justify-content-center">
                    <SpanTitle>Card background:</SpanTitle>
                    <ColorDiv>
                      <ColorString> {theme.cardBackgroundColor}</ColorString>
                      <ColorSquare
                        style={{
                          backgroundColor: theme.cardBackgroundColor,
                        }}
                      ></ColorSquare>
                    </ColorDiv>
                  </SingleColorContainer>
                  {/* ------------------CARD TITLE COLOR------------------- */}
                  <SingleColorContainer className="d-flex align-items-center justify-content-center">
                    <SpanTitle> Card title color:</SpanTitle>
                    <ColorDiv>
                      <ColorString> {theme.cardTitleColor}</ColorString>
                      <ColorSquare
                        style={{
                          backgroundColor: theme.cardTitleColor,
                        }}
                      ></ColorSquare>
                    </ColorDiv>
                  </SingleColorContainer>
                  {/* ------------------CARD SPECIFICATIONS COLOR------------------- */}
                  <SingleColorContainer className="d-flex align-items-center justify-content-center">
                    <SpanTitle> Card specifications color:</SpanTitle>
                    <ColorDiv>
                      <ColorString>
                        {" "}
                        {theme.cardSpecificationsColor}
                      </ColorString>
                      <ColorSquare
                        style={{
                          backgroundColor: theme.cardSpecificationsColor,
                        }}
                      ></ColorSquare>
                    </ColorDiv>
                  </SingleColorContainer>
                  {/* ------------------CARD PRICE COLOR------------------- */}
                  <SingleColorContainer className="d-flex align-items-center justify-content-center">
                    <SpanTitle> Card price color:</SpanTitle>
                    <ColorDiv>
                      <ColorString> {theme.cardPriceColor}</ColorString>
                      <ColorSquare
                        style={{
                          backgroundColor: theme.cardPriceColor,
                        }}
                      ></ColorSquare>
                    </ColorDiv>
                  </SingleColorContainer>
                  {/* ------------------CARD BTN BACKGROUND COLOR------------------- */}
                  <SingleColorContainer className="d-flex align-items-center justify-content-center">
                    <SpanTitle> Card btn background color:</SpanTitle>
                    <ColorDiv>
                      <ColorString> {theme.cardBtnBackgroundColor}</ColorString>
                      <ColorSquare
                        style={{
                          backgroundColor: theme.cardBtnBackgroundColor,
                        }}
                      ></ColorSquare>
                    </ColorDiv>
                  </SingleColorContainer>
                  {/* ------------------CARD BTN TEXT COLOR------------------- */}
                  <SingleColorContainer className="d-flex align-items-center justify-content-center">
                    <SpanTitle> Card btn text color:</SpanTitle>
                    <ColorDiv>
                      <ColorString> {theme.cardBtnTextColor}</ColorString>
                      <ColorSquare
                        style={{
                          backgroundColor: theme.cardBtnTextColor,
                        }}
                      ></ColorSquare>
                    </ColorDiv>
                  </SingleColorContainer>
                </ColorsContainer>
              </PageWrap>
              <PageWrap>
                <PageTitle>Product Page:</PageTitle>
                <ImagesContainer>
                  {/* ------------------  HEADER LOGO IMAGE------------------- */}
                  <ImageContainer>
                    <SpanTitle>Background Image logo: :</SpanTitle>
                    <Image
                      src={theme.productPageBackgroundImage}
                      alt="product page background img"
                    />
                  </ImageContainer>
                </ImagesContainer>
                <ColorsContainer>
                  {/* ------------------PRODUCT PAGE BACKGROUND MASK COLOR------------------- */}
                  <SingleColorContainer className="d-flex align-items-center justify-content-center">
                    <SpanTitle> Mask Color:</SpanTitle>
                    <ColorDiv>
                      <ColorString>
                        {" "}
                        {theme.productPageBackgroundMaskColor}
                      </ColorString>
                      <ColorSquare
                        style={{
                          backgroundColor: theme.productPageBackgroundMaskColor,
                        }}
                      ></ColorSquare>
                    </ColorDiv>
                  </SingleColorContainer>
                  {/* ------------------PRODUCT CONTAINER BORDER------------------- */}
                  <SingleColorContainer className="d-flex align-items-center justify-content-center">
                    <SpanTitle> Product Container Border:</SpanTitle>
                    <ColorDiv>
                      <ColorString> {theme.productContainerBorder}</ColorString>
                      <ColorSquare
                        style={{
                          backgroundColor: theme.productContainerBorder,
                        }}
                      ></ColorSquare>
                    </ColorDiv>
                  </SingleColorContainer>
                  {/* ------------------PRODUCT CONTAINER  BACKGROUND COLOR------------------- */}
                  <SingleColorContainer className="d-flex align-items-center justify-content-center">
                    <SpanTitle> Product Container Background Color:</SpanTitle>
                    <ColorDiv>
                      <ColorString>
                        {" "}
                        {theme.productContainerBackgroundColor}
                      </ColorString>
                      <ColorSquare
                        style={{
                          backgroundColor:
                            theme.productContainerBackgroundColor,
                        }}
                      ></ColorSquare>
                    </ColorDiv>
                  </SingleColorContainer>
                  {/* -----------------PRODUCT PAGE TITLE COLOR------------------- */}
                  <SingleColorContainer className="d-flex align-items-center justify-content-center">
                    <SpanTitle> Title Color:</SpanTitle>
                    <ColorDiv>
                      <ColorString> {theme.productPageTitleColor}</ColorString>
                      <ColorSquare
                        style={{
                          backgroundColor: theme.productPageTitleColor,
                        }}
                      ></ColorSquare>
                    </ColorDiv>
                  </SingleColorContainer>
                  {/* -----------------PRODUCT PAGE PRICE COLOR------------------- */}
                  <SingleColorContainer className="d-flex align-items-center justify-content-center">
                    <SpanTitle> Price Color:</SpanTitle>
                    <ColorDiv>
                      <ColorString> {theme.productPagePriceColor}</ColorString>
                      <ColorSquare
                        style={{
                          backgroundColor: theme.productPagePriceColor,
                        }}
                      ></ColorSquare>
                    </ColorDiv>
                  </SingleColorContainer>
                  {/* -----------------PRODUCT PAGE VALABILITY COLOR------------------- */}
                  <SingleColorContainer className="d-flex align-items-center justify-content-center">
                    <SpanTitle> Valability Color:</SpanTitle>
                    <ColorDiv>
                      <ColorString>
                        {" "}
                        {theme.productPageValabilityColor}
                      </ColorString>
                      <ColorSquare
                        style={{
                          backgroundColor: theme.productPageValabilityColor,
                        }}
                      ></ColorSquare>
                    </ColorDiv>
                  </SingleColorContainer>
                  {/* -----------------PRODUCT PAGE TEXT COLOR------------------- */}
                  <SingleColorContainer className="d-flex align-items-center justify-content-center">
                    <SpanTitle> Text Color:</SpanTitle>
                    <ColorDiv>
                      <ColorString> {theme.productPageTextColor}</ColorString>
                      <ColorSquare
                        style={{
                          backgroundColor: theme.productPageTextColor,
                        }}
                      ></ColorSquare>
                    </ColorDiv>
                  </SingleColorContainer>
                </ColorsContainer>
              </PageWrap>
              <PageWrap>
                <PageTitle>Mobile:</PageTitle>
                <ImagesContainer>
                  {/* ------------------  MOBILE HEADER LEFT IMAGE------------------- */}
                  <ImageContainer>
                    <SpanTitle>Header Left Image:</SpanTitle>
                    <Image
                      src={theme.mobileHeaderLeftImage}
                      alt="icon sidenav menu item"
                    />
                  </ImageContainer>
                  {/* ------------------  MOBILE HEADER RIGHT IMAGE------------------- */}
                  <ImageContainer>
                    <SpanTitle>Header Right Image:</SpanTitle>
                    <Image
                      src={theme.mobileHeaderRightImage}
                      alt="icon sidenav menu item"
                    />
                  </ImageContainer>
                  {/* ------------------  ICON SIDENAV MENU ITEM------------------- */}
                  <ImageContainer>
                    <SpanTitle>Icon sidenav menu item:</SpanTitle>
                    <Image
                      src={theme.svgMenuItem}
                      alt="icon sidenav menu item"
                    />
                  </ImageContainer>
                </ImagesContainer>
                <ColorsContainer>
                  {/* -----------------START LUCILLES LINEAR GRADIENT------------------- */}
                  <SingleColorContainer className="d-flex align-items-center justify-content-center">
                    <SpanTitle> Start Lucille's linear gradient:</SpanTitle>
                    <ColorDiv>
                      <ColorString>
                        {" "}
                        {theme.startLinearGradientLucilles}
                      </ColorString>
                      <ColorSquare
                        style={{
                          backgroundColor: theme.startLinearGradientLucilles,
                        }}
                      ></ColorSquare>
                    </ColorDiv>
                  </SingleColorContainer>
                  {/* -----------------END LUCILLES LINEAR GRADIENT------------------- */}
                  <SingleColorContainer className="d-flex align-items-center justify-content-center">
                    <SpanTitle> End Lucille's linear gradient:</SpanTitle>
                    <ColorDiv>
                      <ColorString>
                        {" "}
                        {theme.endLinearGradientLucilles}
                      </ColorString>
                      <ColorSquare
                        style={{
                          backgroundColor: theme.endLinearGradientLucilles,
                        }}
                      ></ColorSquare>
                    </ColorDiv>
                  </SingleColorContainer>
                  {/* -----------------HAMBOURGER COLOR------------------- */}
                  <SingleColorContainer className="d-flex align-items-center justify-content-center">
                    <SpanTitle> Hambourger color:</SpanTitle>
                    <ColorDiv>
                      <ColorString> {theme.hambourgerColor}</ColorString>
                      <ColorSquare
                        style={{
                          backgroundColor: theme.hambourgerColor,
                        }}
                      ></ColorSquare>
                    </ColorDiv>
                  </SingleColorContainer>
                  {/* -----------------SIDENAV MENU ITEM COLOR------------------- */}
                  <SingleColorContainer className="d-flex align-items-center justify-content-center">
                    <SpanTitle> Sidenav menu item color:</SpanTitle>
                    <ColorDiv>
                      <ColorString> {theme.sideNavMenuItemColor}</ColorString>
                      <ColorSquare
                        style={{
                          backgroundColor: theme.sideNavMenuItemColor,
                        }}
                      ></ColorSquare>
                    </ColorDiv>
                  </SingleColorContainer>
                  {/* -----------------DROPDOWN DIVCOLOR------------------- */}
                  <SingleColorContainer className="d-flex align-items-center justify-content-center">
                    <SpanTitle> Dropdown div color:</SpanTitle>
                    <ColorDiv>
                      <ColorString> {theme.dropdownDivColor}</ColorString>
                      <ColorSquare
                        style={{
                          backgroundColor: theme.dropdownDivColor,
                        }}
                      ></ColorSquare>
                    </ColorDiv>
                  </SingleColorContainer>
                  {/* -----------------DROPDOWN DIV TEXT COLOR------------------- */}
                  <SingleColorContainer className="d-flex align-items-center justify-content-center">
                    <SpanTitle> Dropdown div text color:</SpanTitle>
                    <ColorDiv>
                      <ColorString> {theme.dropdownDivTextColor}</ColorString>
                      <ColorSquare
                        style={{
                          backgroundColor: theme.dropdownDivTextColor,
                        }}
                      ></ColorSquare>
                    </ColorDiv>
                  </SingleColorContainer>
                  {/* -----------------CLOSE ICON BACKGROUND COLOR------------------- */}
                  <SingleColorContainer className="d-flex align-items-center justify-content-center">
                    <SpanTitle> Close Icon background:</SpanTitle>
                    <ColorDiv>
                      <ColorString> {theme.backgroundCloseIcon}</ColorString>
                      <ColorSquare
                        style={{
                          backgroundColor: theme.backgroundCloseIcon,
                        }}
                      ></ColorSquare>
                    </ColorDiv>
                  </SingleColorContainer>
                  {/* -----------------CLOSE ICON X COLOR------------------- */}
                  <SingleColorContainer className="d-flex align-items-center justify-content-center">
                    <SpanTitle> Close Icon X color:</SpanTitle>
                    <ColorDiv>
                      <ColorString> {theme.closeIconXColor}</ColorString>
                      <ColorSquare
                        style={{
                          backgroundColor: theme.closeIconXColor,
                        }}
                      ></ColorSquare>
                    </ColorDiv>
                  </SingleColorContainer>
                </ColorsContainer>
              </PageWrap>
            </ThemeContainer>
          </div>
        );
      })}
    </div>
  );
};

export default ListOfThemes;
