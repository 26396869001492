import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";

import DesktopNavbar from "../Header/DesktopNavbar/DesktopNavbar";
import MobileHeader from "../Header/MobileHeader/MobileHeader";

const AppLayout = ({
  menuItems,
  categoryData,
  sideNav,
  showSideNav,
  currentTheme,
}) => {
  return (
    <div>
      <DesktopNavbar
        currentTheme={currentTheme}
        menuItems={menuItems}
        categoryData={categoryData}
      />
      <MobileHeader
        sideNav={sideNav}
        showSideNav={showSideNav}
        menuItems={menuItems}
        categoryData={categoryData}
        currentTheme={currentTheme}
      />

      <Outlet />
    </div>
  );
};

export default AppLayout;
